<div class="page-containter" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div fxLayout="row" fxLayoutGap="20" fxLayoutAlign="end center" fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch" fxLayoutGap.xs="10" *ngIf="!showEditSection">
        <button mat-button class="mui-button" (click)="addNewRow()">{{'rating.ADD_NEW_CACHE' |
            translate}}</button>
        <mat-form-field appearance="fill">
            <mat-label>{{'sideNav.SEARCH' | translate}}</mat-label>
            <input matInput #searchInput [(ngModel)]="searchQuery" placeholder="{{'devConfig.ENTER_FOR'| translate}}"
                (keydown.enter)="searchTableData()">
            <button mat-icon-button matSuffix (click)="searchTableData()">
                <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="clearSearch()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <div *ngIf="!showEditSection">
        <mat-tab-group *ngIf="!showEditSection" [(selectedIndex)]="selectedTab"
            (selectedTabChange)="onTabChange($event)">
            <mat-tab label="English">
            </mat-tab>
            <mat-tab label="French">
            </mat-tab>
        </mat-tab-group>
        <div class="table-content-fit-page">
            <div class="table-containers full-width">
                <mat-table [dataSource]="data" class="alternate-rows full-width"
                    *ngIf="data.length > 0; else loaderSection">
                    <ng-container matColumnDef="question" class="column-question">
                        <mat-header-cell *matHeaderCellDef class="head-bold">
                            {{ 'cache.QUESTION' | translate
                            }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <div class="text-eclipse-cache cursor">{{ row.question
                                }}</div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="generated_response" class="column-response">
                        <mat-header-cell *matHeaderCellDef class="head-bold">
                            {{ 'cache.RESPONSE' | translate
                            }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">
                            <div class="text-eclipse-cache cursor">
                                {{replaceDivsWithParagraphs(row.generated_response)}}
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="images" *ngIf="chatService?.botConfigurationValues?.enable_image === 'true'">
                        <mat-header-cell *matHeaderCellDef class="head-bold">{{ 'chatBot.IMAGE_TEXT' | translate
                            }}</mat-header-cell>
                        <mat-cell *matCellDef="let row" (click)="previewImages($event, row)">
                            <ng-container *ngIf="getImageKeys(row).length > 0; else noImages">
                                <ul>
                                    <li *ngFor="let key of getImageKeys(row)">
                                        <span class="image-key">
                                            {{ key }}
                                        </span>
                                    </li>
                                </ul>
                            </ng-container>
                            <ng-template #noImages>
                                <ul>
                                    <li>No images</li>
                                </ul>
                            </ng-template>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="added_by">
                        <mat-header-cell *matHeaderCellDef class="head-bold">{{ 'cache.USER' | translate
                            }}</mat-header-cell>
                        <mat-cell *matCellDef="let row" [matTooltip]="row.added_by" class="cursor">{{
                            row.added_by === '' ?'N/A' : row.added_by }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="date_time">
                        <mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.DATE_TIME' | translate
                            }}</mat-header-cell>
                        <mat-cell *matCellDef="let row">{{
                            dateFormatService.convertUtcToLocalUnderScore(row.date_time)
                            }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="head-bold">{{ 'devConfig.ACTION' | translate
                            }}</mat-header-cell>
                        <mat-cell *matCellDef="let row; let index;">
                            <span>
                                <img src="assets/trash 1-black.svg" class="icon-button cursor" height="20" alt="Delete"
                                    (click)="handleDelete(row, index); $event.stopPropagation()">
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row cursor"
                        (click)="onRowClick(row)"></mat-row>
                </mat-table>
                <ng-template #loaderSection>
                    <div class="loader-container" *ngIf="!isLoader">
                        {{'contentMgmt.NO_RECORD_FOUND' | translate}}
                    </div>
                    <div class="loader-container" *ngIf="isLoader">
                        <app-loader [botLoderType]="botLoderType"></app-loader>
                    </div>
                </ng-template>
            </div>
            <div class="pagination" *ngIf="data && data.length > 0 && searchQuery===''">
                <button (click)="onPageChange(1)" [disabled]="pageNumber === 1">
                    <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
                </button>
                <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 1">
                    <mat-icon aria-hidden="false" aria-label="Previous Page" fontIcon="navigate_before"></mat-icon>
                </button>
                <span>{{ pageNumber }} / {{ totalTablePage }}</span>
                <button (click)="onPageChange(pageNumber + 1)" [disabled]="pageNumber === totalTablePage"
                    [disabled]="pageNumber === totalTablePage">
                    <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
                </button>
                <button (click)="onPageChange(totalTablePage)" [disabled]="pageNumber === totalTablePage"
                    [disabled]="pageNumber === totalTablePage">
                    <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="showEditSection" class="page-new-container">
        <div class="edit-record-section">
            <mat-form-field appearance="fill" class="width-100-percent">
                <mat-label> {{ 'feedback.LANGUAGE_TYPE' | translate }}</mat-label>
                <mat-select [(ngModel)]="language" required>
                    <mat-option *ngFor="let language of languageTypeOptions" [value]="language.value">{{ language.label
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="width-100-percent">
                <mat-label>{{ 'cache.ADD_QUESTION' | translate }}</mat-label>
                <input matInput [(ngModel)]="question" #questionInput="ngModel" name="question" required>
                <mat-error *ngIf="questionInput.invalid && (questionInput.dirty || questionInput.touched)">
                    {{ 'errorMessages.CACHEQ&AQUESTION_REQ_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="width-100-percent">
                <mat-label>{{ 'cache.ADD_RESPONSE' | translate }}</mat-label>
                <input matInput [(ngModel)]="response" #responseInput="ngModel" name="response" required>
                <mat-error *ngIf="responseInput.invalid && (responseInput.dirty || responseInput.touched)">
                    {{ 'errorMessages.CACHEQ&ARESPONSE_REQ_ERROR' | translate }}
                </mat-error>
            </mat-form-field>
            <div class="width-100-percent" *ngIf="chatService?.botConfigurationValues?.enable_image
            ==='true'">
                <mat-label>{{ 'cache.TRANS_IMG_REQ_LABEL' | translate }}</mat-label>
                <mat-radio-group [(ngModel)]="imageUpload">
                    <mat-radio-button value="yes">Yes</mat-radio-button>
                    <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="upload-content-style width-100-percent"
                *ngIf="imageUpload === 'yes' && chatService?.botConfigurationValues?.enable_image === 'true'"
                fxLayout="row" fxLayoutGap="40">

                <mat-label>{{ 'cache.TRANS_IMG_UPLOAD_LABEL' | translate }}</mat-label>

                <div>
                    <input type="file" multiple #fileInput accept="image/*" style="display: none"
                        (change)="onFileSelected(fileInput)">

                    <button mat-raised-button class="mui-button" (click)="fileInput.click()">
                        <mat-icon>cloud_upload</mat-icon>
                        <span>{{ 'sideNav.UPLOAD_PROFILE_IMG' | translate }}</span>
                    </button>

                    <p *ngIf="selectedFile.length > 0">{{ 'rating.SELECTED_FILE' | translate }}:</p>

                    <div>
                        <div *ngFor="let file of selectedFile; let i = index" fxLayout="row" fxLayoutAlign="space-between center">
                            <div>{{ i+1}}. {{ file.name }}</div>
                            <div><mat-icon style="cursor: pointer; height: 20px;" (click)="removeFile(i)">delete</mat-icon></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="width-100-percent" *ngIf="chatService?.botConfigurationValues?.enable_disclosure ==='true'">
                <mat-label>{{ 'cache.TRANS_DISCLOSURE_REQ_LABEL' | translate }}</mat-label>
                <mat-radio-group [(ngModel)]="searchDisclosure">
                    <mat-radio-button value="yes">Yes</mat-radio-button>
                    <mat-radio-button value="no">No</mat-radio-button>
                </mat-radio-group>
            </div>
            <mat-form-field class="width-100-percent" appearance="fill"
                *ngIf="searchDisclosure === 'yes' && chatService?.botConfigurationValues?.enable_disclosure ==='true'">
                <mat-label>{{ 'disclosure.SEARCH_DISCLOSURE' | translate }}</mat-label>
                <input [(ngModel)]="disclosuresInfo" matInput #disclosureInput
                    (keydown.enter)="fetchDisclosure(disclosuresInfo)">
                <button mat-icon-button matSuffix (click)="fetchDisclosure(disclosuresInfo)">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20">
                <button mat-button class="mui-button-clear" (click)="onCancel()">{{ 'cache.CANCEL' | translate
                    }}</button>
                <button mat-button class="mui-button" [disabled]="isSaveButtonDisabled()" (click)="onSave()">
                    {{ 'cache.SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="isModalLoader" class="modal-backdrop">
        <app-loader [botLoderType]="botLoderType"></app-loader>
    </div>
</div>