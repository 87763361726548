import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { LocalStorageService } from './localStorageService';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ChatbotService } from './chat.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ContentManagementService {
  saveContentClicked: EventEmitter<void> = new EventEmitter<void>();
  public contentMgmtURL = environment.apiBaseUrl['contentMgmtURL'];
  public developersConfigURL = environment.apiBaseUrl['configServiceURL'];
  public sensitiveURL = environment.apiBaseUrl['sensitiveURL']
  public cacheURL = environment.apiBaseUrl['getSimilarURL']
  public feedbackURL = environment.apiBaseUrl['feedbackUrl']
  public reportURL = environment.apiBaseUrl['reportUrl']
  public transcriptUrl = environment.apiBaseUrl['transcriptUrl']

  private headers: HttpHeaders = new HttpHeaders();
  constructor(private http: HttpClient, private sessionStorage: LocalStorageService, private toaster: ToastrService, private router: Router, private chatbotService: ChatbotService, private translated: TranslateService) {

  }

  getContentConfigurationData() {

    const bot = this.sessionStorage.get('bot_type')
    return this.http.get(`${this.developersConfigURL}/chatBotConfig/getbotConfigurations/${bot}`, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  saveContentConfigurationData(payload: any) {

    const bot = this.sessionStorage.get('bot_type')
    return this.http.post(`${this.developersConfigURL}/chatbotconfig/addBotConfig/${bot}`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  fileUploadContentMgmt(query: any) {

    return this.http.post<any>(`${this.contentMgmtURL}/uploader`, query, { headers: this.headers })
      .pipe(
        catchError((error) => {
          if (error.status === 409) {
            this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          return throwError(error);
        })
      );
  }

  getAllUploadedFiles(pageNumber: number, pageSize: number): Observable<any> {

    const formData = new FormData();
    formData.append('bot_type', this.sessionStorage.get('bot_type'));
    return this.http.post(`${this.contentMgmtURL}/get_all_docs?page_number=${pageNumber}&page_size=${pageSize}`, formData, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  performContentMgmtAction(action: 'update' | 'search' | 'delete', payload: any) {


    let url = '';
    switch (action) {
      case 'update':
        url = `${this.contentMgmtURL}/updateboost`;
        break;
      case 'search':
        url = `${this.contentMgmtURL}/searchdoc`;
        break;
      case 'delete':
        url = `${this.contentMgmtURL}/deletedoc`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  async downloadFile(storageWebsiteUrl: string): Promise<void> {
    try {


      const response: any = await this.http.get(storageWebsiteUrl, {
        responseType: 'blob',
        headers: this.headers
      }).toPromise();

      const contentType = response?.type;
      if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        contentType === 'application/pdf') {
        const blob = new Blob([response], { type: contentType });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      } else {
        this.translated.get('errorMessages.CONTENT_MGMT_PREVIEW_FAILD').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      }
    } catch (error: any) {
      if (error.status === 401) {
        this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      }
      if (error.status === 409) {
        this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
        this.router.navigate(['/login']);
        sessionStorage.clear();
      } else {
        this.translated.get('errorMessages.CONTENT_MGMT_DOC_NOT_AVAILABLE').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      }
    }
  }

  getAllPhrases(page_number: number, page_size: number): Observable<any> {

    const payload = new FormData();
    payload.append('bot_type', this.sessionStorage.get('bot_type'));
    return this.http.post(`${this.contentMgmtURL}/getAllPhrases?page_number=${page_number}&page_size=${page_size}`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  performPhraseAction(action: 'add' | 'update' | 'search' | 'delete', payload: any) {


    let url = '';
    switch (action) {
      case 'add':
        url = `${this.contentMgmtURL}/addPhrases`;
        break;
      case 'update':
        url = `${this.contentMgmtURL}/update`;
        break;
      case 'search':
        url = `${this.contentMgmtURL}/searchPhrase`;
        break;
      case 'delete':
        url = `${this.contentMgmtURL}/delete`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getAllCache(page_number: number, page_size: number, language: any): Observable<any> {

    const body = {
      "bot_type": this.sessionStorage.get('bot_type'),
      "language_type": language
    };
    return this.http.post(`${this.cacheURL}/getAllQuestionsFromCache?page_number=${page_number}&page_size=${page_size}`, body, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  performCacheAction(action: 'save' | 'search' | 'delete', payload: any) {

    let url = '';
    switch (action) {
      case 'save':
        url = `${this.cacheURL}/saveQuestionsToCache`;
        break;
      case 'search':
        url = `${this.cacheURL}/searchQuestionFromCache`;
        break;
      case 'delete':
        url = `${this.cacheURL}/deleteQuestionFromCache`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getAllDevConfigruation() {

    return this.http.get(`${this.developersConfigURL}/chatbotconfig/findAllConfig`, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  performDevConfigurationAction(action: 'addUpdate' | 'addNewApplication' | 'delete', payload: any) {

    let url = '';
    switch (action) {
      case 'addUpdate':
        url = `${this.developersConfigURL}/chatbotconfig/addUpdate`;
        break;
      case 'addNewApplication':
        url = `${this.developersConfigURL}/chatbotconfig/addNewApplication`;
        break;
      case 'delete':
        url = `${this.developersConfigURL}/chatbotconfig/delete`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  performFeedbackAction(action: 'addFeedback' | 'getFeedback' | 'editFeedback' | 'deleteFeedback', payload: any, pageNumber: number, pageSize: number) {

    let url = '';
    switch (action) {
      case 'addFeedback':
        url = `${this.feedbackURL}/add_feedback`;
        break;
      case 'getFeedback':
        url = `${this.feedbackURL}/get_feedback?page_number=${pageNumber}&page_size=${pageSize}`;
        break;
      case 'editFeedback':
        url = `${this.feedbackURL}/edit_feedback`;
        break;
      case 'deleteFeedback':
        url = `${this.feedbackURL}/delete_feedback`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  performSensitiveAction(action: 'add' | 'getAllApproved' | 'getAllUnApproved' | 'approve' | 'reject', payload: any) {

    let url = '';
    switch (action) {
      case 'add':
        url = `${this.sensitiveURL}/admin_add_question`;
        break;
      case 'getAllApproved':
        url = `${this.sensitiveURL}/get_all_approved_question`;
        break;
      case 'getAllUnApproved':
        url = `${this.sensitiveURL}/get_all_unapproved_question`;
        break;
      case 'approve':
        url = `${this.sensitiveURL}/approve_question`;
        break;
      case 'reject':
        url = `${this.sensitiveURL}/reject_question`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  performChitChatAction(action: 'add' | 'getApproved' | 'getRejected' | 'unApproved' | 'approve' | 'reject', payload: any) {

    let url = '';
    switch (action) {
      case 'add':
        url = `${this.sensitiveURL}/admin_add_chit_chat_question`;
        break;
      case 'getApproved':
        url = `${this.sensitiveURL}/get_all_chit_chat_approved_question`;
        break;
      case 'getRejected':
      case 'unApproved':
        url = `${this.sensitiveURL}/get_all_chit_chat_unapproved_question`;
        break;
      case 'approve':
        url = `${this.sensitiveURL}/approve_chit_chat_question`;
        break;
      case 'reject':
        url = `${this.sensitiveURL}/reject_chit_chat_question`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  // performUserCommentFeedbackAction(action: 'add' | 'approved' | 'rejected' | 'unapproved', payload: any) {
  //   
  //   let url = '';
  //   switch (action) {
  //     case 'add':
  //       url = `${this.sensitiveURL}/addApprovedUserFeedback`;
  //       break;
  //     case 'approved':
  //     case 'rejected':
  //     case 'unapproved':
  //       url = `${this.sensitiveURL}/get_all_chit_chat_unapproved_question`;
  //       break;
  //     default:
  //       throw new Error('Invalid action');
  //   }

  //   return this.http.post(url, payload, { headers: this.headers });
  // }
  userCommentFeedApproveReject(action: 'approved' | 'rejected', payload: any) {

    let url = '';
    switch (action) {
      case 'rejected':
      case 'approved':
        url = `${this.feedbackURL}/approveRejectUserFeedback`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  cacheRecommendationApproveReject(action: 'approved' | 'rejected', payload: any) {

    let url = '';
    switch (action) {
      case 'rejected':
      case 'approved':
        url = `${this.cacheURL}/approveRejectCacheRecommendation`;
        break;
      default:
        throw new Error('Invalid action');
    }

    return this.http.post(url, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
  // performUserCommentFeedbackAction(searchPayload: any) {
  //   
  //   return this.http.post(`${this.feedbackURL}/getUserFeedback`, searchPayload, { headers: this.headers }).pipe(
  //     catchError((error: any) => {

  //       if (error.status === 401) {
  //         this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
  //           this.toaster.error(translatedText);
  //         });
  //         this.router.navigate(['/login']);
  //         sessionStorage.clear();
  //       }
  //       if (error.status === 409) {
  //         this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
  //           this.toaster.error(translatedText);
  //         });
  //         this.router.navigate(['/login']);
  //         sessionStorage.clear();
  //       }
  //       return throwError(error);
  //     })
  //   );
  // }

  performUserCommentFeedbackAction(searchPayload: any, page_number: number, page_size: number) {

    return this.http.post(`${this.feedbackURL}/getUserFeedback?page_number=${page_number}&page_size=${page_size}`, searchPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  getCacheRecommendations(searchPayload: any, page_number: number, page_size: number) {

    return this.http.post(`${this.cacheURL}/getCacheRecommendations?page_number=${page_number}&page_size=${page_size}`, searchPayload, { headers: this.headers }).pipe(
      catchError((error: any) => {
        return this.handleError(error);
      })
    );
  }

  private handleError(error: any) {
    if (error.status === 401) {
      this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
      this.router.navigate(['/login']);
      sessionStorage.clear();
    }
    if (error.status === 409) {
      this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
      this.router.navigate(['/login']);
      sessionStorage.clear();
    }
    return throwError(error);
  }

  saveUserFeedback(payload: any) {

    return this.http.post(`${this.feedbackURL}/addApprovedUserFeedback`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {

        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }

  getUsernames(value: string): Observable<{ username: string[] }> {

    const bot = this.sessionStorage.get('bot_type')
    const payload = { bot_type: bot, username: value };

    return this.http.post<{ username: string[] }>(`${this.reportURL}/GetUser`, payload, { headers: this.headers });
  }

  getReportDetails() {

    return this.http.get<any>(`${this.reportURL}/GetAllDetails`, { headers: this.headers });
  }

  generateReport(payload: any): Observable<any> {
    return this.http.post(`${this.reportURL}/QueryingData`, payload, { headers: this.headers, responseType: 'blob' });
  }

  getChatConversationFromUserCmts(payload: any) {
    return this.http.post(`${this.transcriptUrl}/retriveConversation`, payload, { headers: this.headers }).pipe(
      catchError((error: any) => {
        if(error.status === 404 || error.status === 500){
          this.translated.get('errorMessages.NO_CHAT_CONVERSATION').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
        }
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        if (error.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }
        return throwError(error);
      })
    );
  }
}
