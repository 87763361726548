import { Component } from '@angular/core';

@Component({
  selector: 'app-forgot-password-thankyou',
  templateUrl: './forgot-password-thankyou.component.html',
  styleUrls: ['./forgot-password-thankyou.component.scss']
})
export class ForgotPasswordThankyouComponent {

}
