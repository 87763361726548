<!-- <div class="page-containter" [style.paddingLeft]="isDesktop? 20: 0" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div *ngIf="!isValidData" fxLayoutAlign="end center" fxLayoutGap="30" fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch">
        <button mat-button class="mui-button" (click)="addNewEvaluation()">{{'disclosure.ADD_EVALUATION'|
            translate}}</button>
        <mat-form-field appearance="fill" fxFlex="35">
            <mat-label>{{'validator.FILTER_SEARCH_TEXT' | translate}}</mat-label>
            <input matInput #searchInput [(ngModel)]="searchQuery" (ngModelChange)="inputChangeSearch($event)"
                (keydown.enter)="searchTableData(pageNumber)" required>
            <button mat-icon-button matSuffix (click)="searchTableData(pageNumber)">
                <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="clearSearch()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <span fxLayoutAlign="end center" class='font-size12' *ngIf="!isValidData">{{'validator.FILTER_SEARCH_HELP_TEXT' |
        translate}}</span>
    <div *ngIf="isValidData" fxLayout="row" fxLayoutGap="20" fxLayoutAlign="end center" fxLayout.xs="column"
        fxLayoutAlign.xs="end end" fxLayoutGap.xs="10">
        <button mat-button class="mui-button" (click)="backToSearch()">{{'validator.FILTER_BACK' | translate}}</button>
    </div>
    <div>
        <div *ngIf="dataSourceValidationTest.length > 0 && !isRePhrase">
            <div *ngIf="!isLoader; else loading">
                <app-mat-table [columns]="validationColumns" [data]="dataSourceValidationTest" [pageNumber]="pageNumber"
                    [totalTablePage]="totalTablePage" (onPageChangeEvent)="onPageChange($event)"
                    (usernameClick)="rowClickOption($event)" (compareClick)="clickCompare($event)"
                    (filterQuery)="searchfilter($event)" (updatefavorite)="favoriteStatusUpdate($event)">
                </app-mat-table>
                <div *ngIf="dataSourceValidationTest.length === 0" fxLayoutAlign="center center">
                    {{'contentMgmt.NO_RECORD_FOUND' | translate}}
                </div>
            </div>
            <ng-template #loading>
                <div fxLayout="row" fxLayoutAlign="center center" class="padding-top50px">
                    <app-loader [botLoderType]="botLoderType"></app-loader>
                </div>
            </ng-template>
        </div>
        <div *ngIf="dataSourceValidationTest.length === 0" fxLayoutAlign="center center">
            {{'contentMgmt.NO_RECORD_FOUND' | translate}}
        </div>
    </div>
</div>
<div *ngIf="isModalLoader" class="modal-backdrop">
    <app-loader [botLoderType]="botLoderType"></app-loader>
</div> -->
<div class="page-containter" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <div *ngIf="!isValidData" fxLayoutAlign="end center" fxLayoutGap="30" fxLayout.xs="column"
        fxLayoutAlign.xs="start stretch">
        <button mat-button class="mui-button" (click)="addNewEvaluation()">{{'disclosure.ADD_EVALUATION'|
            translate}}</button>
        <mat-form-field appearance="fill" fxFlex="35">
            <mat-label>{{'validator.FILTER_SEARCH_TEXT' | translate}}</mat-label>
            <input matInput #searchInput [(ngModel)]="searchQuery" (ngModelChange)="inputChangeSearch($event)"
                (keydown.enter)="searchTableData(pageNumber)" required>
            <button mat-icon-button matSuffix (click)="searchTableData(pageNumber)">
                <mat-icon>search</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="clearSearch()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <span fxLayoutAlign="end center" class='font-size12' *ngIf="!isValidData">{{'validator.FILTER_SEARCH_HELP_TEXT' |
        translate}}</span>
    <!-- Existing Content -->

    <div class="table-content-fit-page">
        <app-mat-table *ngIf="dataSourceValidationTest.length > 0 && !isRePhrase && !isLoader; else noRecord"
            [columns]="validationColumns" [data]="dataSourceValidationTest" [pageNumber]="pageNumber"
            [totalTablePage]="totalTablePage" (onPageChangeEvent)="onPageChange($event)"
            (usernameClick)="rowClickOption($event)" (compareClick)="clickCompare($event)"
            (filterQuery)="searchfilter($event)" (updatefavorite)="favoriteStatusUpdate($event)">
        </app-mat-table>
        <ng-template #noRecord>
            <div *ngIf="dataSourceValidationTest.length === 0" fxLayoutAlign="center center">
                {{ 'contentMgmt.NO_RECORD_FOUND' | translate }}
            </div>
        </ng-template>

    </div>

</div>
<!-- Modal Loader -->
<div *ngIf="isModalLoader" class="modal-backdrop">
    <app-loader [botLoderType]="botLoderType"></app-loader>
</div>