import { Component, OnInit } from '@angular/core';
import { ContentManagementService } from "../../app/services/content-management.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogComponentComponent } from "../confirmation-dialog-component/confirmation-dialog-component.component";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslationService } from '../services/translation-service';
import { LocalStorageService } from '../services/localStorageService';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
import { StateService } from '../services/shared-object.service';
@Component({
  selector: 'app-synonyms',
  templateUrl: './synonyms.component.html',
  styleUrls: ['./synonyms.component.scss']
})
export class SynonymsComponent implements OnInit {
  searchQuery: string = '';
  editedItem: any = {};
  data: any = [];
  editMode: boolean[] = [];
  pageNumber: number = 1;
  pageSize: number = 20;
  totalTablePage: number = 0;
  isLoader = false;
  translate: any;
  disableIcon = false;
  botLoderType = 'boxLoader'
  isMobileView: boolean = false;
  isNewUser: boolean = false
  queryMigration: any = 0;
  isExpandCollapse: boolean = true;

  constructor(
    private contentMgmtService: ContentManagementService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private sessionStorage: LocalStorageService,
    translateService: TranslationService,
    private breakpointObserver: BreakpointObserver,
    private translated: TranslateService,
    private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService,
    private stateService: StateService
  ) {
    this.translate = translateService;
  }
  ngOnInit(): void {
    this.getAllPhrases(1);
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileView = result.matches;
      });
    this.stateService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
  }
  getAllPhrases(pageNumber: any) {
    this.isLoader = true;
    this.contentMgmtService.getAllPhrases(pageNumber, this.pageSize).subscribe((phrasesResponse: any) => {
      this.isLoader = false;
      let tableData = phrasesResponse?.phraselist.pop();
      this.totalTablePage = tableData.total_pages;
      this.data = phrasesResponse?.phraselist;
      this.initializeEditMode();
    }, (error: any) => {
    })
  }
  initializeEditMode() {
    this.editMode = this.data.map(() => false);
  }
  addNewRow() {
    this.data.unshift({ phrase: '', meaning: '' });
    // Update edit mode array accordingly
    this.editMode.unshift(true);
    this.disableIcon = true;
    this.isNewUser = true
  }
  cancelNewRow() {
    if (this.isNewUser) {
      this.disableIcon = false;
      this.isNewUser = false;
      this.data.shift();
      this.editMode.shift();
    }
  }
  handleEditClick(item: any, index: number) {
    this.editMode[index] = !this.editMode[index];
    this.disableIcon = true;
  }

  handleSaveChanges(item: any, index: number) {
    if (!this.validateFields(item, index)) {
      return;
    }

    const phrasePayload = {
      doc_id: item.doc_id,
      phrase: item.phrase,
      meaning: item.meaning,
      usage: item.usage_type,
      bot_type: this.sessionStorage.get('bot_type')
    };

    const action = item.doc_id ? 'update' : 'add';
    this.contentMgmtService.performPhraseAction(action, phrasePayload).subscribe((response: any) => {
      if (action === 'update') {
        this.translated.get('successfullMessages.SYNONYMS_UPDATE_SUCCESS').subscribe((translatedText: string) => {
          this.toaster.success(translatedText);
        });
      } else if (action === 'add') {
        this.translated.get('successfullMessages.SYNONYMS_ADD_SUCCESS').subscribe((translatedText: string) => {
          this.toaster.success(translatedText);
        });
      }
      this.editMode[index] = !this.editMode[index];
      this.getAllPhrases(1);
      this.disableIcon = false;
      this.pageNumber = 1;
      this.isNewUser = false;
    }, (error: any) => {
      if (error.status !== 409 && error.status !== 401)
        this.translated.get('errorMessages.SYNONYMS_PHARSE_EXIST').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
    });
    // if (item.doc_id && item.phrase === '' && item.meaning === '') {
    //   const phrasePayload = {
    //     "doc_id": item.doc_id,
    //     "phrase": item.phrase,
    //     "meaning": item.meaning,
    //     "bot_type": this.sessionStorage.get('bot_type')
    //   }
    //   this.contentMgmtService.performPhraseAction('update', phrasePayload).subscribe((response: any) => {
    //     this.toaster.success(response.message);
    //     this.editMode[index] = !this.editMode[index];
    //     this.getAllPhrases(1);
    //     this.disableIcon = false;
    //     this.pageNumber = 1;
    //   }, (error: any) => {
    //     this.toaster.error(error?.error?.error);
    //   });
    // } else {
    //   if (item.phrase === '') {
    //     this.toaster.error('Please Enter Phrase');
    //   } else if (item.meaning === '') {
    //     this.toaster.error('Please Enter Meaning');
    //   } else {
    //     const phrasePayload = {
    //       "phrase": item.phrase,
    //       "meaning": item.meaning,
    //       "bot_type": this.sessionStorage.get('bot_type')
    //     }
    //     this.contentMgmtService.performPhraseAction('add', phrasePayload).subscribe((response: any) => {
    //       this.toaster.success(response?.error);
    //       this.editMode[index] = !this.editMode[index];
    //       this.getAllPhrases(1);
    //       this.disableIcon = false;
    //     }, (errorRes: any) => {
    //       this.toaster.error(errorRes?.error?.error);
    //     });
    //   }
    // }
  }

  private validateFields(item: any, index: number): boolean {
    let valid = true;

    if (!item.phrase) {
      this.translated.get('errorMessages.SYNONYMS_PHRASE_ERROR').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
      valid = false;
    }
    if (!item.meaning) {
      this.translated.get('errorMessages.SYNONYMS_MEANING_ERROR').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
      valid = false;
    }

    if (item.usage_type == null || item.usage_type == undefined) {
      this.translated.get('errorMessages.SYNONYMS_USAGE_ERROR').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
      valid = false;
    }

    return valid;
  }

  handleDelete(item: any, i: number) {
    this.openConfirmationDialog(item)
  }


  searchTableData() {
    if (this.searchQuery !== "") {
      const payloadSearch = { "searchString": this.searchQuery, "bot_type": this.sessionStorage.get('bot_type') }
      this.contentMgmtService.performPhraseAction('search', payloadSearch).subscribe((response: any) => {
        this.data = response.matchinglist;
      }, (error: any) => {
      })
    } else {
      this.translated.get('errorMessages.ENTER_SEARCH_TEXT').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }

  clearSearch() {
    if (this.searchQuery !== "") {
      this.searchQuery = "";
      this.getAllPhrases(1);
    } else {
      this.translated.get('errorMessages.ENTER_SEARCH_TEXT').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }

  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.getAllPhrases(pageNumber)
  }
  openConfirmationDialog(selectedRow: any) {
    const DELETE_CONFIRM_LABEL = this.translateLabel('DELETE_CONFIRM_LABEL').translation;
    const messageDelete: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`
    ${DELETE_CONFIRM_LABEL} ${selectedRow.phrase} & ${selectedRow.meaning}`);
    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 1,
        message: messageDelete,
        options: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const phraseDeletePayload = {
          "doc_id": selectedRow.doc_id,
          "bot_type": this.sessionStorage.get('bot_type')
        }
        this.contentMgmtService.performPhraseAction('delete', phraseDeletePayload).subscribe((response: any) => {
          this.toaster.success(response.message);
          this.getAllPhrases(1);
          this.pageNumber = 1;
        }, (error: any) => {
        });
      }
    });
  };
  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.sessionStorage.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }

}
