import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  login(token: string): void {
    sessionStorage.setItem('appTo', token);
  }

  logout(): void {
    sessionStorage.removeItem('appTo');
  }

  isLoggedIn(): boolean {
    return !!sessionStorage.getItem('appTo');
  }

  getToken(): string | null {
    return sessionStorage.getItem('appTo');
  }
}
