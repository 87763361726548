import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sensitive-approve-reject',
  templateUrl: './sensitive-approve-reject.component.html',
  styleUrls: ['./sensitive-approve-reject.component.scss']
})
export class SensitiveApproveRejectComponent {
  title: string = '';
  message: any;
  row: any;
  type: any;
  dataSource: any[];
  // EventEmitter for emitting approved row data
  @Output() rowApproved: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowRejected: EventEmitter<any> = new EventEmitter<any>();
  userComments: string = '';
  constructor(
    private dialogRef: MatDialogRef<SensitiveApproveRejectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.message = data.message;
    this.row = data.row;
    this.type = data.type
    this.dataSource = data.row;
    // Disable closing on backdrop click
    this.dialogRef.disableClose = true;
  }
  closeModal(){
    this.dialogRef.close();
  }
  confirm() {
    const approvalData = {
      row: this.row,
      comments: this.userComments
  };
    // Emitting the approved row data
    this.rowApproved.emit(approvalData);
    this.dialogRef.close('ok');
  }
  cancel() {
    const updatedQuestion = this.rejectQuestion(this.row);
    // Emitting the rejeceted row data
    this.rowRejected.emit(updatedQuestion);
    this.dialogRef.close('cancel');
  }
  rejectQuestion(question: any) {
    return {
      sensitiveQuestion: question.sensitiveQuestion,
      sensitiveQuestionStatus: false,
      comments: this.userComments
    };
  }
}
