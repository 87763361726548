<div class="drawer" [style.width.px]="isMobileView? 350: 400" [ngClass]="{'open': isOpen}">
    <div class='chat-history-head-style'>
        <button mat-icon-button (click)="toggleClose()">
            <mat-icon>close</mat-icon>
        </button>
        <!-- <div>
            <img [src]="userProfile" alt="Profile Picture" height="30" class="profile-border-radius50" />
        </div> -->
        <div>
            <!-- <div>{{userEmail | uppercase}}</div> -->
            <div>{{'chatBot.CHAT_HISTORY' | translate}}</div>
        </div>
    </div>
    <div class="drawer-content">
        <div>
            <ng-container *ngFor="let message of chatHistory;let i = index; let last= last;">
                <div class="row-single">
                    <div *ngIf="message.userMessage.type === 'User'" class="user-response-right">
                        <div class="user">{{ message.userMessage.content }}</div>
                        <span>
                            <img [src]="userProfile" alt="Profile Picture" height="20"
                                class="profile-border-radius50" />
                        </span>
                    </div>
                    <div class="time-format-right">{{dateFormatService.convertUtcToLocal(message.botMessage.timestamp)}}
                    </div>
                </div>
                <div class="row-single">
                    <div *ngIf="message.botMessage.type === 'Chatbot'" class="bot-response-left">
                        <span>
                            <img height="20px" alt='Bot Avatar' src="assets/bot icon.png" />
                        </span>
                        <div class="bot">
                            <div [innerHTML]="message.botMessage.content">
                            </div>
                            <div *ngIf="(message.botMessage.responseType !== 'small-talk' && 
                            message.botMessage.responseType !== 'not-answered' && 
                            message.botMessage.responseType !=='no-content' &&
                            message.botMessage.responseType !=='sensitive-question')" class='feedbak-img-style'
                                (click)="showAugmentContent(this.selectedConversation, message.userMessage.content, i)">
                                <img height="15" class='cursor' src='assets/tag.svg' alt='Tag' />
                            </div>
                        </div>
                    </div>
                    <div class="time-format-left">{{dateFormatService.convertUtcToLocal(message.botMessage.timestamp)}}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="justify-center">{{'chatBot.ENDED' | translate}}</div>
    </div>
</div>
<div class="drawer-overlay" [ngClass]="{'open': isOpen}"></div>