<div *ngIf="data?.isValidDisclosure">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h1 mat-dialog-title>{{'disclosure.DISCLOSURE' | translate}}</h1>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <table>
            <tr>
                <th>{{'disclosure.TRANS_TYPE' | translate}}</th>
                <td>{{data.transaction_type}}</td>
            </tr>
            <br>
            <tr>
                <th>{{'disclosure.TRANS_TYPE_CTN' | translate}}</th>
                <td>{{data.transaction_type_content}}</td>
            </tr>
            <br>
            <tr>
                <th>{{'disclosure.TRANS_DISCL' | translate}}</th>
                <td>{{data.transaction_disclosures}}</td>
            </tr>
            <br>
            <tr>
                <th>{{'disclosure.TRANS_NOTES' | translate}}</th>
                <td>{{data.transaction_notes}}</td>
            </tr>
            <br>
        </table>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button [mat-dialog-close]="data" class="mui-button" cdkFocusInitial>{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.isValidEvalData">
    <div class="title-class">
        <h1 mat-dialog-title>{{'disclosure.EVALU_DETAILS' | translate}}</h1>
    </div>
    <div mat-dialog-content>
        <div class="mat-elevation-z8">
            <mat-table class="width-100" mat-table [dataSource]="dataSource">

                <!-- Question Column -->
                <ng-container matColumnDef="question">
                    <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_QUESTION' |
                        translate }}</th>
                    <td (click)="copyContent(element.question)" mat-cell *matCellDef="let element" class="cursor">{{
                        element.question }}</td>

                </ng-container>
                <!-- Received Response Column -->
                <ng-container matColumnDef="received_response">
                    <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_RES' | translate }}
                    </th>
                    <td (click)="copyContent(element.received_response)" mat-cell *matCellDef="let element"
                        class="cursor">{{
                        element.received_response }}
                    </td>
                </ng-container>

                <!-- Ground Truth Column -->
                <ng-container matColumnDef="ground_truth">
                    <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_GRND_TRH' |
                        translate }}</th>
                    <td (click)="copyContent(element.ground_truth)" mat-cell *matCellDef="let element" class="cursor">{{
                        element.ground_truth }} </td>
                </ng-container>

                <!-- Context Column -->
                <ng-container matColumnDef="context">
                    <th mat-header-cell *matHeaderCellDef>{{ 'disclosure.EVAL_VIEW_CONTEXT' | translate
                        }}</th>
                    <td (click)="copyContent(element.context)" mat-cell *matCellDef="let element" class=" cursor">{{
                        element.context }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <p><span>*</span> {{'disclosure.EVALU_COPY_NOTE' | translate}}</p>
        <button mat-button [mat-dialog-close]="data" class="mui-button" cdkFocusInitial>{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.isValidCompareData">
    <div>
        <div fxFlex="row" fxLayoutAlign="space-between center">
            <h1 mat-dialog-title>{{'disclosure.COM_HEAD' | translate}}</h1>
            <button mat-icon-button (click)="exportToExcel();$event.stopPropagation();">
                <mat-icon matTooltip="{{'sideNav.DOWNLOAD_TEXT_RUNID_DATA' | translate}}" aria-hidden="false"
                    aria-label="Download" fontIcon="file_download"></mat-icon>
            </button>
        </div>
    </div>
    <div mat-dialog-content>
        <div *ngIf="isLoading; else tableCompare" class="center-alignment ">
            <app-loader [botLoderType]="botLoderType"></app-loader>
        </div>
        <ng-template #tableCompare>
            <table mat-table [dataSource]="comparingDatas" class="mat-elevation-z8">

                <!-- Key Column -->
                <ng-container matColumnDef="key">
                    <th mat-header-cell *matHeaderCellDef> {{'disclosure.COM_KEY' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.key}} </td>
                </ng-container>

                <!-- Answer Correctness Column -->
                <ng-container matColumnDef="answer_correctness">
                    <th mat-header-cell *matHeaderCellDef> {{'disclosure.EVAL_VIEW_ANS_CRT' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.answer_relevancy |
                        number:'1.3-3'}} </td>
                </ng-container>

                <!-- Answer Relevancy Column -->
                <ng-container matColumnDef="answer_relevancy">
                    <th mat-header-cell *matHeaderCellDef> {{'disclosure.COM_ANS_RELEV' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.answer_relevancy |
                        number:'1.3-3'}} </td>
                </ng-container>

                <!-- Context Precision Column -->
                <ng-container matColumnDef="context_precision">
                    <th mat-header-cell *matHeaderCellDef> {{'disclosure.COM_CONT_PRE' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.context_precision |
                        number:'1.3-3'}} </td>
                </ng-container>

                <!-- Context Recall Column -->
                <ng-container matColumnDef="context_recall">
                    <th mat-header-cell *matHeaderCellDef> {{'disclosure.COM_CONT_RECAL' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.context_recall |
                        number:'1.3-3'}} </td>
                </ng-container>

                <!-- Faithfulness Column -->
                <ng-container matColumnDef="faithfulness">
                    <th mat-header-cell *matHeaderCellDef> {{'disclosure.COM_FAITH' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.faithfulness |
                        number:'1.3-3'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedCompareColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedCompareColumns;"></tr>
            </table>
        </ng-template>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button [mat-dialog-close]="data" class="mui-button" cdkFocusInitial>{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.isValidValidator">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h1 mat-dialog-title>{{'sideNav.VALIDATION_URL' | translate}}</h1>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <label>{{'sideNav.VALIDATION_TYPE_TEXT' | translate}}</label>
                <mat-radio-group class="bg-white" [(ngModel)]="validationOption"
                    (change)="validationHandleRadioChange()">
                    <mat-radio-button class="bg-white" value="validation">{{'sideNav.VALIDATION' | translate}}</mat-radio-button>
                    <mat-radio-button value="novalidation" [disabled]="validatorSelectedOption ==='db'">{{'sideNav.NON_VALIDATION' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start">
                <label>{{'sideNav.EVAL_TYPE_TEXT' | translate}}</label>
                <mat-radio-group class="bg-white" [(ngModel)]="validatorSelectedOption"
                    (change)="validatorHandleRadioChange()">
                    <mat-radio-button class="bg-white" value="csv">{{'sideNav.CSV' | translate}}</mat-radio-button>
                    <mat-radio-button value="db" [disabled]="validationOption === 'novalidation'">{{'sideNav.DB' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <!-- Evaluate Option -->
            <div fxLayout="column" fxLayoutAlign="start start" *ngIf="this.validatorSelectedOption === 'csv'">
                <label>{{'sideNav.EVALUATION_OPTION' | translate}}</label>
                <mat-radio-group [(ngModel)]="evaluationOption">
                    <mat-radio-button value="conversation">{{'sideNav.CONVER_TEXT' | translate}}</mat-radio-button>
                    <mat-radio-button value="bulk">{{'sideNav.BULK_TEXT'
                        | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <span class="font-size12"
                *ngIf="!isShowFileName && this.validatorSelectedOption === 'csv'">{{'sideNav.PLUSE_ICON_HELP_TEXT' |
                translate}} </span>
            <!-- Content Name Input Field -->
            <mat-form-field *ngIf="!isShowFileName && this.validatorSelectedOption === 'csv'">
                <mat-label>{{'sideNav.FILENAME_TEXT' | translate}}</mat-label>
                <input matInput (ngModelChange)="handleFileNameInput($event)" #validatorFileNam="ngModel"
                    name="validatorFileName" [(ngModel)]="validatorFileName"
                    placeholder="{{'sideNav.Enter_File_Name' | translate}}" required>
                <button mat-icon-button matSuffix (click)="showFileName()" *ngIf="validationOption !=='novalidation'">
                    <mat-icon>add_circle_outline</mat-icon></button>
                <mat-error
                    *ngIf="validatorFileNam?.invalid && (validatorFileNam?.dirty || validatorFileNam?.touched)">{{'errorMessages.VALIDATOR_FILE_NAME_REQ'
                    | translate}}</mat-error>
                <div *ngIf="fileNameError" class="error-message">
                    {{'sideNav.FILE_NAME_VALIDATION_TEXT' | translate}}
                </div>
            </mat-form-field>
            <div *ngIf="isShowFileName && this.validatorSelectedOption === 'csv'">
                <div fxFlex="row" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10">
                    <mat-form-field>
                        <mat-label>{{'sideNav.HISTORY_FILENAME_TEXT' |
                            translate}}</mat-label>
                        <mat-select [(ngModel)]="historyFileName" #histFileNam="ngModel" name="historyFileName"
                            required>
                            <mat-option *ngFor="let option of validatorOptions" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="histFileNam?.invalid && (histFileNam?.dirty || histFileNam?.touched)">{{'errorMessages.VALIDATOR_FILE_NAME_REQ'
                            | translate}}</mat-error>
                    </mat-form-field>
                    <button mat-icon-button matTooltip="{{'validator.BACK_TO_FILE' | translate}}"
                        (click)="backTextboxFileName()">
                        <img src="assets/back_icon.png" alt="back icon" />
                    </button>
                </div>
            </div>
            <!--Test Size Option-->
            <mat-form-field *ngIf="this.validatorSelectedOption === 'db'">
                <mat-label>{{'sideNav.TEST_SIZE' | translate}}</mat-label>
                <mat-select [(ngModel)]="testSize" #tstSize="ngModel" name="testSize" required>
                    <mat-option *ngFor="let option of testSizeOptions" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="tstSize?.invalid && (tstSize?.dirty || tstSize?.touched)">{{'errorMessages.VALIDATOR_TEST_SIZE_REQ'
                    | translate}}</mat-error>
            </mat-form-field>
            <!-- File Upload -->
            <div class="file-upload" *ngIf="validatorSelectedOption === 'csv' && !isShowFileName">
                <div class="drop-area">
                    <ng-container *ngIf="validationSelectedFile; else noFiles">
                        <div class="selected-file">
                            <p>{{ validationSelectedFile.name }}</p>
                            <p>{{ formatSize(validationSelectedFile.size) }}</p>
                            <p (click)="validationHandleDeleteFile()">
                                <img src="assets/trash 1-white.svg" class="cursor" alt="Remove File" />
                                {{'sideNav.REMOVE' |
                                translate}}
                            </p>
                        </div>
                    </ng-container>
                    <ng-template #noFiles>
                        <div>
                            <div class="head-center" (click)="triggerFileInput()">
                                <div fxLayout="column" fxLayoutAlign="center center">
                                    <label class="bg-white">{{'sideNav.SAMPLE_FORMAT' | translate}}</label>
                                    <button mat-icon-button
                                        matTooltip="{{'sideNav.VALIDATION_DOWNLOADTEMPLATE' | translate}}"
                                        (click)="sampleFormat()"><img alt="profile" height="50" width="50"
                                            class="invert-img" src="assets/document-download.png" /></button>
                                </div>
                                <span class="font-size12 bg-white">{{'sideNav.TEMP_DOWN_NOTE' | translate}}</span>
                                <br>
                                <div (drop)="validationhandleDrop($event)" (dragover)="preventDefault($event)">
                                    <label for="file-input" class="head-center bg-white cursor">
                                        <!-- <span class="red-label">*</span> -->
                                        {{'sideNav.DRAG_DROP_TEXT' | translate}} <span
                                            class="choose-file bg-white">{{'sideNav.CHOOSE_FILE' | translate}}</span>
                                    </label>
                                    <input type="file" id="file-input" [accept]="'.csv, .xlsx'"
                                        (change)="validationHandleFileChange($event)" class="input-file-style"
                                        required />
                                    <div class="head-center">
                                        <img src="assets/file-upload.svg" height="50" alt="Upload Icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <br>
            <!-- Language Type Dropdown -->
            <mat-form-field class="mat-mdc-form-field">
                <mat-label>{{'sideNav.LANGUAGE_TYPE' | translate}}</mat-label>
                <mat-select [(ngModel)]="validatorlanguageType" required>
                    <mat-option *ngFor="let lang of languageTypes" [value]="lang">{{ lang }}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- Run ID Input Field -->
            <mat-form-field class="mat-mdc-form-field">
                <mat-label>{{'sideNav.RUNID_TEXT' | translate}}</mat-label>
                <input matInput [(ngModel)]="validatorRunId" #validateRunIDs="ngModel" (ngModelChange)="validateRunID()"
                    placeholder="{{'sideNav.Enter_RunID'|translate}}" maxlength="15" required>
                <mat-error
                    *ngIf="validateRunIDs?.invalid && (validateRunIDs?.dirty || validateRunIDs?.touched)">{{'errorMessages.VALIDATOR_RUNID_REQ'
                    | translate}}</mat-error>
            </mat-form-field>
            <!-- Comment Input Field -->
            <mat-form-field class="mat-mdc-form-field">
                <mat-label>{{'chatBot.CMT_TEXT' | translate}}</mat-label>
                <textarea matInput [(ngModel)]="validatorCmt" #nvalidatorCmd="ngModel" required></textarea>
                <mat-error
                    *ngIf="nvalidatorCmd?.invalid && (nvalidatorCmd?.dirty || nvalidatorCmd?.touched)">{{'errorMessages.VALIDATOR_COMMENT_REQ'
                    | translate}}</mat-error>
            </mat-form-field>
            <button mat-raised-button [disabled]="!isFormValid()" class="mui-button mat-mdc-form-field"
                (click)="submitValitor()">{{'forgotPassword.SUMBIT_BTN_LABEL' | translate}}</button>
        </div>
        <div *ngIf="isModalLoader" class="modal-backdrop">
            <app-loader [botLoderType]="botLoderType"></app-loader>
        </div>
    </div>
</div>
<div *ngIf="data?.isMismatchField">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h1 mat-dialog-title>{{'sideNav.VALIDATION_URL' | translate}}</h1>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <!-- {{data.messageMismatchText}} -->
        <div *ngIf="data?.messageMismatchText.startsWith('Test sizes are not the same for run IDs:'); else csvcontent">
            <div class="red-color font-size12">{{'headers.COMPARE_TXT_HELP_DB' | translate}}</div>
        </div>
        <ng-template #csvcontent>
            <div class="red-color font-size12">{{'headers.COMPARE_TXT_HELP_CSV' | translate}}</div>
        </ng-template>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button [mat-dialog-close]="data" class="mui-button" cdkFocusInitial>{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.isDisclosureFrmCache">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h1 mat-dialog-title>{{'disclosure.DISCLOSURE' | translate}}</h1>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <mat-selection-list #transaction [(ngModel)]="currentTransction" [multiple]="false">
            <mat-label>{{'cache.TRANS_DROPDOWN_LABEL' | translate}}</mat-label>
            <mat-list-option *ngFor="let transaction of disclosureFromCache" [value]="transaction"
                (mouseenter)="showPopover($event, transaction.transaction_type_content)" (mouseleave)="hidePopover()">
                {{transaction.transaction_type}}
            </mat-list-option>
        </mat-selection-list>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <p>* {{'cache.TRANS_CACHE_HELP_TEXT' | translate}}</p>
        <button mat-button [mat-dialog-close]="transaction" [disabled]="!currentTransction" class="mui-button"
            cdkFocusInitial>{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.isUserComments">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h1 mat-dialog-title>{{'userComments.USER_CMD_TITLE' | translate}}</h1>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label> {{ 'feedback.LANGUAGE_TYPE' | translate }}</mat-label>
            <mat-select #language="ngModel" name="language" [(ngModel)]="languageTypeSelected" required>
                <mat-option *ngFor="let language of languageTypeOptions" [value]="language.value">
                    {{ language.label }}
                </mat-option>
            </mat-select>
            <mat-error
                *ngIf="language?.invalid && (language?.dirty || language?.touched)">{{'errorMessages.CONTENT_MGMT_LANGUAGE_EMPTY'
                | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="width-100-percent">
            <mat-label>{{'cache.ADD_QUESTION' | translate}}</mat-label>
            <input matInput #feedback="ngModel" name="feedback" [(ngModel)]="feedbackQuestion" required>
            <mat-error
                *ngIf="feedback?.invalid && (feedback?.dirty || feedback?.touched)">{{'errorMessages.CACHEQ&AQUESTION_REQ_ERROR'
                | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="width-100-percent">
            <mat-label> {{'cache.ADD_RESPONSE' | translate}}</mat-label>
            <input matInput #fdAnswer="ngModel" name="fdAnswer" [(ngModel)]="feedbackAnswer" required>
            <mat-error
                *ngIf="fdAnswer?.invalid && (fdAnswer?.dirty || fdAnswer?.touched)">{{'errorMessages.CACHEQ&ARESPONSE_REQ_ERROR'
                | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'feedback.TARGET_TYPE' | translate }}</mat-label>
            <mat-select #fdTypes="ngModel" name="fdTypes" [(ngModel)]="feedbackTypes" disabled="true" required>
                <mat-option *ngFor="let rating of feedbackTypeOptions" [value]="rating">{{ rating }}</mat-option>
            </mat-select>
            <mat-error
                *ngIf="fdTypes?.invalid && (fdTypes?.dirty || fdTypes?.touched)">{{'errorMessages.REQUIRED_FEEDBACK_TYPE'
                | translate}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label> {{ 'feedback.FEEDBACK_FLAG' | translate }}</mat-label>
            <mat-select #fdFlagType="ngModel" name="fdFlagType" [(ngModel)]="feedbackFlagType" required>
                <mat-option *ngFor="let flag of feedbackFlagTypeOptions" [value]="flag">{{ flag }}</mat-option>
            </mat-select>
            <mat-error
                *ngIf="fdFlagType?.invalid && (fdFlagType?.dirty || fdFlagType?.touched)">{{'errorMessages.REQUIRED_FD_FLAG'
                | translate}}</mat-error>
        </mat-form-field>

    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button [disabled]="!validateUserComments()" class="mui-button mat-mdc-form-field"
            (click)="saveUserFeedback()">{{'forgotPassword.SUMBIT_BTN_LABEL' | translate}}</button>
    </div>
</div>
<div *ngIf="data?.isConversationTimout">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h1 mat-dialog-title class="red-label">{{'chatBot.CONVERSATION_TIMEOUT_TEXT' | translate}}</h1>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div fxLayoutAlign="center center">{{data.conversationTimoutMessage}}</div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button class="mui-button" (click)="onNoClick()" cdkFocusInitial>{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.isCacheUserResponse">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h1 mat-dialog-title *ngIf="data?.title ==='User Comments'">{{'chatBot.USER_CMTS_RESPONSE_DETAILS' | translate}}
        </h1>
        <h1 mat-dialog-title *ngIf="data?.title ==='Feedback Option'">{{'chatBot.FEEDBACK_RESPONSE_DETAILS' |
            translate}}</h1>
        <h1 mat-dialog-title *ngIf="data?.title ==='CacheQnA'">{{'chatBot.CACHE_RESPONSE_DETAILS' | translate}}</h1>
        <!-- <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button> -->
    </div>
    <div mat-dialog-content>
        <div fxLayoutAlign="center center" [innerHTML]="data.cacheUserResponse"></div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
        <button mat-button class="mui-button" (click)="onNoClick()" cdkFocusInitial>{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.forceLogin">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h1 mat-dialog-title class="red-label">{{'chatBot.CACHE_RESPONSE_DETAILS' | translate}}</h1>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div fxLayoutAlign="center center">{{data.forceLoginMessage}}</div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
        <button mat-button class="mui-button" mat-dialog-close="CloseForceLogin" (click)="onNoClick()"
            cdkFocusInitial>{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.isPasswordReset">
    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" (keydown.enter)="onSubmit()">
        <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
            <h1 mat-dialog-title class="red-label">{{'forgotPassword.CANGE_PASSWORD_TITLE' | translate}}</h1>
            <button mat-icon-button (click)="onNoClick()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div mat-dialog-content>

            <mat-form-field appearance="fill">
                <mat-label>{{'forgotPassword.CURRENT_PASSWORD' | translate}}</mat-label>
                <input matInput [type]="hideCurrentPassword ? 'password' : 'text'" formControlName="currentPassword"
                    required>
                <mat-icon mat-icon-button matSuffix (click)="hideCurrentPassword = !hideCurrentPassword"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideCurrentPassword">{{hideCurrentPassword
                    ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="currentPassword?.invalid && (currentPassword?.dirty || currentPassword?.touched)">
                    {{'forgotPassword.REQUIRED_CURRENT_PASSWORD' | translate}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'forgotPassword.NEW_PASSWORD' | translate}}</mat-label>
                <input matInput [type]="hideNewPassword ? 'password' : 'text'" formControlName="newPassword" required>
                <mat-icon mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPassword">{{hideNewPassword ?
                    'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="newPassword?.invalid && (newPassword?.dirty || newPassword?.touched)">
                    <ng-container *ngIf="newPassword?.errors?.['required']">
                        {{'forgotPassword.REQUIRED_NEW_PASSWORD' | translate}}
                    </ng-container>
                    <ng-container *ngIf="newPassword?.errors?.['minlength']">
                        {{'forgotPassword.MIN_LENGTH' | translate}}
                    </ng-container>
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{'forgotPassword.CONFIRM_NEW_PASSWORD' | translate}}</mat-label>
                <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="confirmPassword"
                    required>
                <mat-icon mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">{{hideConfirmPassword
                    ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)">
                    <ng-container *ngIf="confirmPassword?.errors?.['required']">
                        {{'forgotPassword.REQUIRED_CONFIRM_NEW__PASSWORD' | translate}}
                    </ng-container>
                    <ng-container *ngIf="confirmPassword?.errors?.['mismatch']">
                        {{'forgotPassword.MISMATCH_CONFIRM_NEW_PASSWORD' | translate}}
                    </ng-container>
                </mat-error>
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button mat-button type="submit" [disabled]="changePasswordForm.invalid"
                class="mui-button mat-mdc-form-field"
                [mat-dialog-close]="changePasswordForm">{{"forgotPassword.SUMBIT_BTN_LABEL" |
                translate}}</button>
        </div>
    </form>
</div>

<div *ngIf="data?.isRemoveProfile">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h3>{{'errorMessages.PROFILE_CONFIRM_TITLE' | translate}}</h3>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div fxLayoutAlign="center center">
            <b>{{'errorMessages.PROFILE_CONFIRM_LABEL' | translate}}</b>
        </div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30">
        <button mat-button (click)="onCancelClick()">{{"cache.CANCEL" |
            translate}}</button>
        <button mat-button class="mui-button" (click)="onOkClick()">{{"devConfig.CONFIRM" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.passwordNotValid">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h3>{{'errorMessages.PASSWORD_MATCH_TITLE' | translate}}</h3>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <mat-error>
            *{{ 'errorMessages.PASSWORD_UPPER_CASE_ERROR' | translate }}
        </mat-error>
        <mat-error>
            *{{ 'errorMessages.PASSWORD_LOWER_CASE_ERROR' | translate }}
        </mat-error>
        <mat-error>
            *{{ 'errorMessages.PASSWORD_NUMERIC_ERROR' | translate }}
        </mat-error>
        <mat-error>
            *{{ 'errorMessages.PASSWORD_SPECIAL_CHAR_ERROR' | translate }}
        </mat-error>
        <mat-error>
            *{{ 'errorMessages.PASSWORD_LENGTH_ERROR' | translate }}
        </mat-error>
        <mat-error>
            *{{ 'errorMessages.PASSWROD_CONSECUTIVE_ERROR' | translate }}
        </mat-error>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">

        <button mat-button class="mui-button" (click)="onOkClick()">{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data?.passwordNotSamePrevious">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h3>{{'errorMessages.PASSWORD_MATCH_TITLE' | translate}}</h3>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <mat-error>
            *{{ 'errorMessages.PASSWORD_SAME_PREVIOUS' | translate }}
        </mat-error>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">

        <button mat-button class="mui-button" (click)="onOkClick()">{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data.isRatingRquiredClicked">
    <div mat-dialog-content>
        <div>{{'errorMessages.FEEDBACK_REQ_INFORMATION' | translate}}</div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">

        <button mat-button class="mui-button" (click)="onOkClick()">{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
<div *ngIf="data.isUserCommentsRow">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h3>{{'userComments.APPROVED_SUB_ALL' | translate}}</h3>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="popup-table-container">

        <table mat-table [dataSource]="[userCommentsData]" class="full-width alternate-rows">
            <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef class="width300 head-bold">
                    {{'disclosure.EVAL_VIEW_QUESTION' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="width300">{{ element?.question }}</td>
            </ng-container>

            <ng-container matColumnDef="response">
                <th mat-header-cell *matHeaderCellDef class="head-bold" [ngClass]="currentSubTabLabel === 'Cache Recommendation' ? 'width600': 'width400'">
                    {{'disclosure.EVAL_VIEW_RES' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHTML]="element?.response || 'N/A'" [ngClass]="currentSubTabLabel === 'Cache Recommendation' ? 'width600': 'width400'"></div>
                </td>
            </ng-container>
            <ng-container matColumnDef="feedback_comment"
                *ngIf="currentSubTabLabel !== 'Cache Recommendation' && currentSubTabLabel !== 'Recommandations de cache'">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{'feedback.FEEDBACK_VALUE'|
                    translate}}</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element?.feedback_comment">{{
                    element?.feedback_comment === ''? 'N/A':
                    (element.feedback_comment | titlecase) }}</td>
            </ng-container>
            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef [ngClass]="currentSubTabLabel === 'Cache Recommendation' ? 'width200': 'width100'" class="head-bold">
                    {{'sensitiveQuestion.GIVEN_BY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" [ngClass]="currentSubTabLabel === 'Cache Recommendation' ? 'width200': 'width100'" [matTooltip]="element?.username">{{
                    element?.username }}</td>
            </ng-container>

            <ng-container matColumnDef="additional_comment"
                *ngIf="currentSubTabLabel !== 'Cache Recommendation' && currentSubTabLabel !== 'Recommandations de cache'">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{'chatBot.ADDITINAL_CMT_TEXT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ element?.additional_comment || 'N/A' }}</td>
            </ng-container>

            <ng-container matColumnDef="approved_rejected_by">
                <th mat-header-cell *matHeaderCellDef class="width100 head-bold">
                    <div>
                        {{ currentTabLabel === 'Approved' ? 'Approved By': (currentTabLabel === 'Rejected' ? "Rejected By":'')}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let element" class="width100" [matTooltip]="element?.approved_rejected_by">{{
                    element?.approved_rejected_by || 'N/A' }}</td>
            </ng-container>

            <ng-container matColumnDef="language_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'feedback.LANGUAGE_TYPE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.language_type=== null ||
                    element?.language_type ==="")?
                    'N/A':
                    element?.language_type === 'en'? "English" : "French" }}</td>
            </ng-container>
            <ng-container matColumnDef="given_timestamp">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'userComments.GIVEN_TIME'| translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{
                    dateFormat.convertUtcToLocalSlash(element?.given_timestamp) }}</td>
            </ng-container>
            <ng-container matColumnDef="feedback_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'feedback.TARGET_TYPE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.feedback_type=== null ||
                    element?.feedback_type ==="")?
                    'N/A':
                    ( formatFeedbackTypeDsiaplay(element?.feedback_type) | titlecase)}}</td>
            </ng-container>
            <ng-container matColumnDef="response_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'feedback.RESPONSE_TYPE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.response_type=== null ||
                    element?.response_type ==="")?
                    'N/A':
                    (formatFeedbackTypeDsiaplay(element?.response_type) | titlecase) }}</td>
            </ng-container>
            <!-- <ng-container matColumnDef="images">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'chatBot.IMAGE_TEXT' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="getImageKeys(row).length > 0; else noImages">
                        <div *ngIf="getImageKeys(row).length > 0; else noImages">
                            <div *ngFor="let key of getImageKeys(row)">
                                <div (click)="previewImage($event, row.images, key)" class="image-key">
                                    {{ key }}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #noImages>
                        <div>No images</div>
                    </ng-template>
                </td>
            </ng-container> -->
            <ng-container matColumnDef="approveRejectComments">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'feedback.ACTION_CMD' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.approveRejectComments=== null || element?.approveRejectComments=== "" ||
                    element?.response_type ==="")?
                    'N/A':
                    (element?.approveRejectComments | titlecase) }}</td>
            </ng-container>
            <ng-container matColumnDef="action_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'userComments.ACTION_TYPE'| translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{element?.action_type }}</td>
            </ng-container>
            <ng-container matColumnDef="model_update_status">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{ currentTabLabel === 'Approved' ?
                    'Action Taken' : currentTabLabel === 'Approved' ? 'Model Update Status': '' }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container
                        *ngIf="(feedbackTypes === 'All' || feedbackTypes === 'Sensitive' || feedbackTypes ==='Cache Recommendation') && currentTabLabel === 'Approved'">
                        {{ element.model_update_status ? 'Model Updated' : 'Model Not updated' }}
                    </ng-container>
                    <ng-container
                        *ngIf="(feedbackTypes === 'No Content' || feedbackTypes === 'Normal' || feedbackTypes ==='Not Answered' || feedbackTypes === 'Cache') && currentTabLabel === 'Approved'">
                        {{ element.model_update_status ? 'Yet to be Taken' : 'Yet to be Taken' }}
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="head-bold width130">{{'devConfig.ACTION'| translate}}</th>
                <td mat-cell *matCellDef="let element; let index;" class="width130">
                    <button mat-icon-button *ngIf="currentTabLabel === 'Pending'"  matTooltip="{{'feedback.ACTION_TOOLTIP' | translate}}" class="cursor"
                        (click)="data.confirmationDialog(element, index)">
                        <img alt="Approve/Reject" height="15" width="15" src="assets/approve_reject_confirm_icon.png" />
                    </button>
                    <button mat-icon-button *ngIf="currentSubTabLabel !== 'Cache Recommendation' && currentSubTabLabel !== 'Recommandations de cache'" [disabled]="element?.conversation_id === ''" matTooltip="{{'feedback.CHAT_VIEW' | translate}}" class="cursor"
                        (click)="openChatConversation(element, index);$event.stopPropagation();">
                        <img alt="View Chat Conversation" [class.disable-img]="element?.conversation_id === ''" height="15" width="15" src="assets/chitchat.png" />
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="userCommentsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: userCommentsColumns;"></tr>
        </table>
    </div>
    <app-conversation-drawer [selectedConversation]="selectedConversation"
    (drawerClosed)="handleDrawerClosed()"></app-conversation-drawer>
</div>
<div *ngIf="data.isCacheQnAFullRow">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h3>{{'sideNav.CACHE' | translate}}</h3>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="popup-table-container">
        <table mat-table [dataSource]="[cacheQnARowDetails]" class="full-width alternate-rows">
            <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'cache.QUESTION' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{row.question}}</td>
            </ng-container>

            <ng-container matColumnDef="generated_response">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'cache.RESPONSE' | translate}}</th>
                <td mat-cell *matCellDef="let row" [innerHtml]="row.generated_response"></td>
            </ng-container>
            <ng-container matColumnDef="images">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'chatBot.IMAGE_TEXT' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="getImageKeys(row).length > 0; else noImages">
                        <div *ngIf="getImageKeys(row).length > 0; else noImages">
                            <div *ngFor="let key of getImageKeys(row)">
                                <div (click)="previewImage($event, row.images, key)" class="image-key">
                                    {{ key }}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-template #noImages>
                        <div>No images</div>
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="added_by">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'cache.USER' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{
                    row.added_by === '' ?'N/A' : row.added_by }}</td>
            </ng-container>

            <ng-container matColumnDef="date_time">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.DATE_TIME' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">{{
                    dateFormat.convertUtcToLocalUnderScore(row.date_time) }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="cacheQnAColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: cacheQnAColumns;"></tr>
        </table>
    </div>

</div>
<div *ngIf="data.isFeedbackFullRow">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="padding-10">
        <h3>{{'sensitiveQuestion.RATING_TYPES_OPTIONS' | translate}}</h3>
        <button mat-icon-button (click)="onNoClick()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content class="popup-table-container">
        <table mat-table [dataSource]="[feedbackRowDetails]" class="full-width alternate-rows">
            <ng-container matColumnDef="feedback_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'feedback.TARGET_TYPE' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">{{formatFeedbackTypes(row.feedback_type)}}</td>
            </ng-container>

            <ng-container matColumnDef="response_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'feedback.RESPONSE_TYPE' | translate }}</th>
                <td mat-cell *matCellDef="let row">{{formatFeedbackTypes(row.response_type)}}</td>
            </ng-container>
            <ng-container matColumnDef="feedback_cmt_en">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{'feedback.FEEDBACK_CMD_LABEL_ENGLISH' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">{{row.feedback_comment_english}}</td>
            </ng-container>
            <ng-container matColumnDef="feedback_cmt_fr">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{
                    'feedback.FEEDBACK_CMD_LABEL_FRENCH' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">{{row.feedback_comment_french || 'N/A'}}</td>
            </ng-container>

            <ng-container matColumnDef="additional_cmt_req">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'feedback.ADDITIONAL_FEEDBACK_COMMENT' |
                    translate }}
                </th>
                <td mat-cell *matCellDef="let row">{{ row?.additional_comments_required || 'N/A' }}</td>
            </ng-container>

            <ng-container matColumnDef="feedback_flag">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'feedback.FEEDBACK_FLAG' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="row.feedback_type?.toLowerCase() === 'sensitive'">{{row.feedback_flag}}
                    </div>
                    <div *ngIf="row.feedback_type?.toLowerCase() !== 'sensitive'">N/A</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="data_time">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.DATE_TIME' | translate }}
                </th>
                <td mat-cell *matCellDef="let row">{{
                    dateFormat.convertUtcToLocalSlash(row.updated_timestamp) }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="feedbackOptionColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: feedbackOptionColumns;"></tr>
        </table>
    </div>
</div>
<div *ngIf="data.isChatLimitsReached">
    <div mat-dialog-content>
        <div>{{'errorMessages.CHAT_TRANSCTIPT_MSG_INFOR' | translate}}</div>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
        <button mat-button class="mui-button" (click)="onOkClick()">{{"disclosure.OKEY" |
            translate}}</button>
    </div>
</div>
