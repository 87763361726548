import { Environment } from "src/app/interface/environment.interface";

export const environment: Environment  = {
    production: false,
    apiBaseUrl: {
    chatbotURL: "https://conversation-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io",
    authService: "https://auth-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io",
    transcriptUrl: "https://transcriptservice-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io",
    configServiceURL: "https://config-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io/dev",
    chatHistoryURL: "https://history-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io",
    contentMgmtURL: "https://contentmgmtservice-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io",
    getSimilarURL: 'https://similar-question-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io',
    sensitiveURL: 'https://sensitivefilterservice-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io',
    validatorURL: 'https://evaluation-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io',
    loginUrl: "https://app-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io/login",
    feedbackUrl : "https://feedback-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io",
    reportUrl : "https://report-route-kumaranchatbotdev.apps.kumaranchatbotdevocp.eastus.aroapp.io"
    }
}

console.log('Current Environment:', 'Development');
