import { Component, Input, OnInit, Output, EventEmitter, } from '@angular/core';
import { LocalStorageService } from '../services/localStorageService';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatbotService } from "../services/chat.service";
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TagPopupModalComponent } from "../chatbot/tagPopupModal";
import { TranslationService } from '../services/translation-service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DateFormatService } from '../pipe/date-time-localize';
@Component({
  selector: 'app-conversation-drawer',
  templateUrl: './conversation-drawer.component.html',
  styleUrls: ['./conversation-drawer.component.scss']
})
export class ConversationDrawerComponent implements OnInit {
  @Input() selectedConversation: any;
  @Output() drawerClosed = new EventEmitter<void>();
  isOpen: boolean = false;
  chatHistory: any = [];
  userEmail = '';
  userProfile: any = '';
  userTypedQuestion = '';
  modalPopupResponse: any;
  questionIndex: any;
  translate: any;
  isMobileView: boolean = false;
  constructor(
    private sessionStorage: LocalStorageService,
    private sanitizer: DomSanitizer,
    private chatbotService: ChatbotService,
    public dialog: MatDialog,
    translateService: TranslationService,
    private breakpointObserver: BreakpointObserver,
    public dateFormatService: DateFormatService) {
    this.userEmail = this.sessionStorage.get('username');
    this.translate = translateService;
    this.breakpointObserver.observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobileView = result.matches;
    });
  }

  ngOnInit(): void {
    let userImage = this.sessionStorage.get('profile_picture');
    this.chatbotService.profilePictureUrl = userImage !== '' ? this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${userImage}`) : 'assets/noImage.png';
    this.userProfile = this.chatbotService.profilePictureUrl;
  }
  ngOnChanges() {
    if (this.selectedConversation?.conversation?.length >= 0) {
      this.chatHistory = this.selectedConversation?.conversation.map((message: any) => ({
        userMessage: { type: 'User', content: message.question, timestamp: message.timestamp, responseType: message.responseType },
        botMessage: { type: 'Chatbot', content: message.response, timestamp: message.timestamp, responseType: message.responseType}
      }));
    }
    if (this.selectedConversation && this.selectedConversation?.drawerOpen) {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
  }
  
  toggleDrawer(): void {
    this.isOpen = !this.isOpen;
  }
  toggleClose(): void {
    this.isOpen = !this.isOpen;
    if (!this.isOpen) {
      this.drawerClosed.emit(); // Emit the drawer close event
    }
  }
  showAugmentContent(message: any, selectedQuestion: any, index: number) {
    this.userTypedQuestion = selectedQuestion;
    this.modalPopupResponse = message?.conversation;
    this.questionIndex = index;
    this.openDialog('0ms', '0ms');
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    const dialogRef: MatDialogRef<TagPopupModalComponent> = this.dialog.open(TagPopupModalComponent, {
      width: '100%',
      data: {
        enterAnimationDuration,
        exitAnimationDuration,
        userInput: this.userTypedQuestion,
        responseFromBot: this.modalPopupResponse,
        fromRightDrawer: true,
        questionIndex: this.questionIndex
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
