import { Injectable } from '@angular/core';
import { LocalStorageService } from './localStorageService';

@Injectable({
  providedIn: 'root'
})
export class FrenchCharacterValidatorService {

  constructor(private sessionStorage: LocalStorageService) {}

  validateFrenchCharacters(inputValue: string): string | null {
    if (this.sessionStorage.get('language') !== 'fr') {
      return null; // No validation needed if language is not French
    }

    const allowedFrenchRegex = /^[ÄäáìíÖöòóÜúÆæàâçéèêëÏîïôùûüÀÂÇÈÉÊËÎÔÙÛŸÿ0-9\s]*$/;
    const frenchCharacterRegex = /[ÄäáìíÖöòóÜúÆæàâçéèêëÏîïôùûüÀÂÇÈÉÊËÎÔÙÛŸÿ]/;

    if (!allowedFrenchRegex.test(inputValue)) {
      return 'Only specific French characters and numbers are allowed.';
    } else if (!frenchCharacterRegex.test(inputValue)) {
      return 'Input must include French character.';
    } else {
      return null; // No error
    }
  }
}
