<div class="header-forget" role="banner">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="stretch center">
        <img height="30px" alt="Bot Avatar" src="assets/bot icon.png" />
        <div class='cibc-title-style'>CIBC {{'chatBot.VIR_ASSISTANT_TEXT' | translate}}</div>
      </div>
</div>
<div class="container">
    <div *ngIf="submitted; else resetFormSection">
        <h4>{{'forgotPassword.PASSWORD_RESET_SUCCES_MSG_LABEL' | translate}}</h4>
        <p>{{'forgotPassword.SUCCESS_TEXT_MSG' | translate}}</p>
        <p>
            <a [href]="loginURL">{{'forgotPassword.LOGIN_URL_LABEL' | translate}}</a>
        </p>
    </div>
    <ng-template #resetFormSection>
        <h4>{{'forgotPassword.RESET_CREDEN_LABEL' | translate}}</h4>
        <form [formGroup]="resetForm" (ngSubmit)="handleSubmit()">
            <mat-form-field appearance="outline" >
                <mat-label>{{'login.USERNAME' | translate}}</mat-label>
                <input matInput formControlName="userName" type="text" required>
                <mat-error *ngIf="resetForm.get('userName')?.hasError('required')">
                    {{'forgotPassword.EMAIL_REQUIRED_LABEL' | translate}}
                </mat-error>
                <mat-error *ngIf="resetForm.get('userName')?.hasError('invalidEmail') && !resetForm.get('userName')?.hasError('required')">
                    {{ 'errorMessages.VALID_EMAIL_ERROR' | translate }}
                  </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" >
                <mat-label>{{'forgotPassword.NEW_PASSWORD_LABEL' | translate}}</mat-label>
                <input matInput formControlName="newPassword" [type]="hideConfirmPassword ? 'password' : 'text'" required>
                <mat-icon mat-icon-button matSuffix (click)="hideConfirmPassword = !hideConfirmPassword"
                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                <mat-error *ngIf="resetForm.get('newPassword')?.hasError('required')">
                    {{'errorMessages.PASSWORD_REQUIRED' | translate}}
                </mat-error>
            </mat-form-field>
            <br>
            <button mat-raised-button class="mui-button" [disabled]="resetForm.invalid" type="submit" >{{'forgotPassword.RESET_CREDEN_LABEL' | translate}}</button>
        </form>
    </ng-template>
</div>