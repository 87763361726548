import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {
  constructor() {}

  convertUtcToLocal(customUtcDate: string): string {
    // Define the custom format
    const format = 'YYYY:MM:DD HH:mm:ss';
    
    // Parse the date with the custom format
    const utcDate = moment.utc(customUtcDate, format);

    // Check if the date is valid
    if (!utcDate.isValid()) {
      return 'Invalid date';
    }

    return utcDate.local().format('MM/DD/YYYY hh:mm A');
  }

  convertUtcToLocalUnderScore(customUtcDate: string): string {

    // Define the correct format
    const format = 'YYYY-MM-DD HH:mm:ss';

    // Parse the date with the correct format
    const utcDate = moment.utc(customUtcDate, format);

    // Check if the date is valid
    if (!utcDate.isValid()) {
      return 'Invalid date';
    }

    return utcDate.local().format('MM/DD/YYYY hh:mm A');
  }
  convertUtcToLocalSlash(customUtcDate: string): string {
    // Define the custom format
    const inputFormat = 'DD/MM/YYYY HH:mm:ss';
    
    // Parse the date with the custom format
    const utcDate = moment.utc(customUtcDate, inputFormat);

    // Check if the date is valid
    if (!utcDate.isValid()) {
      return 'Invalid date';
    }

    return utcDate.local().format('MM/DD/YYYY hh:mm A');
  }

  convertUtcToLocal24HRS(customUtcDate: string): string {
    // Define the custom format
    const format = 'YYYY:MM:DD HH:mm:ss';
  
    // Parse the date with the custom format
    const utcDate = moment.utc(customUtcDate, format);
  
    // Check if the date is valid
    if (!utcDate.isValid()) {
      return 'Invalid date';
    }
  
    return utcDate.local().format('MM/DD/YYYY HH:mm');
  }
}
