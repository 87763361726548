// image-preview.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services/localStorageService';

@Injectable({ providedIn: 'root' })
export class ImagePreviewService {
  constructor(private http: HttpClient, private getSession: LocalStorageService) { }

  getImageKeys(row: any): any[] {
    if (row.images && row.images.length > 0) {
      const keys = new Set(row.images.flatMap((image: any) => Object.keys(image)));
      return Array.from(keys);
    }
    return [];
  }

  previewImage(event: MouseEvent, images: any[], key: string): Observable<any> {
    event.preventDefault();
    const imageUrl = images.find(image => image[key])[key];
    const regex = /view_image/;
    if (regex.test(imageUrl)) {
      return this.downloadFile(imageUrl);
    } else {
      return new Observable((observer) => observer.error('Invalid image URL'));
    }
  }

  downloadFile(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${this.getSession.get('appTo')}`
      }
    });
  }
}
