import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChatbotService } from "../services/chat.service";
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from '../services/localStorageService';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DateFormatService } from '../pipe/date-time-localize';
@Component({
  selector: 'app-chathistory',
  templateUrl: './chathistory.component.html',
  styleUrls: ['./chathistory.component.scss']
})
export class ChathistoryComponent implements OnInit {
  chatHistory: any = [];
  userProfile: any = '';
  isLoader = false;
  botLoderType = 'barLoader';
  isMobileScreen: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private chatbotService: ChatbotService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer,
    private sessionStorage: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    private dateFormatService: DateFormatService
  ) {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileScreen = result.matches;
      });
  }
  ngOnInit(): void {
    let userImage = this.sessionStorage.get('profile_picture');
    this.chatbotService.profilePictureUrl = userImage !== '' ? this.sanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64,${userImage}`) : 'assets/noImage.png';
    this.userProfile = this.chatbotService.profilePictureUrl;
    this.route.queryParams.subscribe(params => {
      const conversationID = params['conversationID'];
      const selectedPeriod = params['period']
      this.isLoader = true;

      if (conversationID && selectedPeriod === 'currentWeek') {
        let currentWeekPayload = {
          conversationID: conversationID,
          bot_type: this.sessionStorage.get('bot_type')
        }
        this.chatbotService.getCureentWeekSelectedHistory(currentWeekPayload).subscribe(
          (currentWeekSelectedData) => {
            this.isLoader = false;
            // Transform response data into chat history format
            this.chatHistory = this.loadChatHistory(currentWeekSelectedData);
          },
          (error) => {
            this.isLoader = false;
          }
        );
      } else if (conversationID && selectedPeriod === 'currentMonth') {
        let currentMonthPayload = {
          conversationID: conversationID,
          bot_type: this.sessionStorage.get('bot_type')
        }
        this.chatbotService.getCureentMonthSelectedHistory(currentMonthPayload).subscribe(
          (currentMonthSelectedData) => {
            this.isLoader = false;
            // Transform response data into chat history format
            this.chatHistory = this.loadChatHistory(currentMonthSelectedData);
          },
          (error) => {
            this.isLoader = false;
          });
      }
    });
  }

  loadChatHistory(conversationData: any) {
    if (!conversationData || !conversationData.length) return;

    return conversationData[0].conversation.flatMap((message: any) => [
      { type: 'User', content: message.question, timestamp: this.dateFormatService.convertUtcToLocal(message.timestamp) },
      { type: 'Chatbot', content: message.response, timestamp: this.dateFormatService.convertUtcToLocal(message.timestamp) }
    ]);
  }
}
