<div class="chatbot-container" [style.height.px]="isMobileScreen ? 730: 660" [style.left.px]="isMobileScreen ? 2 : 300"
  [style.right.px]="isMobileScreen ? 2 : 2" fxLayout="column" fxLayoutAlign="strech center">
  <div *ngIf="isLoader">
    <app-loader [botLoderType]="botLoderType"></app-loader>
  </div>
  <div *ngIf="chatHistory.length === 0 && !isLoader" fxLayoutAlign="center center">
    <div>{{'contentMgmt.NO_RECORD_FOUND' | translate}}</div>
  </div>
  <div *ngIf="!isLoader">
    <ng-container *ngFor="let message of chatHistory">
      <div [ngClass]="{'single-row-user': message.type === 'User', 'single-row-chatbot': message.type === 'Chatbot'}">
        <span *ngIf="message.type === 'Chatbot'">
          <img height="30px" alt='Bot Avatar' src="assets/bot icon.png" />
        </span>
        <div class="message" [ngClass]="{'user': message.type === 'User', 'bot': message.type === 'Chatbot'}">
          {{ message.content }}
        </div>
        <span *ngIf="message.type === 'User'">
          <img [src]="userProfile" alt="Profile Picture" height="30" class="profile-border-radius50" />
        </span>
      </div>
      <div [ngClass]="{'single-row-user': message.type === 'User', 'single-row-chatbot': message.type === 'Chatbot'}"
        class="padding-left-bottm time-font-size">{{ message.timestamp }}</div>
    </ng-container>
  </div>
</div>