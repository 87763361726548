import { Component, OnInit } from '@angular/core';
import { ContentManagementService } from "../../app/services/content-management.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogComponentComponent } from "../confirmation-dialog-component/confirmation-dialog-component.component";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslationService } from '../services/translation-service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorageService';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
import { StateService } from '../services/shared-object.service';

@Component({
  selector: 'app-developers-configuration',
  templateUrl: './developers-configuration.component.html',
  styleUrls: ['./developers-configuration.component.scss']
})
export class DevelopersConfigurationComponent {
  searchQuery: string = '';
  editedItem: any = {};
  data: any = [];
  editMode: boolean[] = [];
  pageNumber: number = 1;
  pageSize: number = 20;
  totalTablePage: number = 0;
  applicationNames: string[] = [];
  selectedApplication: string = '';
  allApiResponse: any;
  isNewRow: boolean = false;
  newApplicationValue = '';
  isLoader = false;
  translate: any;
  disableIcon = false;
  keyDisabled = true;
  isNewRowAdded = false;
  newKey: string = "";
  newValue: string = "";
  botLoderType = 'boxLoader'
  isMobileView: boolean = false;
  description: string = '';
  editModeEnabled: boolean = false;
  isModalLoader: boolean = false;
  isExpandCollapse: boolean = true;
  constructor(
    private router: Router,
    private contentMgmtService: ContentManagementService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    translateService: TranslationService,
    private sessionStorage: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    private translated: TranslateService,
    private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService,
    private stateService: StateService
  ) {
    this.translate = translateService;
  }
  ngOnInit(): void {
    this.getAllPhrases();
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileView = result.matches;
      });
    this.stateService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
  }
  getAllPhrases() {
    this.isLoader = true;
    this.contentMgmtService.getAllDevConfigruation().subscribe((configResponse: any) => {
      this.isLoader = false;
      this.allApiResponse = configResponse;
      this.applicationNames = configResponse.map((config: any) => config.applicationName);
      // Assuming you want to initially select the first application name
      if (this.applicationNames.length > 0) {
        this.selectedApplication = this.applicationNames[0];
        this.filterDataByApplication();
      }
      this.initializeEditMode();
    }, (error: any) => {
      this.isLoader = false;
    })
  }

  filterDataByApplication() {
    const selectedConfig = this.allApiResponse.find((config: any) => config.applicationName === this.selectedApplication);
    if (selectedConfig) {
      this.data = selectedConfig.data;
      this.isLoader = false;
    } else {
      this.data = [];
      this.isLoader = false;
    }
  }

  initializeEditMode() {
    this.editMode = this.data.map(() => false);
  }
  addNewRow() {
    this.isNewRow = true;
    this.disableIcon = true;
    this.isNewRowAdded = true;
  }
  addConfiguration() {
    if (this.data[0].key !== '' && this.data[0].value !== '') {
      this.data.unshift({ key: '', value: '' });
      this.editMode.unshift(true);
      this.disableIcon = true;
      this.enableKeyInput();
    } else {
      this.translated.get('errorMessages.CONFIG_KEY_VALUE_EMPTY').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }
  // Method to remove a row based on index
  removeRow(index: number): void {
    // Remove the row and its corresponding edit mode state
    this.data.splice(index, 1);
    this.editMode.splice(index, 1);

    // Check if there are any new rows left
    this.isNewRow = this.data.some((row: any) => row.key === '' && row.value === '');

    // If there are no new rows left, reset the state
    if (!this.isNewRow) {
      this.disableIcon = false;
      this.keyDisabled = true;
    }

  }
  handleEditClick(item: any, index: number) {
    this.editModeEnabled = true;
    this.editMode[index] = !this.editMode[index];
    this.disableIcon = true;
  }
  cancelEditMode(index: number) {
    this.editModeEnabled = false;
    this.editMode[index] = !this.editMode[index];
    this.disableIcon = false;
    this.getAllPhrases();
  }
  disableKeyInput() {
    this.keyDisabled = true;
  }

  enableKeyInput() {
    this.keyDisabled = false;
  }

  addNewRecord() {
    let appName = ""
    if (this.newApplicationValue !== "") {
      appName = this.newApplicationValue;
    } else {
      this.translated.get('errorMessages.APPLICATION_NAME_EMPTY').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
    if (this.newKey !== "" && this.newValue !== "") {
      const phrasePayload = {
        appName: appName,
        data: [{ key: this.newKey, value: this.newValue, config_description: this.description }],
        bot_type: this.sessionStorage.get('bot_type')
      }
      this.isModalLoader = true;
      this.contentMgmtService.performDevConfigurationAction('addNewApplication', phrasePayload).subscribe((response: any) => {
        this.translated.get('successfullMessages.CONFIG_ADDED_SUCCESS').subscribe((translatedText: string) => {
          this.toaster.success(translatedText);
        });
        this.isNewRow = false;
        this.getAllPhrases();
        this.newApplicationValue = '';
        this.disableIcon = false;
        this.newKey = '';
        this.newValue = '';
        this.description = '';
        this.isModalLoader = false;
      }, (error: any) => {
        this.isModalLoader = false;
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(['/login']);
          this.sessionStorage.clear();
        }
        if (error.status === 400) {
          if (error?.error?.message === 'Application already Exists') {
            this.translated.get('errorMessages.DEV_CONFIG_APP_EXIST').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
          }
        }
      });
    } else {
      this.translated.get('errorMessages.CONFIG_KEY_VALUE_EMPTY').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }

  handleSaveChanges(item: any, index: number) {
    if (item.key !== "" && item.value !== "") {
      const phrasePayload = {
        "appName": this.selectedApplication,
        "data": [{ key: item.key, value: item.value, config_description: item.config_description }],
        "bot_type": this.sessionStorage.get('bot_type')
      }
      this.isModalLoader = true;
      this.contentMgmtService.performDevConfigurationAction('addUpdate', phrasePayload).subscribe((response: any) => {
        this.translated.get('successfullMessages.CONFIG_UPDATED_SUCCESS').subscribe((translatedText: string) => {
          this.toaster.success(translatedText);
        });
        this.editMode[index] = false; // Disabling edit mode for value
        this.editMode[index - 1] = false; // Disabling edit mode for key
        this.getAllPhrases();
        this.disableIcon = false;
        this.keyDisabled = true;
        this.pageNumber = 1;
        this.editModeEnabled = false;
        this.isModalLoader = false;
      }, (error: any) => {
        this.isModalLoader = false;
        if (error.status === 400) {
          this.toaster.error(error?.error?.message);
        }
      });
    } else {
      this.translated.get('errorMessages.CONFIG_KEY_VALUE_EMPTY').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }

  handleDelete(item: any, i: number) {
    this.openConfirmationDialog(item)
  }


  searchTableData() {
    if (this.searchQuery !== "") {
      this.isLoader = true;
      const filteredData = this.data.filter((row: any) => {
        // Change 'key' and 'value' to the appropriate properties in your row object
        return row.key.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          row.value.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
      this.data = filteredData;
      this.isLoader = false;
      if (this.data?.length === 0) {
        this.isLoader = false;
      }
    }
  }

  clearSearch() {
    if (this.searchQuery !== "") {
      this.searchQuery = "";
      this.getAllPhrases();
    } else {
      this.toaster.info('Please Enter Search Text!');
    }
  }

  clearNewAppName() {
    this.newApplicationValue = '';
  }

  clear() {
    this.isNewRow = false;
    this.newKey = "";
    this.newValue = "";
    this.newApplicationValue = "";
    this.disableIcon = false;
  }


  openConfirmationDialog(selectedRow: any) {
    const DELETE_CONFIRM_LABEL = this.translateLabel('DELETE_CONFIRM_LABEL').translation;
    const DELETE_CONFIRM_FOLLOWING = this.translateLabel('DELETE_CONFIRM_FOLLOWING').translation;
    const DELETE_CONFIRM_APP_NAME = this.translateLabel('DELETE_CONFIRM_APP_NAME').translation;
    const DELETE_CONFIRM_KEY = this.translateLabel('DELETE_CONFIRM_KEY').translation;
    const DELETE_CONFIRM_VALUE = this.translateLabel('DELETE_CONFIRM_VALUE').translation;
    const messageDelete: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`
    ${DELETE_CONFIRM_LABEL} </br>${DELETE_CONFIRM_APP_NAME}:${this.selectedApplication}</br>
     ${DELETE_CONFIRM_FOLLOWING} </br>${DELETE_CONFIRM_KEY}:${selectedRow.key} & ${DELETE_CONFIRM_VALUE}:${selectedRow.value}`);
    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 1,
        message: messageDelete,
        options: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isModalLoader = true;
      if (result) {
        let deleteData = {
          "appName": this.selectedApplication,
          "data": [selectedRow],
          "bot_type": this.sessionStorage.get('bot_type')
        }
        this.contentMgmtService.performDevConfigurationAction('delete', deleteData).subscribe((response: any) => {
          this.isModalLoader = false;
          this.translated.get('successfullMessages.DELEETE_SUCCESS').subscribe((translatedText: string) => {
            this.toaster.success(translatedText);
          });
          this.getAllPhrases();
        }, (error: any) => {
          this.isModalLoader = false;

        });
      }
    });
  };
  shouldDisableKey(index: number): boolean {
    return this.keyDisabled || (index !== 0);
  }

  getPlaceholderKey(index: number): string {
    return this.isNewRow && index === 0 ? 'Enter Key' : '';
  }

  getPlaceholderValue(index: number): string {
    return this.isNewRow && index === 0 ? 'Enter Value' : '';
  }
  getPlaceholderDescription(index: number): string {
    return this.isNewRow && index === 0 ? 'Enter Description' : '';
  }

  setDefaultIfEmpty(row: any, field: string): void {
    if (!row[field]) {
      row[field] = 'N/A';
    }
  }

  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.sessionStorage.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }
}
