<div *ngIf="data.type === 1">
  <h1 mat-dialog-title>{{'devConfig.CONTENT' | translate}}</h1>
  <div [innerHTML]="data.message" class="text-align-justify"></div>
</div>
<div *ngIf="data.type === 2">
  <h1 mat-dialog-title [innerHTML]="data.message"></h1>
  <div class="text-align-justify">
    <div>
      <input type="file" #fileInput accept="image/*" style="display: none" (change)="onFileSelected(fileInput)">
      <button mat-raised-button class="mui-button" (click)="fileInput.click()">
        <mat-icon>cloud_upload</mat-icon>
        <span>{{'sideNav.UPLOAD_PROFILE_PIC' | translate}}</span>
      </button>
    </div>
    <div *ngIf="selectedFile">
      <p>{{'rating.SELECTED_FILE' | translate}}: {{ selectedFile.name }}</p>
    </div>
  </div>
</div>
<div *ngIf="data.type === 3">
  <h1 mat-dialog-title>{{'devConfig.LANGUAGE_CHANGE' | translate}}</h1>
  <div [innerHTML]="data.message" class="text-align-justify"></div>
</div>
<div mat-dialog-content class="padding20" *ngIf="data.type === 0">
  <h1 mat-dialog-title>{{'devConfig.SELECT_LANGUAGE' | translate}}</h1>
  <mat-form-field>
    <mat-select [(value)]="selectedLanguage">
      <mat-option *ngFor="let option of data.options" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div mat-dialog-actions class="full-right">
  <button mat-button class="mui-button-clear" (click)="onCancelClick()">{{'devConfig.CANCEL' | translate}}</button>
  <button mat-button class="mui-button" [disabled]="data.disable" (click)="onConfirmClick()" cdkFocusInitial> 
    {{'devConfig.CONFIRM' | translate}}
  </button> 
</div>