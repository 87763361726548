import { Component } from '@angular/core';

@Component({
  selector: 'app-user-disprove',
  templateUrl: './user-disprove.component.html',
  styleUrls: ['./user-disprove.component.scss']
})
export class UserDisproveComponent {

}
