import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatbotService } from '../services/chat.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationService } from '../services/translation-service';
import { LocalStorageService } from '../services/localStorageService';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.dev';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss']
})
export class RatingModalComponent implements OnInit {
  @Input() showModal: any = false;
  @Output() closeModalEvent = new EventEmitter<boolean>();
  rating3: number;
  showFirstModal: boolean = true;
  selectedOption: string = '';
  chatBotResponse: any;
  isOthersField: boolean = false;
  userTypedComments = '';
  feedbackRating = [1];
  userSelectedComments = '';
  currentRating: number = 0;
  stars: number[] = [];
  maxRating: number = 5;
  initialRating: number = 1
  translate: any;
  isNotCorrectField: boolean = false;
  validObjects: any = [];
  nonValidObjects: any = [];
  sensitiveObjects: any = [];
  flagEnabled: boolean = false;
  conversationalServiceURL = environment.apiBaseUrl['chatbotURL'];
  constructor(private chatResponseFromService: ChatbotService, private toastr: ToastrService, translateService: TranslationService, private sessionStorage: LocalStorageService, private router: Router, public dialog: MatDialog, private translated: TranslateService) {
    this.rating3 = 0;
    this.chatBotResponse = this.chatResponseFromService.botRespponse;
    this.stars = Array(this.maxRating).fill(0).map((x, i) => i + 1);
    this.translate = translateService;
  }

  ngOnInit(): void {
    this.currentRating = this.initialRating;
    this.validObjects = this.chatResponseFromService.ratingFeedbackComments;
  }

  enter(value: number) {
    this.currentRating = value;
  }

  reset() {
    this.currentRating = this.initialRating;
  }

  setRating(value: number) {
    this.currentRating = value;
    this.showFirstModal = false;
  }

  goBack() {
    this.isNotCorrectField = false;
    this.isOthersField = false;
    this.showFirstModal = false;
    this.userSelectedComments = ''
    this.userTypedComments = ''
    this.flagEnabled = false
  }

  onSelectionChange() {
    const selectedOption = this.validObjects.find((option: { value: string; }) => option.value === this.userSelectedComments);

    if (selectedOption) {
      this.selectedOption = selectedOption;

      // Set isOthersField and isNotCorrectField based on the selected option
      // this.isOthersField = this.userSelectedComments === 'Others';
      // this.isNotCorrectField = this.userSelectedComments === 'Not correct';

      // Set flagEnabled based on the additionalComments property
      this.flagEnabled = selectedOption.additionalComments;
    } else {
      this.isOthersField = false;
      this.isNotCorrectField = false;
      this.flagEnabled = false;
    }
  }

  closeModal(): void {
    this.showModal = false;
    this.showFirstModal = false;
    this.closeModalEvent.emit(true);
  }

  onTextareaInput(event: any) {
    this.userTypedComments = event.target.value;
  }
  saveFeedback() {
    this.showModal = false;
    let comment = '';
    if (this.userSelectedComments === "Others") {
      comment = ''
    } else {
      comment = this.userSelectedComments;
    }
    let filteredFeedbackArray = this.chatResponseFromService?.feedbackResponseFromAPI.filter(item =>
      item.feedback_comment_english.toLocaleLowerCase() === this.userSelectedComments.toLocaleLowerCase() ||
      item.feedback_comment_french.toLocaleLowerCase() === this.userSelectedComments.toLocaleLowerCase()
    );
    let saveConPayload = {
      conversationID: this.chatResponseFromService.initialConversationID,
      rating: this.currentRating || '',
      feedback_comment: comment,
      additional_comment: this.userTypedComments || '',
      feedback_type: this.chatResponseFromService?.botCurrentResponse?.responseType === 'not-answered' ? 'not_answered' : (filteredFeedbackArray[0]?.feedback_type === 'normal' ? 'cache' : filteredFeedbackArray[0]?.feedback_type),
      feedback_options_id: filteredFeedbackArray[0]?._id,
      bot_type: this.sessionStorage.get('bot_type'),
      feedback_flag: filteredFeedbackArray[0]?.feedback_flag,
      language_type: this.sessionStorage.get('language'),
      response_type: this.chatResponseFromService?.botCurrentResponse?.responseType === 'not-answered' ? 'not_answered' : (filteredFeedbackArray[0]?.response_type === 'normal' ? 'cache' : filteredFeedbackArray[0]?.response_type)
    }
    this.chatResponseFromService.saveConversationFeedbac(saveConPayload).subscribe((res: any) => {
      this.translated.get('successfullMessages.THUMBS_DOWN_FEEDBACK_SUCCESS').subscribe((translatedText: string) => {
        this.toastr.success(translatedText);
      });
    }, (error: any) => {
      if (error?.status === 519) {
        this.chatResponseFromService.inputDisabled = true;
        const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
          width: 'auto',
          disableClose: true,
          data: {
            isConversationTimout: true,
            conversationTimoutMessage: error?.error?.error
          },
        });

        dialogRef.afterClosed().subscribe((result: any) => {
        });
      }
      if (error?.status === 500) {
        this.chatResponseFromService.feedbackIconDisabled = false
        this.translated.get('errorMessages.FEEDBACK_RATING_ERROR').subscribe((translatedText: string) => {
          this.toastr.error(translatedText);
        });
      }
    });

    // let payload = {
    //   "conversationID": this.chatResponseFromService?.initialConversationID,
    //   "comment": comment,
    //   "rating": this.currentRating || '',
    //   "additionalComment": this.userTypedComments || '',
    //   "bot_type": this.sessionStorage.get('bot_type')
    // }
    // this.chatResponseFromService.saveUserFeedback(payload)
    //   .subscribe(
    //     (feedbackRes) => {
    //       if (feedbackRes)
    //         this.toastr.success(feedbackRes?.message);
    //     },
    //     (error) => {
    //       // this.toastr.error(error?.error?.error);
    //       if (error.status === 401) {
    //         this.toastr.info('Login Expired');
    //         this.router.navigate(['/login']);
    //       }
    //     }
    //   );
  }
}
