import { Component, OnInit } from '@angular/core';
import { ContentManagementService } from '../services/content-management.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../services/localStorageService';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponentComponent } from "../confirmation-dialog-component/confirmation-dialog-component.component";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslationService } from '../services/translation-service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DateFormatService } from '../pipe/date-time-localize';
import { TranslateService } from '@ngx-translate/core';
import { ChatbotService } from '../services/chat.service';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
import { StateService } from "../services/shared-object.service";
@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.scss']
})
export class ContentManagementComponent implements OnInit {
  numbersArray: number[] = Array.from({ length: 5 }, (_, index) => index + 1);
  changedRows: any[] = [];
  editMode: boolean[] = [];
  // boostScores: string[] = [];
  data: any[] = [];
  totalTablePage = 0;
  pageNumber = 1;
  pageSize = 20;
  editItem: any;
  selectedRecord = '';
  searchQuery = '';
  isLoader = false;
  translate: any;
  botLoderType = 'boxLoader'
  isMobileScreen: boolean = false;
  selectedOption: string = 'file';
  validatorSelectedOption: string = 'csv';
  selectedFile: File | null = null;
  validationSelectedFile: File | null = null;
  fileInputRef: any;

  languageType: string = 'English';
  validatorlanguageType: string = 'English';
  boostScore: number = 4;
  contentName: string = '';
  fileType: any | '' = '';
  fileTypes = [
    { label: 'PDF', value: 'pdf' },
    { label: 'XLSX', value: 'xlsx' },
    { label: 'Word Document', value: 'docx' },
  ];
  fileUploaded: boolean = false;
  languageTypes: string[] = ['English', 'French'];
  boostScores: number[] = [1, 2, 3, 4, 5];
  htmlLink: string = '';
  websiteUrl: string = '';
  excludedValues: string[] = [];
  selectedExcludedValue: string[] = [];
  newExcludedValue: string = '';
  isDisclosure: any = false;
  imageRequired: any = false;
  isShowFileName: boolean = false;
  isNewContent: boolean = false;
  disableSave: boolean = false;
  isModalLoader: boolean = false;
  isExpandCollapse: boolean = true;
  constructor(
    private contentMgmtService: ContentManagementService,
    private toaster: ToastrService,
    private sessionStorage: LocalStorageService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private router: Router,
    translateService: TranslationService,
    private breakpointObserver: BreakpointObserver,
    public dateFormatService: DateFormatService,
    private translated: TranslateService,
    public chatbotService: ChatbotService,
    private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService,
    private stateService: StateService) {
    this.translate = translateService;
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobileScreen = result.matches;
      });
  }

  ngOnInit(): void {
    this.chatbotService.getAppBotConfiguration();
    this.pageNumber = 1;
    this.getAllFiles(this.pageNumber);
    // this.contentMgmtService.saveContentClicked.subscribe(() => {
    //   this.pageNumber = 1;
    //   this.getAllFiles(this.pageNumber);
    // });
    this.stateService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
  }

  handleBoostScoreChange(event: any, index: number): void {
    this.boostScores[index] = event.target.value;
  }

  handleDelete(row: any, index: number): void {
    this.selectedRecord = row.content_name;
    this.openConfirmationDialogDelete(row)
  }

  openConfirmationDialogDelete(selectedRow: any) {
    const DELETE_CONFIRM_LABEL = this.translateLabel('DELETE_CONFIRM_LABEL').translation;
    const message: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`
    ${DELETE_CONFIRM_LABEL} ${this.selectedRecord}.
  `);

    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 1, message: message,
        options: []
      } // Pass the sanitized message
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isModalLoader = true;
        const deletePayload = {
          "username": selectedRow.username,
          "date": selectedRow.date,
          "filename": selectedRow.filename,
          "extn": '.' + selectedRow.filename?.split('.')[1],
          "content_name": selectedRow.content_name,
          "indexer_name": selectedRow.indexer_name,
          "bot_type": this.sessionStorage.get('bot_type')
        }
        this.contentMgmtService.performContentMgmtAction('delete', deletePayload).subscribe(
          response => {
            this.isModalLoader = false;
            this.translated.get('successfullMessages.DELETED_SUCCESS').subscribe((translatedText: string) => {
              this.toaster.success(translatedText);
            });
            this.pageNumber = 1;
            this.getAllFiles(this.pageNumber);
          },
          error => {
            this.isModalLoader = false;
            this.translated.get('errorMessages.DELETED_ERROR').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
          })
      }
    });
  }
  fileDownload(row: any): void {
    if (row.doc_type === '.docx') {
      this.contentMgmtService.downloadFile(row.storage_website_url);
    } else {
      window.open(row.storage_website_url, '_blank');
    }
  }

  handleEditClick(row: any, index: number): void {
    if (this.data[index]?.status === "Completed") {
      this.editMode[index] = true;
      this.changedRows.push(row);
    }
  }
  cancelEditMode(index: number) {
    this.editMode[index] = !this.editMode[index];
    this.pageNumber = 1;
    this.getAllFiles(this.pageNumber);
  }

  handleSaveChanges(selectedRow: any, index: number): void {
    this.editMode[index] = false;

    const boostScore = {
      username: this.sessionStorage.get('username'),
      content_name: selectedRow.content_name,
      boost_score: selectedRow.boost_score,
      bot_type: this.sessionStorage.get('bot_type')
    };

    this.contentMgmtService.performContentMgmtAction('update', boostScore).pipe()
      .subscribe(
        response => {
          this.translated.get('successfullMessages.CONTENT_MGMT_BOOSTSCORE_SUCCESS').subscribe((translatedText: string) => {
            this.toaster.success(translatedText);
          });
          this.pageNumber = 1;
          this.getAllFiles(this.pageNumber);
        },
        error => {
          this.translated.get('errorMessages.CONTENT_MGMT_BOOSTSCORE_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
        }
      );
  }

  getAllFiles(pageNumber: number): void {
    this.isLoader = true;
    this.contentMgmtService.getAllUploadedFiles(pageNumber, this.pageSize).subscribe(
      (response) => {
        this.isLoader = false;
        let tableData = response.pop();
        this.totalTablePage = tableData.total_no_of_pages;
        this.data = response;
        this.editMode = new Array(this.data.length).fill(false);
        this.boostScores = this.data.map(row => row.boost_score);
      },
      (error) => {
        this.isLoader = false;
        if (error.status === 401) {
          this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
          this.router.navigate(["/login"]);
          sessionStorage.clear();
        } else if (error.status === 500) {
          this.translated.get('errorMessages.FAILD_LOAD_DATA').subscribe((translatedText: string) => {
            this.toaster.error(translatedText);
          });
        }
      }
    );
  }

  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.data = [];
    this.getAllFiles(pageNumber);
  }

  searchTableData() {
    if (this.searchQuery !== '') {
      this.isLoader = true;
      this.data = [];
      const searchPayload = { "content_name": this.searchQuery, "bot_type": this.sessionStorage.get('bot_type') }
      this.contentMgmtService.performContentMgmtAction('search', searchPayload).subscribe(
        (response: any) => {
          this.isLoader = false;
          this.data = response.matching_document;
        },
        (error: any) => {
          this.isLoader = false;
          this.data = [];
        }
      );
    } else {
      this.translated.get('errorMessages.ENTER_SEARCH_TEXT').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }
  clearSearch() {
    this.searchQuery = '';
    this.pageNumber = 1;
    this.getAllFiles(this.pageNumber);
  }
  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.sessionStorage.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }
  handleRadioChange() {
    this.fileUploaded = false;
    this.selectedFile = null;
    this.fileType = '';
    this.contentName = '';
    this.websiteUrl = '';
    this.htmlLink = '';
    this.selectedExcludedValue = [];
    this.disableSave = false;
  }

  handleDrop(e: any) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    this.selectedFile = file;
    if (file) {
      const extension = file.name.split('.').pop()?.toLowerCase();
      this.fileType = this.getFileTypeFromExtension(extension);
      this.fileUploaded = true;
    } else {
      this.fileType = null;
    }
  }

  preventDefault(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }

  getFileTypeFromExtension(extension: string): string | null {
    switch (extension) {
      case 'csv':
        return 'xlsx';
      case 'xlsx':
        return 'xlsx'
      case 'pdf':
        return 'pdf';
      case 'docx':
        return 'docx';
      default:
        return null;
    }
  }
  updateExcludedValues() {

  }

  addExcludedValue() {
    if (this.newExcludedValue.trim() !== '') {
      this.excludedValues.push(this.newExcludedValue.trim());
      this.selectedExcludedValue.push(this.newExcludedValue.trim());
      this.newExcludedValue = '';
    }
  }

  addContent() {
    if (this.selectedOption === 'file') {
      if (this.selectedFile === null) {
        this.translated.get('errorMessages.CONTENT_MGMT_FILE_EMPTY').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      }
      else if (this.languageType === '') {
        this.translated.get('errorMessages.CONTENT_MGMT_LANGUAGE_EMPTY').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      } else if (this.contentName === '') {
        this.translated.get('errorMessages.CONTENT_MGMT_CONTENTNAME_EMPTY').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      } else {
        this.openConfirmationDialog();
      }
    } else if (this.selectedOption === 'html') {
      if (this.htmlLink === "") {
        this.translated.get('errorMessages.CONTENT_MGMT_HTMLLINK_EMPTY').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      } else if (this.contentName === '') {
        this.translated.get('errorMessages.CONTENT_MGMT_CONTENTNAME_EMPTY').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      } else {
        this.openConfirmationDialog()
      }
    } else if (this.selectedOption === 'website') {
      if (this.websiteUrl === "") {
        this.translated.get('errorMessages.CONTENT_MGMT_WEBSITE_EMPTY').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      } else if (this.contentName === '') {
        this.translated.get('errorMessages.CONTENT_MGMT_CONTENTNAME_EMPTY').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      } else {
        this.openConfirmationDialog();
      }
    }
  }
  openConfirmationDialog() {
    const ADD_CONTENT_CONFIRM_LABEL1 = this.translateLabel('ADD_CONTENT_CONFIRM_LABEL1').translation;
    const ADD_CONTENT_CONFIRM_LABEL2 = this.translateLabel('ADD_CONTENT_CONFIRM_LABEL2').translation;
    const message: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`
   ${ADD_CONTENT_CONFIRM_LABEL1} ${this.selectedOption} ${ADD_CONTENT_CONFIRM_LABEL2}.
  `);

    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 1, message: message,
        options: []
      } // Pass the sanitized message
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const formData = new FormData();
        if (this.selectedFile) {
          formData.append('file', this.selectedFile);
        }
        formData.append('username', this.sessionStorage.get('username'));
        formData.append('disclosure_based', this.isDisclosure);
        formData.append('boost_score', Number(this.boostScore).toString());
        formData.append('content_name', this.contentName);
        formData.append('excluded_url_list', JSON.stringify(this.selectedExcludedValue));
        formData.append('img_required', this.imageRequired)
        let websiteUrl = '';
        let language = '';
        let fileType = ''
        if (this.selectedOption === 'website' && this.websiteUrl) {
          websiteUrl = this.websiteUrl;
        } else if (this.selectedOption === 'html' && this.htmlLink) {
          websiteUrl = this.htmlLink;
        } else {
          websiteUrl = '';
        }
        if (this.languageType === 'English') {
          language = 'en';
        } else if (this.languageType === 'French') {
          language = 'fr';
        }

        if (this.selectedOption === 'file' && this.selectedFile) {
          fileType = '.' + this.fileType;
        } else if (this.selectedOption === 'website' && this.websiteUrl) {
          fileType = 'website';
        } else if (this.selectedOption === 'html' && this.htmlLink) {
          fileType = 'html';
        }
        formData.append('website_url', websiteUrl);
        formData.append('language_type', language);
        formData.append('file_type', fileType);
        formData.append('bot_type', this.sessionStorage.get('bot_type'));
        this.disableSave = true;
        this.isLoader = true;
        this.isModalLoader = true;
        this.contentMgmtService.fileUploadContentMgmt(formData).subscribe((response: any) => {
          this.translated.get('successfullMessages.CONTENT_MGMT_FILE_UPLOAD_SUCCESS').subscribe((translatedText: string) => {
            this.toaster.success(translatedText);
          });
          this.selectedFile = null;
          this.boostScore = 4;
          this.fileType = '';
          this.websiteUrl = '';
          this.contentName = '';
          this.excludedValues = [];
          this.htmlLink = '';
          this.isNewContent = false;
          // this.contentMgmtService.saveContentClicked.emit();
          this.pageNumber = 1;
          this.isLoader = false;
          this.isModalLoader = false;
          this.getAllFiles(this.pageNumber);
        }, (error: any) => {
          this.isModalLoader = false;

          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          if (error.status === 409) {
            this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
              this.toaster.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          } else if (error.status === 400) {
            this.disableSave = false;
            if (error?.error?.error === 'Uploaded document is not in English (en) language. Please upload a document in English.') {
              this.translated.get('errorMessages.REQUIRED_CONTENT_UPLOAD_EN').subscribe((translatedText: string) => {
                this.toaster.error(translatedText);
              });
            } else if (error?.error?.error === 'Uploaded document is not in French (fr) language. Please upload a document in French.') {
              this.translated.get('errorMessages.REQUIRED_CONTENT_UPLOAD_FR').subscribe((translatedText: string) => {
                this.toaster.error(translatedText);
              });
            } else if (error?.error === 'Content Name Already Exists') {
              this.translated.get('errorMessages.CONTENT_NAME_EXIST').subscribe((translatedText: string) => {
                this.toaster.error(translatedText);
              });
            }
          }
        })
      }
    });
  }
  formatSize(size: number | undefined): string {
    if (size === undefined) return '';
    const KB = 1024;
    const MB = KB * KB;
    if (size < KB) return size + ' B';
    else if (size < MB) return (size / KB).toFixed(2) + ' KB';
    else return (size / MB).toFixed(2) + ' MB';
  }
  handleDeleteFile() {
    this.selectedFile = null;
    this.fileType = '';
  }

  triggerFileInput() {
    this.fileInputRef?.click();
  }

  handleFileChange(event: any) {
    const file = event.target.files[0];
    this.selectedFile = file;
    if (file) {
      const extension = file.name.split('.').pop()?.toLowerCase();
      this.fileType = this.getFileTypeFromExtension(extension);
      this.fileUploaded = true;
    } else {
      this.fileType = null;
    }
    if (file.name.split('.').pop()?.toLowerCase() === 'odt') {
      this.translated.get('errorMessages.CONTENT_MGMT_FILE_UPLOAD_ERROR').subscribe((translatedText: string) => {
        this.toaster.error(translatedText);
      });
    }
  }
  showFileName() {
    this.isShowFileName = !this.isShowFileName;
  }
  replaceSpaces(event: string) {
    this.contentName = event.toLowerCase().replace(/\s+/g, '_'); // Replace spaces with underscores
  }
  newConent() {
    this.isNewContent = true;
    this.disableSave = false;
  }
  cancelContent() {
    this.isNewContent = false;
    this.pageNumber = 1;
    this.getAllFiles(this.pageNumber);
  }
}
