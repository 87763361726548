import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ChatbotService } from '../services/chat.service';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { LocalStorageService } from '../services/localStorageService';
import { Router } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class ProfileImageService {
    removedProfile: boolean = false;
    constructor(
        private dialog: MatDialog,
        private toastr: ToastrService,
        private translate: TranslateService,
        private chatbotService: ChatbotService,
        private sessionStorage: LocalStorageService,
        private router: Router
    ) { }

    removeProfileImage(userDetails: any) {
        const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
            width: 'auto',
            disableClose: true,
            data: { isRemoveProfile: true }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'OK') {
                const removeProfile = new FormData();
                if (userDetails === '') {
                    removeProfile.append('username', this.sessionStorage.get('username'));
                } else if (userDetails?.username !== '') {
                    removeProfile.append('username', userDetails?.username);
                }
                removeProfile.append('bot_type', this.sessionStorage.get('bot_type'));
                this.chatbotService.removeProfilePicture(removeProfile).catch((error: any) => {
                    if (error.status === 400) {
                        this.translate.get('errorMessages.NO_PROFILE_IMG').subscribe(translatedText => {
                            this.toastr.error(translatedText);
                        });
                    } else if (error.status === 409) {
                        this.translate.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe(translatedText => {
                            this.toastr.error(translatedText);
                        });
                        this.router.navigate(['/login']);
                        sessionStorage.clear();
                    } else if (error.status === 401) {
                        this.translate.get('errorMessages.UNAUTHORIZED_ERROR').subscribe(translatedText => {
                            this.toastr.error(translatedText);
                        });
                        this.router.navigate(['/login']);
                        sessionStorage.clear();
                    }
                }).then((response: any) => {
                    if (response) {
                        this.translate.get('successfullMessages.REMOVED_PROFILE_IMG').subscribe(translatedText => {
                            this.toastr.success(translatedText);
                        });
                        this.sessionStorage.remove('profile_picture');
                        this.sessionStorage.set('profile_picture', '');
                        this.chatbotService.profilePictureUrl = 'assets/noImage.png';
                        this.removedProfile = true;
                        // Update any other relevant properties or services
                    }
                });
            }
        });
    }
}
