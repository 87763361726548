import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AppChatbotComponent } from './chatbot/chatbot.component';
import { AuthGuard } from 'protedtedRoutes';
import { ChathistoryComponent } from './chathistory/chathistory.component';
import { ConversationHistoryComponent } from './conversation-history/conversation-history.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordThankyouComponent } from './forgot-password-thankyou/forgot-password-thankyou.component';
import { PasswordResetPageComponent } from './password-reset-page/password-reset-page.component';
import { SynonymsComponent } from './synonyms/synonyms.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { DevelopersConfigurationComponent } from './developers-configuration/developers-configuration.component';
import { RatingTypeOptionsListComponent } from './feedback/feedback.component';
import { CacheComponent } from './cache/cache.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ValidationComponent } from './validation/validation.component';
import { UserCommentsComponent } from './user-comments/user-comments.component';
import { ReportsComponent } from './reports/reports.component';
import { UserDisproveComponent } from './user-disprove/user-disprove.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'chatbot', component: AppChatbotComponent, canActivate: [AuthGuard] },
  { path: 'chathistory', component: ChathistoryComponent, canActivate: [AuthGuard] },
  { path: 'conversationhistory', component: ConversationHistoryComponent, canActivate: [AuthGuard] },
  { path: 'contentmanagement', component: ContentManagementComponent, canActivate: [AuthGuard] },
  { path: 'synonyms', component: SynonymsComponent, canActivate: [AuthGuard] },
  { path: 'thankyou', component: ForgotPasswordThankyouComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: 'passwordreset', component: PasswordResetPageComponent },
  { path: 'usermanagement', component: UsermanagementComponent, canActivate: [AuthGuard] },
  { path: 'apiconfiguration', component: DevelopersConfigurationComponent, canActivate: [AuthGuard] },
  { path: 'feedbackcomment', component: RatingTypeOptionsListComponent, canActivate: [AuthGuard] },
  { path: 'cache', component: CacheComponent, canActivate: [AuthGuard] },
  { path: 'configuration', component: ConfigurationComponent, canActivate: [AuthGuard] },
  { path: 'validation', component: ValidationComponent, canActivate: [AuthGuard] },
  { path: 'user-comments', component: UserCommentsComponent, canActivate:[AuthGuard]},
  { path: 'reports', component: ReportsComponent, canActivate:[AuthGuard]},
  // {path:'user-disprove',component:UserDisproveComponent,canActivate:[AuthGuard]},
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
