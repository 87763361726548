import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ChatbotService } from '../services/chat.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup;
  buttonDisable: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private chatbotService: ChatbotService,
    private router: Router,
    private toastr: ToastrService,

  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, this.emailDomainValidator()]]
    });
  }

  async onSubmit() {
    if (this.forgotPasswordForm.valid) {
      this.buttonDisable = true;
      const email = this.forgotPasswordForm.value.email;
      try {
        const response = await this.chatbotService.forgotPassword(email);
        this.buttonDisable = false;
        if (response)
          this.toastr.success(`Reset link sent successfull to ${this.forgotPasswordForm.value.email}`)
        this.router.navigate(['/thankyou']);
      } catch (error: any) {
        if (error?.status === 200) {
          this.toastr.success(`Reset link sent successfull to ${this.forgotPasswordForm.value.email}`)
          this.router.navigate(['/thankyou']);
          this.buttonDisable = false;
        }
      }
    }
  }

  emailDomainValidator(): ValidatorFn {
    const emailPattern = /^(?=.{5,60}$)(?!.*\.\.)(?!.*@.*@)[a-zA-Z0-9._%-]+@(cibc\.com|cibc\.co|kumaran\.com|kumaran\.world)$/i;
    return (control: AbstractControl): ValidationErrors | null => {
      return emailPattern.test(control.value) ? null : { invalidEmail: true };
    };
  }
  
}
