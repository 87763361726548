<div class="custom-dialog">
    <div fxLayoutAlign="space-between center" class="padding-10">
        <div class='req-res-style'>
            <img src='assets/augcontentheadimage.svg' height="50" alt='Augment Content' />
            <strong>{{data.userInput}}</strong>
        </div>
        <button mat-dialog-close mat-icon-button><mat-icon>close</mat-icon></button>
    </div>
    <mat-dialog-content>
        <div *ngIf="showRateComment">
            <div><strong>{{'chatBot.RATING_TEXT' | translate}}:</strong>{{ this.augmentContentFromDrawer[0]?.feedback ?
                this.augmentContentFromDrawer[0].feedback : 'N/A' }}</div>
            <div><strong>{{'chatBot.CMT_TEXT' | translate}}:</strong>{{ this.augmentContentFromDrawer[0]?.feedbackComment
                ?
                this.augmentContentFromDrawer[0]?.feedbackComment : 'N/A' }}</div>
            <div><strong>{{'chatBot.ADDITINAL_CMT_TEXT' | translate}}:</strong>{{
                this.augmentContentFromDrawer[0]?.additionalComment ?
                this.augmentContentFromDrawer[0]?.additionalComment : 'N/A' }}</div>
            <br>
        </div>
        <div>
            <mat-accordion>
                <!-- Loop through each item in the content array -->
                <mat-expansion-panel *ngFor="let item of agumentResponse; let i = index" [expanded]="false">
                    <!-- Panel header -->
                    <mat-expansion-panel-header>
                        <!-- Display the Section Title as the header -->
                        <mat-panel-title class="ellipsis-aug">
                            <strong>{{i+1}}.{{ item['context_' + (i + 1)] }}</strong>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <!-- Panel body -->
                    <p>
                        <!-- Display the Document ID, Section Title, Page Number, and URL -->
                        <span class="copy-agument-style">
                            <strong>{{'chatBot.COPY_AUG_CONTENT_TEXT' | translate}}:</strong>
                            <mat-icon class="cursor" aria-hidden="false" aria-label="Augment Content Copy"
                                fontIcon="file_copy" (click)="copyToClipboard(item['context_' + (i + 1)])"></mat-icon>
                        </span>
                        <br>
                        <strong>{{'chatBot.DOC_ID_TEXT' | translate}}:</strong> {{ item.doc_id }}<br>
                        <strong>{{'chatBot.SEC_TITLE_TEXT' | translate}}:</strong> {{ item.sec_title }}<br>
                        <strong>{{'chatBot.PAGE_NO' | translate}}:</strong> {{ item.pageno || 'N/A' }}<br>
                        <ng-container *ngIf="isDocxFile(item.url)" class="popup-url-style">
                            <!-- If it's a .docx file, display a message or use a preview component -->
                            <span class="popup-url-style">
                                <strong>{{'chatBot.PREVIEW_TEXT'| translate}}: </strong> <a href="{{ item.url }}"
                                    target="_blank" (click)="fileDownloadDocx($event, item.url)">{{ item.url
                                    }}</a>
                            </span>
                        </ng-container>
                        <!-- If it's a .pdf file, display a message or use a preview component -->

                        <ng-container *ngIf="isPdfFile(item.url)" class="popup-url-style">
                            <span class="popup-url-style">
                                <strong>{{'chatBot.PREVIEW_TEXT' | translate}}: </strong>
                                <!-- Adjust this link to handle PDF downloads -->
                                <a href="{{ item.url }}" target="_blank" (click)="fileDownloadDocx($event, item.url)">{{
                                    item.url }}</a>
                            </span>
                        </ng-container>
                        <!-- If it's not a .docx file, display the URL as a link -->
                        <ng-container *ngIf="!isDocxFile(item.url) && !isPdfFile(item.url)">
                            <span class="popup-url-style">
                                <strong>{{'chatBot.URL_TEXT' | translate}}: </strong> <a href="{{ item.url }}"
                                    target="_blank">{{ item.url
                                    }}</a>
                            </span>
                        </ng-container>
                    </p>
                    <!-- Display additional content if available -->
                </mat-expansion-panel>
            </mat-accordion>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions class='icon-end-position'>
        <!-- <button class="mui-button-clear" mat-dialog-close>Cancel</button> -->
        <button class="mui-button " mat-dialog-close cdkFocusInitial>{{'chatBot.OK' | translate}}</button>
    </mat-dialog-actions>
</div>