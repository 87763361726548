<div>
  <div>
    <h3>{{ 'chatBot.SIM_QUESTION_TEXT' | translate }}</h3>
  </div>
  <div class="center-alignment drawer-container">
    <div *ngIf="qaResponses.length > 0; else emptySimilarTemplate">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let qa of qaResponses">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ qa.question.charAt(0).toUpperCase() + qa.question.slice(1) }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <p [innerHTML]="qa.response"></p>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <ng-template #emptySimilarTemplate>{{ 'chatBot.NO_SIM_QUESTION_TEXT' | translate }}</ng-template>
  </div>
  <div class="bottom-fixed" *ngIf="isChatbotUrl() && isDesktop">
    <button mat-button class="mat-button-style" (click)="newConversation()"> <mat-icon>add_circle_outline</mat-icon>
      {{'sideNav.NEW_CONVERSATION' | translate}}</button>
  </div>
</div>