import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateServiceSpecifiedLabelsService {
  translations: { [key: string]: { [key: string]: string } } = {
    en: {
      RATING_TAG_TEXT: 'Does that answer your question?',
      STEPWISE_TEXT: 'Stepwise',  // Corrected from STEPSWISE_TEXT to STEPWISE_TEXT
      TOCACHE_TEXT: 'To cache',
      DISCLOSURE: 'Disclosure',
      LANGUAGE_CONFIRMATION: 'To observe the Language Change take effect, please log out.',
      SENSITIVE_CONFIRMATION_TITLE: 'Confirm Action',
      SENSITIVE_CONFIRMATION: 'Do you really want to "approve" or "reject" this question?',
      ADDITIONAL_MESSAGE_LABEL: 'Please note that this chatbot is an AI-based assistant designed to provide information and answer questions related to CIBC Investor Bank products and services. It is not intended to offer professional financial or investment advice.',
      CHITCHAT_CONFIRMATION: "Are you certain you wish to mark the following question as chit chat and approve / reject?",
      SENSITIVE_RES_TEXT: "I apologize, I am not allowed to answer this question",
      NO_CONTENT_RES_TEXT: "Kindly rephrase the question and check",
      OUT_OF_SCOPE_RES_TEXT: "Apologize, I don't see any relevant content in my knowledge base to answer this question",
      LANGUAGE_MISMATCH_FR: "Veuillez poser la question en français uniquement",
      LANGUAGE_MISMATCH_EN: "Kindly ask the question in English language only",
      PII_RES_TEXT: "The question cannot be processed because it contains PII(Personally identifiable information).",
      PROFILE_PICTURE_UPLOAD: "Upload Profile Picture",
      CHATBOT_UNDEFIND_TEXT: "We regret to inform you that we were unable to provide an answer to your query at this time. Please consider rephrasing your query or contacting support for further assistance. Please start a new conversation",
      START_NEW_CONVERSATION_MESSAGE: "Your conversation session has been expired. Please start a new one",
      DELETE_CONFIRM_LABEL: "Are you sure to want to delete following record?",
      DELETE_CONFIRM_FILE_NAME: "File Name",
      DELETE_CONFIRM_FOLLOWING: "with following",
      DELETE_CONFIRM_RUNID: "RUNID",
      DELETE_CONFIRM_AND: "&",
      DELETE_CONFIRM_FILE_TYPE: "File Type",
      ADD_CONTENT_CONFIRM_LABEL1: " Are you sure to add ",
      ADD_CONTENT_CONFIRM_LABEL2: "content for Chatbot to consider for generating response for the questions it receives? This might have significant impact on the response generated by Chatbot for the related questions. The default boosting score will be 4 (if not given), which determines the ranking of the content to consider while generating response for the question. Note that website content take longer time to parse and index and webserver might block our IP if the traffic is not expected from our IP continously",
      DELETE_CONFIRM_APP_NAME: "Application Name",
      DELETE_CONFIRM_KEY: "Key",
      DELETE_CONFIRM_VALUE: "Value",
      DELETE_TARGET_FDBK: "Target Feedback",
      DELETE_TARGET_FDCOMMENT: "Feedback Comment",
      ANS_FROM_CACHE : "Answer generated from cache",
      TOUR_WELCOME: "Welcome to the Guide Tour Chatbot",
      TOUR_NEW_CONVERSATION: "About New Conversation",
      TOUR_LANGUAGE_INPUT: "Language Input for Chatbot",
      TOUR_ASK_QUESTION: "Chatbot Input For Ask Question",
      TOUR_SEND_OPTION: "Send Option",
      TOUR_SIMILAR_QUESTION: "Similar Question",
      TOUR_WELCOME_CONTENT: "To check for available features in chatbot, click here.",
      TOUR_NEW_CONVERSATION_CONTENT: "To initiate a new conversation, click here.",
      TOUR_LANGUAGE_INPUT_CONTENT: "Change the language for the current conversation with this bot.",
      TOUR_ASK_QUESTION_CONTENT: "Type your question in the chatbot's text box to start the conversation.",
      TOUR_SEND_OPTION_CONTENT: "A send icon to submit your message in the chat.",
      TOUR_SIMILAR_QUESTION_CONTENT: "You can view a list of similar questions found in the database."
    },
    fr: {
      RATING_TAG_TEXT: 'Est-ce que cela répond à votre question ?',
      STEPWISE_TEXT: 'Étape par étape',  // Corrected from STEPSWISE_TEXT to STEPWISE_TEXT
      TOCACHE_TEXT: 'Vers le cache',
      DISCLOSURE: 'Divulgation',
      LANGUAGE_CONFIRMATION: 'Pour observer que le changement de langue prend effet, veuillez vous déconnecter.',
      SENSITIVE_CONFIRMATION_TITLE: "Confirmer l'action",
      SENSITIVE_CONFIRMATION: 'Voulez-vous vraiment « approuver » ou « rejeter » cette question?',
      ADDITIONAL_MESSAGE_LABEL: "Veuillez noter que ce chatbot est un assistant basé sur l'IA conçu pour fournir des informations et répondre aux questions liées aux produits et services de la Banque CIBC Investisseurs. Il n’est pas destiné à offrir des conseils financiers ou d’investissement professionnels.",
      CHITCHAT_CONFIRMATION: "Êtes-vous certain de vouloir marquer la question suivante comme bavardage et approuver/rejeter ?",
      SENSITIVE_RES_TEXT: "Je m'excuse, je ne suis pas autorisé à répondre à cette question",
      NO_CONTENT_RES_TEXT: "Veuillez reformuler la question et vérifier",
      OUT_OF_SCOPE_RES_TEXT: "Veuillez m'excuser, je ne vois aucun contenu pertinent dans ma base de connaissances pour répondre à cette question.",
      LANGUAGE_MISMATCH_FR: "Veuillez poser la question en français uniquement",
      LANGUAGE_MISMATCH_EN: "Kindly ask the question in English language only",
      PII_RES_TEXT: "La question ne peut pas être traitée car elle contient des PII (informations personnellement identifiables).",
      PROFILE_PICTURE_UPLOAD: "Télécharger une photo de profil",
      CHATBOT_UNDEFIND_TEXT: "Nous avons le regret de vous informer que nous ne sommes pas en mesure de répondre à votre question pour le moment. Veuillez envisager de reformuler votre requête ou de contacter l'assistance pour obtenir de l'aide. Veuillez démarrer une nouvelle conversation",
      START_NEW_CONVERSATION_MESSAGE: "Votre session de conversation a expiré. S'il vous plaît, commencez-en un nouveau",
      DELETE_CONFIRM_LABEL: "Etes-vous sûr de vouloir supprimer l'enregistrement suivant ?",
      DELETE_CONFIRM_FILE_NAME: "Nom de fichier",
      DELETE_CONFIRM_FOLLOWING: "avec la suite",
      DELETE_CONFIRM_RUNID: "RUNID",
      DELETE_CONFIRM_AND: "&",
      DELETE_CONFIRM_FILE_TYPE: "Type de fichier",
       ADD_CONTENT_CONFIRM_LABEL1: "Etes-vous sûr d'ajouter ",
      ADD_CONTENT_CONFIRM_LABEL2: "Quel contenu le Chatbot doit-il prendre en compte pour générer des réponses aux questions qu'il reçoit ? Cela pourrait avoir un impact significatif sur la réponse générée par Chatbot pour les questions associées. Le score de boosting par défaut sera de 4 (s'il n'est pas indiqué), ce qui détermine le classement du contenu à prendre en compte lors de la génération de réponses à la question. Notez que le contenu du site Web prend plus de temps à être analysé et indexé et que le serveur Web peut bloquer notre IP si le trafic n'est pas attendu de notre IP en continu.",
      DELETE_CONFIRM_APP_NAME: "Nom de l'application",
      DELETE_CONFIRM_KEY: "Clé",
      DELETE_CONFIRM_VALUE: "Valeur",
      DELETE_TARGET_FDBK: "Cibler les commentaires",
      DELETE_TARGET_FDCOMMENT: "Commentaire",
      ANS_FROM_CACHE: "Réponse générée à partir du cache",
      TOUR_WELCOME: "Bienvenue dans le guide du chatbot",
      TOUR_NEW_CONVERSATION: "À propos de la nouvelle conversation",
      TOUR_LANGUAGE_INPUT: "Entrée de langue pour le chatbot",
      TOUR_ASK_QUESTION: "Saisie de question pour le chatbot",
      TOUR_SEND_OPTION: "Option d'envoi",
      TOUR_SIMILAR_QUESTION: "Question similaire",
      TOUR_WELCOME_CONTENT: "Pour vérifier les fonctionnalités disponibles dans le chatbot, cliquez ici.",
      TOUR_NEW_CONVERSATION_CONTENT: "Pour démarrer une nouvelle conversation, cliquez ici.",
      TOUR_LANGUAGE_INPUT_CONTENT: "Changez la langue pour la conversation en cours avec ce bot.",
      TOUR_ASK_QUESTION_CONTENT: "Tapez votre question dans la boîte de texte du chatbot pour commencer la conversation.",
      TOUR_SEND_OPTION_CONTENT: "Une icône pour envoyer votre message dans le chat.",
      TOUR_SIMILAR_QUESTION_CONTENT: "Vous pouvez voir une liste de questions similaires trouvées dans la base de données."
    }
  }

  constructor() { }

  translateLabel(label: string, lang: string): string {
    if (!this.translations[lang] || !this.translations[lang][label]) {
      lang = 'en';
    }
    return this.translations[lang][label] || label;
  }
}
