<div class="page-containter" [style.paddingLeft]="isDesktop ? 20 : 0" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
    <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">
        <mat-tab label="{{ 'sensitiveQuestion.TO_APPROVE' | translate }}">
            <mat-tab-group #toApproveSubTabGroup (selectedTabChange)="subTabChanged($event)">
                <!-- All Sub-Tab -->
                <mat-tab label="{{ 'userComments.APPROVED_SUB_ALL' | translate }}">
                    <div class="table-content-fit-page">
                        <ng-container *ngTemplateOutlet="feedbackControlsTemplate"></ng-container>
                        <div *ngIf="!isLoader; else loading">
                            <div *ngIf="!toApproveData?.data?.length; else showTable" fxLayoutAlign="center center">
                                <span *ngIf="!noRecordFound; else searchText">
                                    <p>{{'userComments.SEARCH_HELP_TEXT'| translate}}</p>
                                </span>
                                <ng-template #searchText>
                                    {{'sideNav.NO_DATA' | translate}}
                                </ng-template>
                            </div>
                            <ng-template #showTable>
                                <ng-container
                                    *ngTemplateOutlet="tableTemplate; context: { $implicit: toApproveData }"></ng-container>
                            </ng-template>
                        </div>
                        <ng-template #loading>
                            <div fxLayout="row" fxLayoutAlign="center center" class="padding-top50px">
                                <app-loader [botLoderType]="botLoderType"></app-loader>
                            </div>
                        </ng-template>
                    </div>
                </mat-tab>
                <!-- Cache Recommendation Sub-Tab -->
                <mat-tab label="{{ 'userComments.PPROVED_CACHE_RECOMMENDATION' | translate }}">
                    <div class="table-content-fit-page">
                        <ng-container *ngTemplateOutlet="feedbackControlsTemplate"></ng-container>
                        <div *ngIf="!isLoader; else loading">
                            <div *ngIf="!toApproveData?.data?.length; else showTableCache"
                                fxLayoutAlign="center center">
                                <span *ngIf="!noRecordFound; else searchText">
                                    <p>{{ 'userComments.SEARCH_HELP_TEXT' | translate }}</p>
                                </span>
                                <ng-template #searchText>
                                    {{ 'sideNav.NO_DATA' | translate }}
                                </ng-template>
                            </div>
                            <ng-template #showTableCache>
                                <ng-container
                                    *ngTemplateOutlet="tableTemplate; context: { $implicit: toApproveData }"></ng-container>
                            </ng-template>
                        </div>
                        <ng-template #loading>
                            <div fxLayout="row" fxLayoutAlign="center center" class="padding-top50px">
                                <app-loader [botLoderType]="botLoderType"></app-loader>
                            </div>
                        </ng-template>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
        <mat-tab label="{{ 'sensitiveQuestion.APPROVED' | translate }}">
            <mat-tab-group #approvedSubTabGroup (selectedTabChange)="subTabChanged($event)">
                <!-- All Sub-Tab -->
                <mat-tab label="{{ 'userComments.APPROVED_SUB_ALL' | translate }}">
                    <div class="table-content-fit-page">
                        <ng-container *ngTemplateOutlet="feedbackControlsTemplate"></ng-container>
                        <div *ngIf="!isLoader; else loading">
                            <div *ngIf="!approvedData?.data?.length; else showTable" fxLayoutAlign="center center">
                                <span *ngIf="!noRecordFound; else searchText">
                                    <p>{{'userComments.SEARCH_HELP_TEXT'| translate}}</p>
                                </span>
                                <ng-template #searchText>
                                    {{'sideNav.NO_DATA' | translate}}
                                </ng-template>
                            </div>
                            <ng-template #showTable>
                                <ng-container
                                    *ngTemplateOutlet="tableTemplate; context: { $implicit: approvedData }"></ng-container>
                            </ng-template>
                        </div>
                        <ng-template #loading>
                            <div fxLayout="row" fxLayoutAlign="center center" class="padding-top50px">
                                <app-loader [botLoderType]="botLoderType"></app-loader>
                            </div>
                        </ng-template>
                    </div>
                </mat-tab>
                <!-- Cache Recommendation Sub-Tab -->
                <mat-tab label="{{ 'userComments.PPROVED_CACHE_RECOMMENDATION' | translate }}">
                    <div class="table-content-fit-page">
                        <ng-container *ngTemplateOutlet="feedbackControlsTemplate"></ng-container>
                        <div *ngIf="!isLoader; else loading">
                            <div *ngIf="!approvedData?.data?.length; else showTableCache" fxLayoutAlign="center center">
                                <span *ngIf="!noRecordFound; else searchText">
                                    <p>{{ 'userComments.SEARCH_HELP_TEXT' | translate }}</p>
                                </span>
                                <ng-template #searchText>
                                    {{ 'sideNav.NO_DATA' | translate }}
                                </ng-template>
                            </div>
                            <ng-template #showTableCache>
                                <ng-container
                                    *ngTemplateOutlet="tableTemplate; context: { $implicit: approvedData }"></ng-container>
                            </ng-template>
                        </div>
                        <ng-template #loading>
                            <div fxLayout="row" fxLayoutAlign="center center" class="padding-top50px">
                                <app-loader [botLoderType]="botLoderType"></app-loader>
                            </div>
                        </ng-template>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
        <mat-tab label="{{ 'sensitiveQuestion.REJECTED' | translate }}">
            <mat-tab-group #rejectedSubTabGroup (selectedTabChange)="subTabChanged($event)">
                <!-- All Sub-Tab -->
                <mat-tab label="{{ 'userComments.APPROVED_SUB_ALL' | translate }}">
                    <div class="table-content-fit-page">
                        <ng-container *ngTemplateOutlet="feedbackControlsTemplate"></ng-container>
                        <div *ngIf="!isLoader; else loading">
                            <div *ngIf="!rejectedData?.data?.length; else showTable" fxLayoutAlign="center center">
                                <span *ngIf="!noRecordFound; else searchText">
                                    <p>{{'userComments.SEARCH_HELP_TEXT'| translate}}</p>
                                </span>
                                <ng-template #searchText>
                                    {{'sideNav.NO_DATA' | translate}}
                                </ng-template>
                            </div>
                            <ng-template #showTable>
                                <ng-container
                                    *ngTemplateOutlet="tableTemplate; context: { $implicit: rejectedData }"></ng-container>
                            </ng-template>
                        </div>
                        <ng-template #loading>
                            <div fxLayout="row" fxLayoutAlign="center center" class="padding-top50px">
                                <app-loader [botLoderType]="botLoderType"></app-loader>
                            </div>
                        </ng-template>
                    </div>
                </mat-tab>
                <!-- Cache Recommendation Sub-Tab -->
                <mat-tab label="{{ 'userComments.PPROVED_CACHE_RECOMMENDATION' | translate }}">
                    <div class="table-content-fit-page">
                        <ng-container *ngTemplateOutlet="feedbackControlsTemplate"></ng-container>
                        <div *ngIf="!isLoader; else loading">
                            <div *ngIf="!rejectedData?.data?.length; else showTableCache" fxLayoutAlign="center center">
                                <span *ngIf="!noRecordFound; else searchText">
                                    <p>{{ 'userComments.SEARCH_HELP_TEXT' | translate }}</p>
                                </span>
                                <ng-template #searchText>
                                    {{ 'sideNav.NO_DATA' | translate }}
                                </ng-template>
                            </div>
                            <ng-template #showTableCache>
                                <ng-container
                                    *ngTemplateOutlet="tableTemplate; context: { $implicit: rejectedData }"></ng-container>
                            </ng-template>
                        </div>
                        <ng-template #loading>
                            <div fxLayout="row" fxLayoutAlign="center center" class="padding-top50px">
                                <app-loader [botLoderType]="botLoderType"></app-loader>
                            </div>
                        </ng-template>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #feedbackControlsTemplate>
    <div class="padding-top10" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20">
        <mat-form-field appearance="fill">
            <mat-label> {{ 'feedback.LANGUAGE_TYPE' | translate }}</mat-label>
            <mat-select [(ngModel)]="languageTypeSelected" required (ngModelChange)="changeLanguageOption($event)">
                <mat-option *ngFor="let language of getFilteredLanguageTypeOptions()" [value]="language.value">
                    {{ language.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" *ngIf="!isCacheRecommendation()">
            <mat-label> {{ 'feedback.TARGET_TYPE' | translate }}</mat-label>
            <mat-select [(ngModel)]="feedbackTypes" (ngModelChange)="changeFeedbackOption($event)"
                (selectionChange)="onFeedbackTypeChange()" required>
                <ng-container *ngFor="let rating of feedbackTypeOptions">
                    <mat-option *ngIf="rating !== 'Cache Recommendation'" [value]="rating">{{ rating }}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="10px">
            <button mat-button class="mui-button"
                [disabled]="!feedbackTypes && currentSubTab !== 'Cache Recommendation'"
                (click)="searchFeedback(this.pageNumber, this.pageSize)">{{ 'sideNav.SEARCH' | translate }}</button>
            <button mat-button *ngIf="isApprovedTabSelected() && !isCacheRecommendation() " (click)="addUserComments()"
                class="mui-button">{{
                'sideNav.ADD' | translate }}</button>
        </div>
    </div>
</ng-template>

<!-- Table Template -->
<ng-template #tableTemplate let-data>
    <div class="table-container full-width">
        <table mat-table [dataSource]="data" class="alternate-rows full-width">
            <ng-container matColumnDef="question">
                <th mat-header-cell *matHeaderCellDef class="head-bold"
                    [ngClass]="currentSubTab === 'Cache Recommendation' ? 'width400': 'width200'">
                    {{'disclosure.EVAL_VIEW_QUESTION'| translate}}</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element?.question" class="text-eclipse cursor"
                    [ngClass]="currentSubTab === 'Cache Recommendation' ? 'width400': 'width200'">{{ element?.question
                    }}
                </td>
            </ng-container>

            <ng-container matColumnDef="response">
                <th mat-header-cell *matHeaderCellDef class="head-bold"
                    [ngClass]="currentSubTab === 'Cache Recommendation' ? 'width400': 'width300'">
                    <div>{{'disclosure.EVAL_VIEW_RES' | translate}}</div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="text-eclipse" [ngClass]="{'cursor': element.response !== 'N/A', 'width400': currentSubTab === 'Cache Recommendation',
  'width300': currentSubTab !== 'Cache Recommendation'}">
                        {{element.response === '' ?
                        'N/A' : replaceDivsWithParagraphs(element.response)}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef
                    class="width100 head-bold">
                    {{'sensitiveQuestion.GIVEN_BY' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"
                    class="width100 text-eclipse"
                    [matTooltip]="element?.username">{{
                    element?.username }}</td>
            </ng-container>

            <ng-container matColumnDef="additional_comment">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'chatBot.ADDITINAL_CMT_TEXT' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.additional_comment=== null
                    ||
                    element?.additional_comment ==="")?
                    'N/A':
                    element?.additional_comment }}</td>
            </ng-container>

            <ng-container matColumnDef="approved_rejected_by">
                <th mat-header-cell *matHeaderCellDef class="head-bold"
                    [ngClass]="currentSubTab === 'Cache Recommendation' ? 'width100': 'width100'">
                    <div>
                        {{ currentTabLabel === 'Approved' || currentTabLabel === 'Pending' ? 'Approved By':
                        (currentTabLabel === 'Rejected' ? "Rejected By":'')}}
                    </div>
                </th>
                <td mat-cell *matCellDef="let element"
                    [ngClass]="currentSubTab === 'Cache Recommendation' ? 'width100': 'width100'" class="text-eclipse"
                    [matTooltip]="element?.approved_rejected_by">{{ element?.approved_rejected_by?
                    element?.approved_rejected_by: "N/A" }}</td>
            </ng-container>

            <ng-container matColumnDef="disclosure">
                <th mat-header-cell *matHeaderCellDef> {{ 'disclosure.DISCLOSURE' | translate
                    }} </th>
                <td mat-cell *matCellDef="let element">
                    <a href="#" (click)="onDisclosureClick(element, $event)" class="image-key">{{
                        'disclosure.NAVIGATION' | translate
                        }} </a>
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="images" *ngIf="feedbackTypes === 'Cache Recommendation'">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{ 'chatBot.IMAGE_TEXT' | translate}}
                </th>
                <td mat-cell *matCellDef="let row">
                    <div *ngIf="getImageKeys(row).length > 0; else noImages">
                        <div *ngFor="let key of getImageKeys(row)">
                            <div (click)="previewImage($event, row.images, key)" class="image-key">
                                {{ key }}
                            </div>
                        </div>
                    </div>

                    <ng-template #noImages>
                        <div>No images</div>
                    </ng-template>
                </td>
            </ng-container> -->
            <ng-container matColumnDef="feedback_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'feedback.TARGET_TYPE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.feedback_type=== null ||
                    element?.feedback_type ==="")?
                    'N/A':
                    ( formatFeedbackTypeDsiaplay(element?.feedback_type) | titlecase)}}</td>
            </ng-container>
            <ng-container matColumnDef="response_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'feedback.RESPONSE_TYPE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.response_type=== null ||
                    element?.response_type ==="")?
                    'N/A':
                    (formatFeedbackTypeDsiaplay(element?.response_type) | titlecase) }}</td>
            </ng-container>

            <ng-container matColumnDef="approveRejectComments">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'feedback.ACTION_CMD' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.approveRejectComments=== null ||
                    element?.approveRejectComments=== "" ||
                    element?.response_type ==="")?
                    'N/A':
                    (element?.approveRejectComments | titlecase) }}</td>
            </ng-container>

            <!-- <ng-container matColumnDef="language_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'feedback.LANGUAGE_TYPE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{ (element?.language_type=== null ||
                    element?.language_type ==="")?
                    'N/A':
                    element?.language_type === 'en'? "English" : "French" }}</td>
            </ng-container> -->

            <ng-container matColumnDef="feedback_comment">
                <th mat-header-cell *matHeaderCellDef class="head-bold">
                    {{'feedback.FEEDBACK_VALUE'|
                    translate}}</th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element?.feedback_comment">{{
                    element?.feedback_comment === ''? 'N/A':
                    (element.feedback_comment | titlecase) }}</td>
            </ng-container>
            <!--<ng-container matColumnDef="feedback_flag">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{ 'feedback.FEEDBACK_FLAG' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.feedback_type === 'sensitive' ? (element?.feedback_flag === 0 ? 'False' : 'True') :
                    'N/A' }}
                </td>
            </ng-container> -->
            <ng-container matColumnDef="given_timestamp">
                <th mat-header-cell *matHeaderCellDef class="width200 head-bold">{{'userComments.GIVEN_TIME'|
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="width200">{{
                    dateFormat.convertUtcToLocalSlash(element?.given_timestamp) }}</td>
            </ng-container>
            <ng-container matColumnDef="action_type">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{'userComments.ACTION_TYPE'| translate}}
                </th>
                <td mat-cell *matCellDef="let element">{{element?.action_type }}</td>
            </ng-container>
            <ng-container matColumnDef="model_update_status">
                <th mat-header-cell *matHeaderCellDef class="head-bold">{{ currentTabLabel === 'Approved' ?
                    'Action Taken' : currentTabLabel === 'Approved' ? 'Model Update Status': '' }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container
                        *ngIf="(feedbackTypes === 'All' || feedbackTypes === 'Sensitive' || feedbackTypes ==='Cache Recommendation') && currentTabLabel === 'Approved'">
                        {{ element.model_update_status ? 'Model Updated' : 'Model Not updated' }}
                    </ng-container>
                    <ng-container
                        *ngIf="(feedbackTypes === 'No Content' || feedbackTypes === 'Normal' || feedbackTypes ==='Not Answered' || feedbackTypes === 'Cache') && currentTabLabel === 'Approved'">
                        {{ element.model_update_status ? 'Yet to be Taken' : 'Yet to be Taken' }}
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="action" >
                <th mat-header-cell *matHeaderCellDef class="head-bold width150">{{'devConfig.ACTION'| translate}}</th>
                <td mat-cell *matCellDef="let element; let index;" class="width150">
                    <button mat-icon-button *ngIf="currentTabLabel === 'Pending'" matTooltip="{{'feedback.ACTION_TOOLTIP' | translate}}" class="cursor"
                        (click)="openSensitiveConfirmationDialog(element, index, '');$event.stopPropagation();">
                        <img alt="Approve/Reject" height="15" width="15" src="assets/approve_reject_confirm_icon.png" />
                    </button>
                    <button mat-icon-button *ngIf="currentSubTab !== 'Cache Recommendation' && currentSubTab !== 'Recommandations de cache'" [disabled]="element?.conversation_id === ''" matTooltip="{{'feedback.CHAT_VIEW' | translate}}" class="cursor"
                        (click)="openChatConversation(element, index);$event.stopPropagation();">
                        <img alt="View Chat Conversation" [class.disable-img]="element?.conversation_id === ''" height="15" width="15" src="assets/chitchat.png" />
                    </button>
                </td>
            </ng-container>

            <!-- Header and Row Declarations -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;let i=index" class="cursor"
                [ngStyle]="{ 'background-color': (i % 2 === 0) ? '#f5f5f5' : 'white' }" (click)="onRowClick(row)"></tr>
        </table>
        <app-conversation-drawer [selectedConversation]="selectedConversation"
            (drawerClosed)="handleDrawerClosed()"></app-conversation-drawer>
    </div>
    <div class="pagination">
        <button (click)="onPageChange(1)" [disabled]="pageNumber === 1">
            <mat-icon aria-hidden="false" aria-label="First Page" fontIcon="first_page"></mat-icon>
        </button>
        <button (click)="onPageChange(pageNumber - 1)" [disabled]="pageNumber === 1">
            <mat-icon aria-hidden="false" aria-label="Previous Page" fontIcon="navigate_before"></mat-icon>
        </button>
        <span>{{ pageNumber }} / {{ totalTablePage }}</span>
        <button (click)="onPageChange(pageNumber + 1)" [disabled]="pageNumber === totalTablePage"
            [disabled]="pageNumber === totalTablePage">
            <mat-icon aria-hidden="false" aria-label="Next Page" fontIcon="navigate_next"></mat-icon>
        </button>
        <button (click)="onPageChange(totalTablePage)" [disabled]="pageNumber === totalTablePage"
            [disabled]="pageNumber === totalTablePage">
            <mat-icon aria-hidden="false" aria-label="Last Page" fontIcon="last_page"></mat-icon>
        </button>
        <span class="export-excel-style" (click)="exportToExcel()">
            <mat-icon aria-hidden="false" aria-label="Download" fontIcon="file_download"></mat-icon>
        </span>
    </div>

</ng-template>