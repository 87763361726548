import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ContentManagementService } from '../services/content-management.service';
import { LocalStorageService } from '../services/localStorageService';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { StateService } from '../services/shared-object.service';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  performanceChecked = false;
  feedbackChecked = false;
  usageChecked = false;
  tokenUtilizedChecked = false;
  selectedOption: string[] = [];
  loading: boolean = false;
  isLoader = false;
  botLoderType = 'boxLoader';
  isDesktop = false;
  showPerformanceOptions = false;
  selectedDateFilter: string | null = null;
  selectedYear: number[] = [];
  selectedYearMonth: number[] = [];
  selectedWeek: string[] = [];
  startDate: Date | null = null;
  endDate: Date | null = null;
  // selectedResponseType: string[] = [];
  // selectedRating: number[] = [];
  // selectedResponseTime: string[] = [];
  selectedGroupBy: string | null = null;
  yearOptions = [];
  monthYearOptions = [];
  weekOptions = [];
  ratingOptions = [];
  responseTypeOptions = [];
  responseTimeOptions = [];
  groupByOptions = ['Date Filter', 'User ID', 'Response Type', 'Rating', 'Response Time'];
  today: Date = new Date();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl('');
  filteredUsers!: Observable<string[]>;
  users: string[] = [];
  allUsers: string[] = [];

  aiModelCtrl = new FormControl('');
  filteredModels!: Observable<string[]>;
  selectedAIModels: string[] = [];
  allAIModels: string[] = [];

  langCtrl = new FormControl('');
  filteredLang!: Observable<string[]>;
  selectedLang: string[] = [];
  allLang: string[] = [];

  respTypeCtrl = new FormControl('');
  filteredRespType!: Observable<string[]>;
  selectedResponseType: string[] = [];
  allRespType: string[] = [];

  respTimeCtrl = new FormControl('');
  filteredRespTime!: Observable<string[]>;
  selectedResponseTime: string[] = [];
  allRespTime: string[] = [];
  responseTimeForApi: string[] = [];

  ratingCtrl = new FormControl('');
  filteredRating!: Observable<string[]>;
  selectedRating: string[] = [];
  allRating: string[] = [];
  isExpandCollapse: boolean = true;
  isModalLoader: boolean = false;
  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;
  @ViewChild('modelInput') modelInput!: ElementRef<HTMLInputElement>;
  @ViewChild('langInput') langInput!: ElementRef<HTMLInputElement>;
  @ViewChild('respTypeInput') respTypeInput!: ElementRef<HTMLInputElement>;
  @ViewChild('ratingInput') ratingInput!: ElementRef<HTMLInputElement>;
  @ViewChild('respTimeInput') respTimeInput!: ElementRef<HTMLInputElement>;

  groupByValue: string | null = null;
  
  constructor(public contentService: ContentManagementService, private cdRef: ChangeDetectorRef, private localStroage: LocalStorageService,
    private toastr: ToastrService,private translated: TranslateService,  private router: Router, private stateService: StateService) {
  }

  onFocus() {
    this.cdRef.detectChanges(); // Trigger change detection when input gains focus
  }

  onBlur() {
    this.cdRef.detectChanges(); // Trigger change detection when input loses focus
  }
  ngOnInit(): void {
    this.isLoader = true
    this.contentService.getReportDetails().subscribe({
      next: (response) => {
        this.yearOptions = response.report_year;
        this.monthYearOptions = response.report_month;
        this.weekOptions = response.report_week;
        // this.ratingOptions = response.report_rating;
        this.allRating = response.report_rating;
        this.filteredRating = this.ratingCtrl.valueChanges.pipe(
          startWith(null),
          map((rat:string|null) => (rat ? this._filter(rat,this.allRating): this.allRating.slice()))
        );
        // this.responseTypeOptions = response.report_response_type;
        this.allRespType = response.report_response_type;
        this.filteredRespType = this.respTypeCtrl.valueChanges.pipe(
          startWith(null),
          map((resp:string|null) => (resp ? this._filter(resp,this.allRespType): this.allRespType.slice()))
        );
        this.allRespTime = response.report_response_time.map(
          (time: number[]) => time[1] === null ? `(> ${time[0]})` : `(${time[0]}, ${time[1]})`
        );
        this.filteredRespTime = this.respTimeCtrl.valueChanges.pipe(
          startWith(null),
          map((time: string | null) => (time ? this._filter(time, this.allRespTime) : this.allRespTime.slice()))
        );
        this.allAIModels = response.report_gpt_model;
        this.filteredModels = this.aiModelCtrl.valueChanges.pipe(
          startWith(null),
          map((model: string | null) => (model ? this._filter(model, this.allAIModels) : this.allAIModels.slice()))
        );
        this.allLang = response.language_types.map((lang: any) => {
          switch (lang) {
            case 'en':
              return 'English';
            case 'fr':
              return 'French';
            default:
              return lang; // Return the same value if no match is found
          }
        });
        this.filteredLang = this.langCtrl.valueChanges.pipe(
          startWith(null),
          map((lang: string | null) => (lang ? this._filter(lang, this.allLang) : this.allLang.slice()))
        );
        this.isLoader = false;
      },
      error: (err) => {
        this.isLoader = false;
        if (err.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }else{
        console.error('API call failed:', err);
        }
      },
    });
    this.stateService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
  }

  // Method to check if all required fields are filled
  isFormValid(): boolean {
    // Check if any required fields are empty
    return (
      (this.selectedAIModels.length > 0 || this.selectedLang.length > 0) &&
      this.selectedDateFilter !== null &&
      (this.selectedDateFilter !== 'year' || this.selectedYear !== null) &&
      (this.selectedDateFilter !== 'yearMonth' || this.selectedYearMonth.length > 0) &&
      (this.selectedDateFilter !== 'week' || this.selectedWeek !== null) &&
      (this.selectedDateFilter !== 'dateRange' || (this.startDate !== null && this.endDate !== null)) &&
      this.users.length > 0 &&
      this.selectedResponseType !== null &&
      (this.selectedRating !== null || !this.performanceChecked) &&
      (this.selectedResponseTime !== null || !this.feedbackChecked)
    );
  }


  add(event: MatChipInputEvent, list: string[], ctrl: FormControl): void {
    const value = (event.value || '').trim();

    if (value && !list.includes(value)) {
      list.push(value);
    }

    event.chipInput!.clear();
    ctrl.setValue(null);
  }

  remove(item: string, list: string[]): void {
    const index = list.indexOf(item);

    if (index >= 0) {
      list.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent, list: string[], ctrl: FormControl): void {
    if (!list.includes(event.option.viewValue)) {
      list.push(event.option.viewValue);
    }

    ctrl.setValue(null);
    this.filteredUsers = of([])
  }

  private _filter(value: string, list: string[]): string[] {
    const filterValue = value.toLowerCase();
    return list.filter(item => item.toLowerCase().includes(filterValue));
  }

  searchUser(value: string | null) {
    if (value && value.trim()) {
      this.loading = true;
      this.contentService.getUsernames(value).subscribe({
        next: (response) => {
          this.allUsers = response.username;
          this.filteredUsers = this.userCtrl.valueChanges.pipe(
            startWith(null),
            map((user: string | null) => (user ? this._filter(user, this.allUsers) : this.allUsers.slice()))
          );
          this.userInput.nativeElement.value = '';
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.error('API call failed:', err);
        },
      });
    } else {
      // console.log('No valid input for search');
    }
  }

  // Function to clear the input field
  clearInput() {
    this.userCtrl.setValue('');
    this.userInput.nativeElement.value = '';
    this.allUsers = []
    this.filteredUsers = of([]);

    this.cdRef.detectChanges();
  }

  onEnterKeyPress(value: string) {
    if (value && value.length > 0) {
      this.searchUser(value);
    }
  }


  onCheckboxChange(option: string, isChecked: boolean) {
    this.clearFilters()
    if (isChecked) {
      // Add option to the array if it's not already present
      if (!this.selectedOption.includes(option)) {
        this.selectedOption.push(option);
      }
    } else {
      // Remove option from the array if it's unchecked
      const index = this.selectedOption.indexOf(option);
      if (index > -1) {
        this.selectedOption.splice(index, 1);  // Remove the unchecked option
      }
    }

    if (option === 'performance') {
      this.performanceChecked = isChecked;
    } else if (option === 'feedback') {
      this.feedbackChecked = isChecked;
    } else if (option === 'usage') {
      this.usageChecked = isChecked;
    } else if (option === 'tokenutilized') {
      this.tokenUtilizedChecked = isChecked;
    }
  }
  onDateFilterChange(selected: string) {
    this.selectedDateFilter = selected;
  }

  clearFilters() {
    this.selectedAIModels = []
    this.selectedLang = []
    this.selectedDateFilter = null;
    this.selectedYear = [];
    this.selectedYearMonth = [];
    this.selectedWeek = [];
    this.startDate = null;
    this.endDate = null;
    this.users = []
    this.selectedResponseType = [];
    this.selectedRating = [];
    this.selectedResponseTime = [];
    this.selectedGroupBy = null;
    this.showPerformanceOptions = false;
  }


  generateReport() {
    this.groupByValue = null;
    this.groupByValue = this.selectedGroupBy === 'date_range' ? this.selectedDateFilter : this.selectedGroupBy;

    const bot = this.localStroage.get('bot_type')
    const languageMap: { [key: string]: string } = {
      'English': 'en',
      'French': 'fr',
    };

    const languageCodes = this.selectedLang.map(lang => languageMap[lang] || lang); 

    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);  // Months are 0-indexed
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    };
  
    const formattedStartDate = this.startDate ? formatDate(new Date(this.startDate)) : null;
    const formattedEndDate = this.endDate ? formatDate(new Date(this.endDate)) : null;

    this.responseTimeForApi = this.selectedResponseTime.map((time: string) => {
      if (time.includes('>')) {
        return time.replace('>', '').trim(); // Remove '>' and trim spaces
      }
      return time;
    });
    
    const payload = {
      "group_by": this.groupByValue,
      "report": this.selectedOption,
      "bot_type": bot,
      "username": this.users,
      "response_type": this.selectedResponseType,
      "year": this.selectedYear,
      "week": this.selectedWeek,
      "month": this.selectedYearMonth,
      "start_date": formattedStartDate,
      "end_date": formattedEndDate,
      "rating": this.selectedRating,
      "response_time": this.responseTimeForApi,
      "language_type": languageCodes,
      "model_name": this.selectedAIModels,
      "platform_language": this.localStroage.get('language')
    };
    this.isModalLoader = true;
    this.contentService.generateReport(payload).subscribe({
      next: (response: Blob) => {
        this.isModalLoader = false;
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const currentDate = formatDate(new Date());
        const a = document.createElement('a');
        a.href = url;
        a.download = `report_${currentDate}.xlsx`;  // You can customize the file name here
        a.click();
        window.URL.revokeObjectURL(url);  // Clean up the URL after download
        this.translated.get('successfullMessages.REPORT_DOWNLOADED').subscribe((translatedText: string) => {
          this.toastr.success(translatedText);
        });
        this.clearFilters()
      },
      error: (err) => {
        this.isModalLoader = false;
        if (err.status === 409) {
          this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
          this.router.navigate(['/login']);
          sessionStorage.clear();
        }else if(err.status === 404){
          this.translated.get('errorMessages.REPORT_NOT_DOWNLOADED').subscribe((translatedText: string) => {
            this.toastr.error(translatedText);
          });
        }else{
        console.error('API call failed:', err);
        }
      }
    });
  }
}
