<div class="page-containter" [ngClass]="{ 'collapsed': isExpandCollapse, 'expanded': !isExpandCollapse }">
  <mat-stepper orientation="vertical" #stepper class="table-content-fit-page" *ngIf="!loading && apiData">
    <ng-container *ngFor="let item of apiData.data">
      <mat-step [stepControl]="createForm(item)">
        <ng-template matStepLabel>{{ item?.label }}</ng-template>
        <div class="step-content">
          <form [formGroup]="secondFormGroup">
            <ng-container *ngIf="item.input_type === 'textbox'">
              <p [innerHTML]="item?.description"></p>
              <mat-form-field appearance="outline">
                <mat-label>{{item?.label}}</mat-label>
                <input matInput [formControlName]="item?.key" placeholder="{{ item?.label }}" required>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="item?.input_type === 'number'">
              <p [innerHTML]="item?.description"></p>
              <mat-form-field appearance="outline">
                <mat-label>{{item?.label}}</mat-label>
                <input matInput type="number" [formControlName]="item?.key" placeholder="{{ item?.label }}" min="1"
                  max="10" required>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="item?.input_type === 'dropdown'">
              <p [innerHTML]="item?.description"></p>
              <mat-form-field appearance="outline">
                <mat-label>{{ item?.label }}</mat-label>
                <mat-select [formControlName]="item?.key">
                  <mat-option *ngFor="let option of item?.input_options" [value]="option">{{ option }}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-container *ngIf="item?.input_type === 'radio'">
              <p [innerHTML]="item?.description"></p>
              <mat-radio-group [formControlName]="item?.key">
                <mat-radio-button *ngFor="let option of item?.input_options" [value]="option">{{ option
                  }}</mat-radio-button>
              </mat-radio-group>
            </ng-container>
            <ng-container *ngIf="item?.input_type === 'toggle'">
              <p [innerHTML]="item?.description"></p>
              <mat-slide-toggle [formControlName]="item.key">{{ item?.label }}</mat-slide-toggle>
            </ng-container>
            <!-- Add other input types as needed -->
            <div class="step-button">
              <button mat-button class="mui-button-clear" matStepperPrevious
                *ngIf="stepper.selectedIndex > 0">Back</button>
              <button mat-button class="mui-button-clear" matStepperNext>Next</button>
            </div>
          </form>
        </div>
      </mat-step>
    </ng-container>
    <mat-step>
      <ng-template matStepLabel>{{'devConfig.SUMMARY' | translate}}</ng-template>
      <h4>{{'devConfig.VERIFY_ALERT' | translate}}</h4>
      <div>
        <ng-container *ngFor="let item of apiData.data; let i = index">
          <ul>
            <li>{{ item.label }}: {{ secondFormGroup.get(item.key)?.value }}</li>
          </ul>
        </ng-container>
        <div class="step-button">
          <button mat-button class="mui-button-clear" matStepperPrevious>{{'devConfig.BACK' | translate}}</button>
          <button mat-button class="mui-button" [disabled]="!isFormDirty || isAnyFieldEmpty"
            (click)="save()">{{'rating.SAVE' | translate}}</button>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <app-loader [ngClass]="{'loader': loading, 'modal-backdrop': isModalLoader}" *ngIf="loading || isModalLoader"
    [botLoderType]="botLoderType"></app-loader>
</div>