import { Component, Inject, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ChatbotService } from '../services/chat.service';
import { StateService } from '../services/shared-object.service';
import { TranslationService } from '../services/translation-service';
import { LocalStorageService } from '../services/localStorageService';
@Component({
  selector: 'app-confirmation-dialog-component',
  templateUrl: './confirmation-dialog-component.component.html',
  styleUrls: ['./confirmation-dialog-component.component.scss']
})
export class ConfirmationDialogComponentComponent implements OnInit {
  selectedLanguage: any | undefined;
  flag: boolean | undefined;
  storeFlag!: [];
  translate: any;
  selectedFile: File | undefined;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponentComponent>, public chatService: ChatbotService,
    @Inject(MAT_DIALOG_DATA) public data: { type: number, message: SafeHtml, options: string[], disable: boolean }, // Update data object
    private sanitizer: DomSanitizer, public sharedService: StateService, translateService: TranslationService, private sessionDetails: LocalStorageService
  ) {
    this.translate = translateService;
    if (data?.options && this.sessionDetails.get('language') === 'en')
      this.selectedLanguage = data?.options[0];
    else {
      this.selectedLanguage = data?.options[1];
    }
  }

  ngOnInit(): void {
    if (this.chatService.pref_lang_type === "en") {
      this.selectedLanguage = "English";
    } else if (this.chatService.pref_lang_type === "fr") {
      this.selectedLanguage = "Français"
    }
  }

  onCancelClick(): void {
    this.dialogRef.close(false); // Close the dialog without emitting anything
  }

  onConfirmClick(): void {
    if (this.selectedFile) {
      this.dialogRef.close(this.selectedFile); // Close the dialog and emit the selected file
    } else {
      this.dialogRef.close(this.selectedLanguage || true); // Close the dialog with the selected language if no file is selected
    }
  }

  onFileSelected(input: HTMLInputElement) {
    const files = input.files;
    if (files && files.length > 0) {
      this.data.disable = false
      this.selectedFile = files[0];
    }
  }

}
