<style>
  .spacer {
    flex: 1;
  }

  .header {
    padding: 0 20px;
  }

  .pad-lft35 {
    padding-left: 35px;
  }

  .content {
    display: flex;
    overflow-y: auto;
  }

  svg.material-icons {
    height: 24px;
    width: auto;
  }

  svg.material-icons:not(:last-child) {
    margin-right: 8px;
  }


  a,
  a:visited,
  a:hover {
    color: #1976d2;
    text-decoration: none;
  }

  a:hover {
    color: #125699;
  }


  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 5px;
  }

  .font-size20 {
    font-size: 20px;
  }

  .bg-white {
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .content-center {
    display: flex;
    align-items: center !important;
  }
</style>
<mat-sidenav-container class="side-nav-container">
  <mat-sidenav #sidenav [style.top.px]="isMobileScreen ? 0: 0" [style.overflow]="isMobileScreen? 'hidden': 'hidden'"
    *ngIf="!isLoginPage() && !isForgotPasswordPage() && !isPasswordResetPage() && !isThankYouPage() && !isPasswordResetPage()"
    [mode]="mode" [(opened)]="opened" (opened)="events.push('open!')" (closed)="events.push('close!')">
    <app-chatsidenav (actionClickedFromSideNav)="handleActionClick($event)"></app-chatsidenav>
  </mat-sidenav>

  <mat-sidenav-content class="bg-image">
    <!-- Header -->
    <!-- <div class="header" [style.left.px]="isMobileScreen ? 2 : 2" fxLayout="row" fxLayoutAlign="space-between center"
      *ngIf="!isLoginPage() && !isForgotPasswordPage() && !isPasswordResetPage() && !isThankYouPage()">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div *ngIf="isNonLoginPage()" (click)="toggleDrawerMode()" class='content-center guide-tour'>
          <img height="25px" width="25px" alt="Mobile Menu" class="cursor" src="assets/mobile-menu.svg" />
        </div>
        <div *ngIf="isLoginPage()">
          <img height="25" alt="CIBC Logo" src='assets/CIBC_logo_2021.png' />
          <span class="font-size20">{{'login.CONTACT_CENTER_TEXT'| translate}}</span>
        </div>
        <div fxLayout="row" *ngIf="!isLoginPage()">
          <div class='content-center'>
            <img height="30px" alt="Bot Avatar" src="assets/bot icon.png" />
          </div>
          <div class='content-center'>
            <div class='cibc-title-style'>CIBC {{'chatBot.VIR_ASSISTANT_TEXT' | translate}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="!isMobileScreen" fxFlex fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="isChatbotPage()" class="pad-lft35">
          <h3>{{'sideNav.CHAT_BOT' | translate}}</h3>
        </div>
        <div *ngIf="isConversationHistoryPage()" class="pad-lft35">
          <h3>{{'chatBot.TRANSCRIPT_CONVER_HISTORY' | translate}}</h3>
        </div>
        <div *ngIf="isContentManagementPage()" class="pad-lft35">
          <h3>{{'sideNav.CONTENT_MGMT' | translate}}</h3>
        </div>
        <div *ngIf="isSynonymsPage()" class="pad-lft35">
          <h3>{{'sideNav.SYNONYMS' | translate}}</h3>
        </div>
        <div *ngIf="isConfigurationPage()" class="pad-lft35">
          <h3>{{'chatBot.API_CONFIGURATION' | translate}}</h3>
        </div>
        <div *ngIf="isUserManagementPage()" class="pad-lft35">
          <h3>{{'sideNav.USER_MGMT' | translate}}</h3>
        </div>
        <div *ngIf="isFeedbackPage()" class="pad-lft35">
          <h3>{{'sensitiveQuestion.RATING_TYPES_OPTIONS' | translate}}</h3>
        </div>
        <div *ngIf="isCacheTopicPage()" class="pad-lft35">
          <h3>{{'sideNav.CACHE' | translate}}</h3>
        </div>
        <div *ngIf="isConfigTopicPage()" class="pad-lft35">
          <h3>{{'sideNav.CONFIGURATION' | translate}}</h3>
        </div>
        <div *ngIf="isChatHistoryPage()" class="pad-lft35">
          <h3>{{'sideNav.CHATBOT_HISTORY' | translate}}</h3>
        </div>
        <div *ngIf="isValidationPage()" class="pad-lft35">
          <h3>{{'sideNav.VALIDATION_URL' | translate}}</h3>
        </div>
        <div *ngIf="isChitChatPage()" class="pad-lft35">
          <h3>{{'devConfig.TOGGLE_LABEL' | translate}}</h3>
        </div>
        <div *ngIf="isUserComments()" class="pad-lft35">
          <h3>{{'sideNav.FEEDBACK_CMD' | translate}}</h3>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end center">
         <div class="cursor" *ngIf="!isMobileScreen" [matMenuTriggerFor]="menu" [matTooltip] ="'sideNav.TOUR_GUIDE' | translate">
          <img height="20px" width="20px" alt="Tour guide" src="assets/question.png" />
        </div> 
        <mat-menu #menu="matMenu">
          <div mat-menu-item (click)="tourGuideStart()">
            <mat-icon><img height="20px" width="20px" alt="Tour guide" src="assets/user-guide.png" /></mat-icon>
            <span>{{'sideNav.TOUR_GUIDE' | translate}}</span>
          </div>
        </mat-menu>
        <div class="cursor new-convo-tour" *ngIf="isChatbotPage() && isMobileScreen" (click)="newConversation()">
          <img height="30px" width="30px" alt="New Conversation" [matTooltip]="'sideNav.NEW_CONVERSATION' | translate"
            src="assets/mobile-add.svg" />
        </div>
         <div *ngIf="isChatbotPage() && !isMobileScreen">
          <button mat-button class="mat-button-style new-convo-tour" (click)="newConversation()">{{'sideNav.NEW_CONVERSATION' |
            translate}}</button>
        </div>
      </div>
    </div> -->
    <!-- Main Content -->
    <div>
      <router-outlet></router-outlet>
      <ngx-guided-tour backText="Back" skipText="Skip"></ngx-guided-tour>
      <div class="modal-backdrop" *ngIf="isModalLoader">
        <app-loader [botLoderType]="botLoderType"></app-loader>
      </div>
    </div>
    <!-- Footer -->
    <footer fxLayoutAlign="center center" *ngIf="isLoginPage() || isPasswordResetPage() || isForgotPasswordPage()">
      <div class='footer-row'>
        {{'login.POWERD_BY' | translate}}
        <img alt='Kumaran Systems Private Limited' src='assets/kumaran icon.png' />
        <a href="https://kumaran.com/" target="_blank" rel="noopener noreferrer" class='forgot-password-link'>Kumaran
          Systems</a>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>