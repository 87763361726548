<div class="header-forget" role="banner">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="stretch center">
        <img height="30px" alt="Bot Avatar" src="assets/bot icon.png" />
        <div class='cibc-title-style'>CIBC {{'chatBot.VIR_ASSISTANT_TEXT' | translate}}</div>
      </div>
</div>
<div class="container">
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
        <h4>{{'forgotPassword.FORGOT_PASS_LABEL' | translate}}</h4>
        <div>
            {{'forgotPassword.FORGOT_PASS_MSG_CONTENT' | translate}}
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>{{'forgotPassword.EMAIL_LABEL' | translate}}</mat-label>
                <input matInput type="email" formControlName="email">
                <mat-error *ngIf="forgotPasswordForm.get('email')?.hasError('required')">
                    {{'forgotPassword.EMAIL_REQUIRED_LABEL' | translate}}
                </mat-error>
                <mat-error *ngIf="forgotPasswordForm.get('email')?.hasError('invalidEmail') && !forgotPasswordForm.get('email')?.hasError('required')">
                    {{ 'errorMessages.VALID_EMAIL_ERROR' | translate }}
                  </mat-error>
            </mat-form-field>
        </div>
        <div>
            <button class="mui-button" type="submit" [disabled]="buttonDisable || !forgotPasswordForm.valid">
                {{'forgotPassword.SUMBIT_BTN_LABEL' | translate}}
            </button>
        </div>
    </form>
</div>