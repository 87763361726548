<div class="header-login" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="stretch center">
        <img height="30px" alt="Bot Avatar" src="assets/bot icon.png" />
        <div class='cibc-title-style'>CIBC {{'chatBot.VIR_ASSISTANT_TEXT' | translate}}</div>
    </div>
    <div (click)="languageCheck()" class="cursor">
        <div *ngIf="language ==='fr'" fxLayout="row" fxlayoutGap="20" fxLayoutAlign="center center">
            <mat-icon>language</mat-icon>
            <div>{{ 'login.EN_LANG' | translate}}</div>
        </div>
        <div *ngIf="language ==='en'" fxLayout="row" fxlayoutGap="20" fxLayoutAlign="center center">
            <mat-icon>language</mat-icon>
            <div>{{ 'login.FR_LANG' | translate}}</div>
        </div>
    </div>
</div>
<div fxLayout="row" class="container">
    <div fxFlex="100" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center center">
        <div class="head-center">
            <div>
                <img alt='homepage' src='assets/icon logo.png' />
                <div class='welcome-head'>{{'login.WELCOME_BACK' | translate}}</div>
                <div>{{'login.WELCOME_CONTENT_TEXT' | translate}}</div>
                <div class='support-head'>{{'login.WELCOME_SUPPORT' | translate}}</div>
            </div>
        </div>
        <div class="login-form" fxLayout="column" fxLayoutGap="20px">
            <form (ngSubmit)="handleSubmit()">
                <div class="form-group">
                    <label for="username">{{ 'login.USERNAME' | translate }}</label>
                    <div class="input-with-icon" fxLayout="row" fxLayoutAlign="start center">
                        <input type="text" id="username" name="username" #email="ngModel"
                            (ngModelChange)="validateEmail(email)" [(ngModel)]="username" required class="mui-input"
                            fxFlex />
                        <mat-icon class="icon" aria-hidden="false" aria-label="Username" fontIcon="person"></mat-icon>
                    </div>
                    <mat-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                        <ng-container *ngIf="email?.errors?.['required']">
                          {{ 'forgotPassword.EMAIL_REQUIRED_LABEL' | translate }}
                        </ng-container>
                        <ng-container *ngIf="email?.errors?.['invalidEmail']">
                          {{ 'errorMessages.VALID_EMAIL_ERROR' | translate }}
                        </ng-container>
                      </mat-error>
                </div>
                <br>
                <div class="form-group">
                    <label for="password">{{ 'login.PASSWORD' | translate }}</label>
                    <div class="input-with-icon" fxLayout="row" fxLayoutAlign="start center">
                        <input type="password" id="password" name="password" [(ngModel)]="password" #pass="ngModel"
                            required class="mui-input" [type]="passwordFieldType" fxFlex />
                        <mat-icon class="icon" aria-hidden="false" aria-label="Toggle Password Visibility"
                            (click)="togglePasswordVisibility()">
                            {{ showPassword ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </div>
                    <mat-error *ngIf="pass.invalid && (pass.dirty || pass.touched)">
                        {{ 'errorMessages.PASSWORD_REQUIRED' | translate }}
                    </mat-error>
                </div>
                <div class="forgot-password">
                    <a (click)="goToForgotPassword()" class="forgot-password-link cursor">{{
                        'login.FORGOT_PASSWORD_LINK' | translate }}</a>
                </div>
                <br>
                <div fxLayoutGap="5px" fxLayoutAlign="center center" class="width-100">
                    <button type="button"  (click)="clearFormData()" class="login-button-clear width-100">{{ 'login.CLEAR' |
                        translate }}</button>
                    <button type="submit" [disabled]="!this.username || !this.password || email?.errors?.['invalidEmail']" class="login-mui-button width-100">{{
                        'login.LOGIN' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
    <div *ngIf="isModalLoader" class="modal-backdrop">
        <app-loader [botLoderType]="botLoderType"></app-loader>
    </div>
</div>