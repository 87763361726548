import { Component, OnInit, ViewChild } from '@angular/core';
import { ContentManagementService } from "../services/content-management.service";
import { ToastrService } from "ngx-toastr";
import { ConfirmationDialogComponentComponent } from "../confirmation-dialog-component/confirmation-dialog-component.component";
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslationService } from '../services/translation-service';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorageService';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DateFormatService } from '../pipe/date-time-localize';
import { TranslateServiceSpecifiedLabelsService } from '../services/translate-service-specified-labels.service';
import { TranslateService } from '@ngx-translate/core';
import { DisclousrePopupModalComponent } from '../popup-modal/popup-modal.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { ChatbotService } from '../services/chat.service';
import { StateService } from '../services/shared-object.service';
// Define a type for items with display_index
interface ItemWithIndex {
  _id: any;
  display_index: number;
}

@Component({
  selector: 'app-rating-type-options-list',
  templateUrl: './feedback-component.html',
  styleUrls: ['./feedback.component.scss']
})
export class RatingTypeOptionsListComponent {
  searchQuery: any = {};
  editedItem: any = {};
  data: any = [];
  pageNumber: number = 1;
  pageSize: number = 20;
  totalTablePage: number = 0;
  typesOfRating = ["Chit Chat", "Sensitive", "No Content", "Normal"];
  targetTypeOptions = ["Sensitive", "No Content", "Normal", "All"];
  responseTypeOptions = ["Sensitive", "No Content", "Normal"];
  feedbackType = [
    { value: 1, label: 'feedback.TRUE' },
    { value: 0, label: 'feedback.FALSE' }
  ];
  targetType: string = '';
  responseType: string = '';
  addTargetType: string = '';
  addResponseType: string = '';
  displayType: string[] = [];
  addDisplayType: string[] = [];
  newFeedbackType: string = '';
  editTargetType: string[] = [];
  editDisplayType: string[] = [];
  isNewRow: boolean = false;
  isLoader = false;
  translate: any;
  disableIcon = false;
  keyDisabled = true;
  isNewRowAdded = false;
  feedbackValuesEnglish: string = "";
  feedbackValuesFrench: string = ''
  frenchErrorMessage: string = '';
  wordCountErrorMessage: string = '';
  // Add this line in your component class
  displayedColumns: string[] = ['targetType', 'responseType', 'feedbackCommentEnglish', 'feedbackCommentFrench', 'additionalFeedbackComment', 'feedbackFlag', 'dateTime', 'actions'];
  dataSource = new MatTableDataSource<any>(this.data);
  editMode: { [key: string]: boolean } = {};
  isDesktop = false;
  additionalFeedbackComment: boolean = false
  feedbackFlag: number = 0;
  botLoderType: string = 'boxLoader';
  reorderEnabled: boolean = false;
  @ViewChild(MatTable) table!: MatTable<any>;
  reorderProcess: boolean = false;
  showPositionColumn = false;
  isModalLoader: boolean = false;
  isExpandCollapse: boolean = true;
  constructor(
    private router: Router,
    private contentMgmtService: ContentManagementService,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    translateService: TranslationService,
    private sessionStorage: LocalStorageService,
    private breakpointObserver: BreakpointObserver,
    public dateFormatService: DateFormatService,
    private translateSpecifiedLabel: TranslateServiceSpecifiedLabelsService,
    private translated: TranslateService,
    private cdr: ChangeDetectorRef,
    public chatbotService: ChatbotService,
    private stateService: StateService
  ) {
    this.translate = translateService;
  }
  ngOnInit(): void {
    /**Method Used to find desktop/ Mobile device */
    this.breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isDesktop = !result.matches;
    });
    this.responseType = 'All';
    this.searchFeedback(this.pageNumber, this.pageSize);
    this.stateService.isExpandCollapse$.subscribe((state) => {
      this.isExpandCollapse = state;
    });
  }

  filterDataByApplication() {
    this.pageNumber = 1;
  }
  targetTypeChange() {

  }
  initializeEditMode() {
    this.editMode = this.data.map(() => false);
  }
  addNewRow() {
    this.isNewRow = true;
    this.disableIcon = true;
    this.isNewRowAdded = true;
  }

  handleEditClick(row: any, rowIndex: number) {
    this.editMode = {};
    this.editMode[row._id] = true;
    this.disableIcon = true;
    row.feedback_type = this.mapToOriginalType(this.data[rowIndex].feedback_type, this.targetTypeOptions);
    row.response_type = this.mapToOriginalType(this.data[rowIndex].response_type, this.targetTypeOptions);

    if (row.feedback_type?.toLowerCase() === 'sensitive') {
      row.feedback_flag = this.data[rowIndex].feedback_flag === 'false' ? 0 : 1;
    } else {
      row.feedback_flag = 'Not Applicable'
    }
  }

  mapToOriginalType(formattedType: string, targetTypeOptions: string[]): string {
    switch (formattedType) {
      // case 'chit_chat':
      //   return targetTypeOptions[0];
      case 'sensitive':
        return targetTypeOptions[0];
      case 'no_content':
        return targetTypeOptions[1];
      case 'normal':
        return targetTypeOptions[2];
      case 'none':
        return targetTypeOptions[3]
      // case 'not_answered':
      //   return targetTypeOptions[4];
      // case 'cache':
      //   return targetTypeOptions[5];
      default:
        return '';
    }
  }

  disableKeyInput() {
    this.keyDisabled = true;
  }

  enableKeyInput() {
    this.keyDisabled = false;
  }

  addNewRecord() {
    // let transformedDisplayTypeOptions = this.transformDisplayTypeOptions(this.addDisplayType);
    let transformedTargetTypeOptions = this.transformTargetTypeOptions(this.addTargetType);
    let transformedResponseTypeOptions = this.transformTargetTypeOptions(this.addResponseType);
    let payload = {
      bot_type: this.sessionStorage.get('bot_type'),
      feedback_type: transformedTargetTypeOptions.toLowerCase(),
      response_type: [transformedResponseTypeOptions.toLowerCase()],
      feedback_comment_english: this.feedbackValuesEnglish,
      feedback_comment_french: this.feedbackValuesFrench,
      additional_comments_required: this.additionalFeedbackComment,
      // given_by_username: this.sessionStorage.get('username'),
      feedback_flag: this.feedbackFlag
    }
    this.isModalLoader = true;
    this.contentMgmtService.performFeedbackAction('addFeedback', payload, this.pageNumber, this.pageSize).subscribe(
      (response: any) => {
        this.isNewRow = false;
        let searchTargetType = this.transformTargetTypeOptions(this.addTargetType);
        this.addTargetType = '';
        this.addResponseType = '';
        this.addDisplayType = [];
        this.feedbackValuesEnglish = '';
        this.feedbackValuesFrench = '';
        this.feedbackFlag = 1;
        this.additionalFeedbackComment = false
        this.disableIcon = false;
        this.updateTargetTypeOptions(transformedTargetTypeOptions);
        this.translated.get('successfullMessages.FEEDBACK_ADDED_SUCCESS').subscribe((translatedText: string) => {
          this.toaster.success(translatedText);
        });
        this.isModalLoader = false;
        let searchPayload = {
          bot_type: this.sessionStorage.get('bot_type'),
          feedback_type: searchTargetType.toLowerCase(),
          response_type: null,
          language_type: this.sessionStorage.get('language')
        }
        this.getFeedbackBySearch(searchPayload, this.pageNumber, this.pageSize);
      },
      (error: any) => {
        this.isModalLoader = false;
        this.translated.get('errorMessages.FEEDBACK_SAVE_ERROR_TEXT').subscribe((translatedText: string) => {
          this.toaster.error(translatedText);
        });
      }
    )
  }

  handleSaveChanges(item: any, index: number) {
    if (item.feedback_type === '') {
      this.toaster.error('Please Enter Feedback Type')
    } else if (item.feedback_comment_english === '') {
      this.toaster.error('Please Enter Feedback Comment For English')
    } else if (item.feedback_comment_french === '') {
      this.toaster.error('Please Enter Feedback Comment For French')
    } else if (item.response_type === '') {
      this.toaster.error('Please Enter Response Type')
    } else {
      let transformedTargetTypeOptions = this.transformTargetTypeOptions(item.feedback_type);
      let transformedResponseTypeOptions = this.transformTargetTypeOptions(item.response_type);
      let updatePayload = {
        _id: item._id,
        feedback_comment_english: item.feedback_comment_english,
        feedback_comment_french: item.feedback_comment_french,
        bot_type: this.sessionStorage.get('bot_type'),
        feedback_type: transformedTargetTypeOptions,
        response_type: transformedResponseTypeOptions.toLowerCase(),
        feedback_flag: item.feedback_flag === "Not Applicable" ? 0 : 1,
        additional_comments_required: item?.additional_comments_required,
        // given_by_username: item.given_by_username
      }
      this.isModalLoader = true;
      this.contentMgmtService.performFeedbackAction('editFeedback', updatePayload, this.pageNumber, this.pageSize).subscribe(
        (response: any) => {
          this.editMode[item._id] = false;
          this.translated.get('successfullMessages.FEEDBACK_UPDATED_SUCCESS').subscribe((translatedText: string) => {
            this.toaster.success(translatedText);
          });
          this.disableIcon = false;
          let serachQuery = {
            bot_type: this.sessionStorage.get('bot_type'),
            feedback_type: transformedTargetTypeOptions.toLowerCase(),
            language_type: this.sessionStorage.get('language'),
            response_type: ''
          }
          this.isModalLoader = false;
          this.getFeedbackBySearch(serachQuery, this.pageNumber, this.pageSize);
        },
        (error: any) => {
          this.isModalLoader = false;
          this.disableIcon = true;
          this.editMode[item._id] = true;
          this.toaster.error(error?.error?.error);
        }
      )
    }
  }

  handleDelete(item: any, i: number) {
    this.openConfirmationDialog(item)
  }

  checkWordCount(field: 'english' | 'french', row?: any, rowData?: any) {
    const wordLimit = 500;
    let currentValue = '';

    if (field === 'english') {
      if (rowData) {
        currentValue = rowData
      } else {
        currentValue = this.feedbackValuesEnglish;
      }
    } else if (field === 'french') {
      if (rowData) {
        currentValue = rowData
      } else {
        currentValue = this.feedbackValuesFrench;
      }
    }

    const words = currentValue.trim().split(/\s+/);

    if (words.length > wordLimit) {
      if (field === 'english') {
        this.feedbackValuesEnglish = words.slice(0, wordLimit).join(' ');
        if (row) {
          row.wordCountErrorMessageEnglish = "Please Provide the comment with 500 words"
        } else {
          this.wordCountErrorMessage = "Please Provide the comment with 500 words"
        }
      } else {
        this.feedbackValuesFrench = words.slice(0, wordLimit).join(' ');
        if (row) {
          row.wordCountErrorMessageFrench = "Please Provide the comment with 500 words"
        } else {
          this.wordCountErrorMessage = "Please Provide the comment with 500 words"
        }
      }
    }
  }

  validateFrenchCharacters(input?: any, row?: any) {
    const allowedFrenchRegex = /^[ÄäáìíÖöòóÜúÆæàâçéèêëÏîïôùûüÀÂÇÈÉÊËÎÔÙÛŸÿ0-9\s]*$/;
    const frenchCharacterRegex = /[ÄäáìíÖöòóÜúÆæàâçéèêëÏîïôùûüÀÂÇÈÉÊËÎÔÙÛŸÿ]/;
    const inputValue = this.feedbackValuesFrench === '' ? input : this.feedbackValuesFrench;

    // Check if inputValue is undefined or empty
    if (!inputValue) {
      if (row) {
        row.frenchErrorMessage = '';
      } else {
        this.frenchErrorMessage = '';
      }
      return null;
    }

    if (!allowedFrenchRegex.test(inputValue)) {
      if (row) {
        row.frenchErrorMessage = 'Only specific French characters and numbers are allowed.';
      } else {
        this.frenchErrorMessage = 'Only specific French characters and numbers are allowed.';
      }
      return null;
      // this.feedbackValuesFrench = inputValue.replace(/^[ÄäáìíÖöòóÜúÆæàâçéèêëÏîïôùûüÀÂÇÈÉÊËÎÔÙÛŸÿ0-9\s]/g, '');
    } else if (!frenchCharacterRegex.test(inputValue)) {
      if (row) {
        row.frenchErrorMessage = 'Input must include French character.';
      } else {
        this.frenchErrorMessage = 'Input must include French character.';
      }
      return null;
    } else {
      if (row) {
        row.frenchErrorMessage = ''
      } else {
        this.frenchErrorMessage = '';
      }
      return null;
    }
  }

  clear(field: 'english' | 'french', textarea?: HTMLTextAreaElement) {
    if (field === 'english') {
      this.feedbackValuesEnglish = '';
      this.wordCountErrorMessage = ''
    } else {
      this.feedbackValuesFrench = '';
      this.frenchErrorMessage = '';
      this.wordCountErrorMessage = ''
      if (textarea) {
        textarea.value = ''; // Reset the textarea value directly
      }
    }
  }

  cancel() {
    this.isNewRow = !this.isNewRow;
    this.addTargetType = '';
    this.addResponseType = ''
    this.addDisplayType = [];
    this.data = []
    this.targetType = ''
    this.feedbackValuesEnglish = '';
    this.feedbackValuesFrench = ''
    this.feedbackFlag = 1;
    this.additionalFeedbackComment = false
    this.targetType = 'All'
    this.searchFeedback(this.pageNumber, this.pageSize)
    this.disableIcon = false;
  }
  openConfirmationDialog(selectedRow: any) {
    const DELETE_CONFIRM_LABEL = this.translateLabel('DELETE_CONFIRM_LABEL').translation;
    const DELETE_TARGET_FDBK = this.translateLabel('DELETE_TARGET_FDBK').translation;
    const DELETE_TARGET_FDCOMMENT = this.translateLabel('DELETE_TARGET_FDCOMMENT').translation;
    const messageDelete: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(`
    ${DELETE_CONFIRM_LABEL}<br> ${DELETE_TARGET_FDBK} :${selectedRow?.feedback_type || ""} <br> ${DELETE_TARGET_FDCOMMENT}:${selectedRow?.feedback_comment_english || selectedRow?.feedback_comment_french}`);
    const dialogRef = this.dialog.open(ConfirmationDialogComponentComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        type: 1,
        message: messageDelete,
        options: []
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.isModalLoader = true;
      if (result) {
        let deleteData = {
          "_id": selectedRow._id,
          "bot_type": this.sessionStorage.get('bot_type')
        }
        this.contentMgmtService.performFeedbackAction('deleteFeedback', deleteData, this.pageNumber, this.pageSize).subscribe((response: any) => {
          this.translated.get('successfullMessages.FEEDBACK_DELETED_SUCCESS').subscribe((translatedText: string) => {
            this.toaster.success(translatedText);
          });
          this.isModalLoader = false;
          this.getFeedbackBySearch(this.searchQuery, this.pageNumber, this.pageSize);
          this.searchQuery = {};
        }, (error: any) => {
          this.isModalLoader = false;
          this.toaster.error(error?.error?.error);
        });
      }
    });
  };
  shouldDisableKey(index: number): boolean {
    return this.keyDisabled || (!this.isNewRowAdded && index !== 0);
  }
  transformTargetTypeOptions(displayTypeOptions: string): string {
    if (displayTypeOptions === 'All') {
      return '';
    }
    return displayTypeOptions.replace(/ /g, '_').toLowerCase();
  }

  searchFeedback(page_number: any, page_size: number) {
    if (this.responseType != "") {
      this.editMode = {};
      this.isLoader = false;
      let transformedTargetTypeOptions = this.transformTargetTypeOptions(this.responseType);
      let searchPayload = {
        bot_type: this.sessionStorage.get('bot_type'),
        feedback_type: null,
        response_type: transformedTargetTypeOptions.toLowerCase() === '' ? null : transformedTargetTypeOptions.toLowerCase(),
        language_type: this.sessionStorage.get('language')
      }
      this.searchQuery = searchPayload;
      this.getFeedbackBySearch(searchPayload, page_number, page_size);
    }
  }

  searchFeedbackList() {
    this.data = [];
    this.isLoader = true;
    let transformedResponseTypeOptions = this.transformTargetTypeOptions(this.responseType);
    let searchPayload = {
      bot_type: this.sessionStorage.get('bot_type'),
      response_type: transformedResponseTypeOptions.toLowerCase() === '' ? null : transformedResponseTypeOptions.toLowerCase(),
    }
    this.chatbotService.getFeedbackList(searchPayload).subscribe((res: any) => {
      this.data = res?.map((row: any, index: number) => ({
        ...row,
        originalIndex: index + 1
      }));
      this.isLoader = false;
      if (!this.displayedColumns.includes('position') && this.reorderEnabled) {
        this.displayedColumns.push('position');
      }
      this.showPositionColumn = true;
    });
  }
  getFeedbackBySearch(payload: any, page_number: any, page_size: any) {
    this.data = [];
    this.isLoader = true;
    this.contentMgmtService.performFeedbackAction('getFeedback', payload, page_number, page_size).subscribe(
      (response: any) => {
        if (response.data?.length > 0) {
          this.data = response?.data.map((row: any, index: number) => ({
            ...row,
            originalIndex: index + 1 // Store the original index (1-based)
          }));
          this.totalTablePage = response?.total_no_of_pages;
          this.isLoader = false;
          if (!this.displayedColumns.includes('position') && this.reorderEnabled) {
            this.displayedColumns.push('position');
            this.showPositionColumn = true;

          }
        }
        else {
          this.isLoader = false
          this.data = [];
          if (this.displayedColumns.includes('position') && this.reorderEnabled) {
            this.displayedColumns = this.displayedColumns.filter(col => col !== 'position');
            this.showPositionColumn = false;

          }
        }
        // Trigger change detection and re-render the table
        this.cdr.detectChanges();
        this.table.renderRows();
      },
      (error: any) => {
        this.isLoader = false;
        this.data = [];
        if (this.displayedColumns.includes('position') && this.reorderEnabled) {
          this.displayedColumns = this.displayedColumns.filter(col => col !== 'position');
          this.showPositionColumn = false;

        }
        // Trigger change detection and re-render the table
        this.cdr.detectChanges();
        this.table.renderRows();
      }
    )
  }
  formatFeedbackType(feedbackType: string): string {
    switch (feedbackType) {
      case 'none':
        return 'All';
      case 'no_content':
        return 'No Content';
      case 'sensitive':
        return 'Sensitive';
      case 'normal':
        return 'Normal';
      default:
        return '';
    }
  }

  flagValueChanged(event: any) {
    this.additionalFeedbackComment = event;
  }
  feedbackFlagChange(event: any, row: any) {
    row.feedback_flag = event ? 1 : 0;
  }
  validFeedbackFrom() {

    return this.feedbackValuesEnglish !== '' && this.feedbackValuesFrench !== '' && this.addTargetType !== '' && this.addResponseType !== ''
  }

  updateTargetTypeOptions(newTargetType: string) {
    const originalType = this.mapToOriginalType(newTargetType, this.targetTypeOptions);

    if (originalType && !this.targetTypeOptions.includes(originalType)) {
      this.targetTypeOptions.push(originalType);
    }

    this.targetType = originalType;
  }

  translateLabel(label: string): { translation: string, languageCode: string } {
    const lang = this.sessionStorage.get('language');
    const translation = this.translateSpecifiedLabel.translateLabel(label, lang);

    // Handle missing translation
    if (!translation) {
      console.warn(`Missing translation for label: ${label} in language: ${lang}`);
      return {
        translation: label,  // Fallback to the label itself if translation is missing
        languageCode: lang
      };
    }
    return {
      translation: translation,
      languageCode: lang
    };
  }

  cancelEditMode(row: any, rowIndex: number) {
    this.editMode[row._id] = false;
    this.disableIcon = false;
    this.getFeedbackBySearch(this.searchQuery, this.pageNumber, this.pageSize);
  }
  onPageChange(pageNumber: number) {
    this.pageNumber = pageNumber;
    this.searchFeedback(pageNumber, this.pageSize)
  }

  showPopover(content: string) {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      width: 'auto',
      disableClose: true,
      data: {
        isCacheUserResponse: true,
        title: 'Feedback Option',
        cacheUserResponse: content
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  onRowClick(row: string) {
    const dialogRef = this.dialog.open(DisclousrePopupModalComponent, {
      width: '100%',
      maxWidth: 'none',
      disableClose: true,
      data: {
        isFeedbackFullRow: true,
        feedbackRowDetails: row
      },
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  reorder() {
    this.reorderEnabled = true;
    this.disableIcon = true;
    this.searchFeedbackList();
  }
  cancelReorder() {
    this.reorderEnabled = false;
    this.disableIcon = false;
    this.responseType = 'All';
    this.searchFeedback(this.pageNumber, this.pageSize);
    if (this.displayedColumns.includes('position')) {
      this.displayedColumns = this.displayedColumns.filter(col => col !== 'position');
    }
  }
  reorderSave() {
    const filteredData = this.data.map((item: any) => ({
      _id: item._id,
      display_index: item.display_index
    }));
    const payloadReorder = {
      display_index_list: filteredData,
      bot_type: this.sessionStorage.get('bot_type')
    }
    this.reorderProcess = false;
    this.chatbotService.saveReorder(payloadReorder).subscribe((res: any) => {
      this.reorderEnabled = false;
      this.reorderProcess = false;
      this.showPositionColumn = false;
      this.disableIcon = false;
      this.responseType = 'All';
      this.translated.get('successfullMessages.FEEDBACK_REORDER_SUCCESS').subscribe((translatedText: string) => {
        this.toaster.success(translatedText);
      });
      if (this.displayedColumns.includes('position')) {
        this.displayedColumns = this.displayedColumns.filter(col => col !== 'position');
      }
      this.searchFeedback(this.pageNumber, this.pageSize);
    }, (error: any) => {
      // console.log('filteredData error:', error);
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
    // Create a new array to hold the updated items
    const updatedData: ItemWithIndex[] = this.data.map((item: any, index: number) => ({
      _id: item._id,
      display_index: index + 1
    }));

    // Apply the new display_index values to the data
    this.data.forEach((item: any) => {
      const updatedItem = updatedData.find((i) => i._id === item._id);
      if (updatedItem) {
        item.display_index = updatedItem.display_index;
      }
    });

    if (this.showPositionColumn) {
      this.table.renderRows();
    }
    this.reorderProcess = true;
  }
  responseTypeChange() {
    this.searchFeedbackList();
    this.pageNumber = 1;
    this.totalTablePage = 1;
  }
}
