import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { LocalStorageService } from '../services/localStorageService';
import { TranslationService } from '../services/translation-service';
import { ChatbotService } from '../services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-tag-popup-modal',
  templateUrl: 'tagPopupModal.html',
  styleUrls: ['./chatbot.component.scss']
})
export class TagPopupModalComponent implements OnInit {
  agumentResponse: any;
  augmentContentFromDrawer: any;
  showRateComment: boolean = false;
  translate: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, private sessionStorage: LocalStorageService, translateService: TranslationService, private chatbotService: ChatbotService,
    private translated: TranslateService, private toastr: ToastrService, private router: Router) {
    this.translate = translateService
  }

  ngOnInit(): void {
    if (!this.data?.fromRightDrawer) {
      this.agumentResponse = this.data?.responseFromBot?.queryInfo;
      this.showRateComment = false;
    }
    if (this.data?.fromRightDrawer) {
      let augContent = this.data?.responseFromBot.filter((item: any, index: number) => {
        if (item.question === this.data.userInput && index === this.data.questionIndex) {
          return item.augContent
        }
      });
      this.augmentContentFromDrawer = augContent;
      this.agumentResponse = augContent.length > 0 && augContent[0]?.augSrc.length > 0 ? augContent[0]?.augSrc : augContent[0]?.augContent;
      this.showRateComment = true;
    }

  }

  isDocxFile(url: string): boolean {
    return url.endsWith('.docx');
  }
  isPdfFile(url: string): boolean {
    return url.endsWith('.pdf');
  }
  copyToClipboard(content: string) {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
  fileDownloadDocx(event: MouseEvent, url: string) {
    event.preventDefault();
    const regex = /view_file/;
    if (regex.test(url)) {
      this.downloadFile(url).subscribe(
        response => {
          const contentType = response.type;
          const blob = new Blob([response], { type: contentType });

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
        },
        error => {
          if (error.status === 401) {
            this.translated.get('errorMessages.UNAUTHORIZED_ERROR').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          if (error.status === 409) {
            this.translated.get('errorMessages.LOGIN_CURRENT_SESSION_EXPIRED').subscribe((translatedText: string) => {
              this.toastr.error(translatedText);
            });
            this.router.navigate(['/login']);
            sessionStorage.clear();
          }
          // Handle other errors accordingly
        }
      );
    }
    else {
      window.open(url, '_blank');
    }
  }

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob', // Specify responseType as blob
      headers: {
        Authorization: `Bearer ${this.sessionStorage.get('appTo')}`
      }
    });
  }

}
